import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  createError: null,
  updateError: null,
  getError: null,
  deleteError: null,
  themeData:{},
  faqs:{}
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
    //HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false
      state.createError = action.payload
    },
    //UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false
      state.updateError = action.payload
    },
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload
    },
    // GET User Success
    getThemeDataSuccess(state, action) {
      state.isLoading = false;
      state.themeData = action.payload
    },
    getFaqDataSuccess(state, action) {
        state.isLoading = false;
        state.faqs = action.payload
      }
  },
})
export const { getThemeDataSuccess,getFaqDataSuccess } = themeSlice.actions;
export default themeSlice.reducer


//Get Theme Data
export function getThemeData() {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(themeSlice.actions.startLoading());
    try {
      const response = await axios.get('manage/frontend', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      dispatch(themeSlice.actions.getThemeDataSuccess(response.data.data));
    } catch (error) {
      dispatch(themeSlice.actions.hasGetError(error?.message));
    }
  };
}

//Get Faq Data
export function getFaqData() {
    return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
      dispatch(themeSlice.actions.startLoading());
      try {
        const response = await axios.get('faq', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        });
        dispatch(themeSlice.actions.getFaqDataSuccess(response.data.data));
      } catch (error) {
        dispatch(themeSlice.actions.hasGetError(error?.message));
      }
    };
  }