import React from 'react'
import logo from "../assetr/navlogo.png";
import { Link, useNavigate } from 'react-router-dom'
import Leftsidpage from '../component/Leftsidpage';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import Navbar from '../component/Navbar';
const PaymentSuccess = () => {
    const navigate = useNavigate();
    return (
        <div>
        <Navbar />
            <div className="container min-100vh p-0">
                <div className="row justify-content-center me-lg-5 me-md-5 login-right-side mt-5">
                    <div className="col-lg-12 col-md-12 col-12 ">
                        <div className='bgSUCCESS rounded-3 p-4 text-center'>
                            <IoMdCheckmarkCircleOutline size={45} className='coloryellow' />
                            <h3 className='coloryellow'>SUCCESS!</h3>
                            <h6 className='content font-inter fw-500 font-20'> Plan Purchased Successfully!
                            </h6>
                            <div className='mt-3'>
                                <button type='button' onClick={()=>navigate('/profile')} className='btn-login px-5 py-2 rounded-3'>Go to Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
