import { combineReducers } from 'redux';
import userSlice from './slices/userSlice';
import videoSlice from './slices/videoSlice';
import themeSlice from './slices/themeSlice';

import {persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedUserReducer = persistReducer(persistConfig, userSlice);
const persistedThemeReducer = persistReducer(persistConfig, themeSlice); // Persist themeSlice
const persistedVideoReducer = persistReducer(persistConfig, videoSlice); // Persist themeSlice

const rootReducer = combineReducers({
  user: persistedUserReducer,
  video: persistedVideoReducer,
  theme: persistedThemeReducer, // Add persisted theme reducer to combined reducers
});

export default rootReducer;