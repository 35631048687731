import React, { useEffect, useState } from 'react'
import Navbar from '../component/Navbar'
import Tabs from '../component/Tabs'
import Travel from '../component/Travel'
import Fotter from '../component/Fotter'
import { Link, useNavigate } from 'react-router-dom'
import { shuffleArray } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { addRecentSearches, addToFavourite, getAllCategories, getAllLongVideos, getLatestVideos, getLongVideos, getVideoClips } from '../redux/slices/videoSlice'
import { FaSearch } from 'react-icons/fa'
import NewVideoCard from '../component/NewVideoCard'
const Captions = () => {
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [collectionsData, setCollections] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionTitle, setSelectedCollectionTitle] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  const [videoDetail, setVideoDetail] = React.useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const { latestVideos, videoClips,categories,} =
    useSelector((state) => state.video);
    
  const { userDetail } = useSelector((state) => state.user);

  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(28);
  const handlLoadMore = async () => {
    if(type==='new'){
        setLoading(true);
        setLimit(parseInt(limit) + 28);
        dispacth(getLatestVideos(1, parseInt(limit) + 28))
    }else if(type==='ai'){
        setLoading(true);
        setLimit(parseInt(limit) + 28);
        dispacth(getVideoClips(1, parseInt(limit) + 28))
    }else{
        setLoading(true);
        setLimit(parseInt(limit) + 28);
        dispacth(getVideoClips(1, parseInt(limit) + 28))
    }
  }


  useEffect(() => {
    if (type==='new') {
      setFilteredData(latestVideos);
      setLoading(false);
    }else if(type==='ai'){
        setFilteredData(videoClips);
    }else{
        setFilteredData(videoClips);
    }
  }, [type,latestVideos,videoClips]);
  // const [search,setSearch] = useState(null);
  const handleSearchVideos = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      search: search,
    };
    dispacth(addRecentSearches(data));
    // dispacth(getVideoClips(1,1000,search))
    dispacth(getLongVideos(page, limit,search))
    navigate("/videos?search=" + encodeURIComponent(search));
    window.location.reload();
  };
  return (
    <div>
      <Navbar />
      {loading &&
                <div class="loading">Loading&#8230;</div>
            }
      <div>
        <div className="container">
          <div className="row justify-content-center" >
            <div className="col-lg-4 col-md-2 mt-3">
              <form method="get" onSubmit={handleSearchVideos}>
                <div className="d-flex">
                  <div className="search-container">
                    <input type="text" className="custom-input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  </div>
                  <button type="submit" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                    <FaSearch />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid p-lg-5">
          <div className="row">
          <div className='col-md-12'>
            <h1>{selectedCollectionTitle}</h1>
          </div>
            {filteredData?.length > 0 ?
              filteredData.map((clip, index) => {
                if(index<limit)
                  return (
                    <div className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card" key={index}>
                      <NewVideoCard
                        video={clip}
                        videoType="clip"
                      />
                    </div>
                  )
              }) :
              <div className='col-md-12 text-center mt-5'>
                <h3 className='text-white'>{loading ?"Please wait...":"No Clip Found!"}</h3>
              </div>
            }
          </div>
          {filteredData?.length  && <div className="row mt-5">
            <div className='col-md-12 col-12 text-center'>
              <button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
                LOAD MORE
              </button>
            </div>
          </div>}
        </div>
      </div>
      <Fotter />
    </div>
  )
}

export default Captions