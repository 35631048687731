import React, { useState } from "react";
import profiledark from "../assetr/userlight.png";
import profilelight from "../assetr/profileimg.png";
import accountdark from "../assetr/accountlight.png";
import accountlight from "../assetr/accountimg.png";
import sublight from "../assetr/sublight.png";
import subdark from "../assetr/paymentsimg.png";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileSidBar = ({ isProfile, isAccount, isSubscription,isAffiliate }) => {
  const {  theme } = useSelector((state) => state.user);
  const { userDetail } = useSelector((state) => state.user);
  const [openProfile, setOpenProfile] = useState(isProfile);
  const [openAffiliate, setOpenAffiliate] = useState(isAffiliate);

  const [openAccount, setOpenAccount] = useState(isAccount);
  const [openPayments, setOpenPayments] = useState(isSubscription);

  const handleProfileClick = () => {
    setOpenProfile(!openProfile);
  };
  const handleAffiliateClick = () => {
    setOpenAffiliate(!openAffiliate);
  };

  const handleAccountClick = () => {
    setOpenAccount(!openAccount);
  };

  const handlePaymentsClick = () => {
    setOpenPayments(!openPayments);
  };

  return (
    <div className="container ps-2  pb-3 pe-0  inter">
      <div className="mt-4 Custom-scrolll position-relative">
        <ul className="ps-0 sideNav">
          {/* Profile */}
          <li className="mt-4">
            <div className="d-flex" onClick={handleProfileClick}>
              <div className="pe-3">
                <img src={theme === "light" ? profiledark : profilelight} width={30} alt="" />
              </div>
              <div>
                <NavLink to="/profile" className={openProfile ? 'active' : ''}>
                  Profile
                </NavLink>
              </div>
              <div className="plusicon position-absolute">{openProfile ? "-" : "+"}</div>
            </div>
            {openProfile && (
              <ul>
                {/* Profile Info */}

                <li className="mt-2">
                  <NavLink className="" to="/profile">
                    Profile Info
                  </NavLink>
                </li>
                <li className="mt-2">
                  <NavLink className="" to="/myvideos">
                    My Videos
                  </NavLink>
                </li>
                {/* Notifications */}
                <li className="mt-2">
                  <NavLink className="" to="/notification">
                    Notifications
                  </NavLink>
                </li>
                {/* Downloads */}
                <li className="mt-2">
                  <NavLink className="" to="/downloads">
                    Downloads
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          {userDetail?.is_affiliate == 1 &&
            <li className="mt-4">
              <div className="d-flex" onClick={handleAffiliateClick}>
                <div className="pe-3">
                  <img  src={theme === "light" ? profiledark : profilelight} width={30} alt="" />
                </div>
                <div>
                  <NavLink to="/affiliate" className={openAffiliate ? 'active' : ''}>
                    Affiliate Dashboard
                  </NavLink>
                </div>
                <div className="plusicon position-absolute">{openAffiliate ? "-" : "+"}</div>
              </div>
              {openAffiliate && (
                <ul>
                  {/* Profile Info */}
                    <>
                      <li className="mt-2">
                        <NavLink className="" to="/affiliate">
                        Dashboard
                        </NavLink>
                      </li>
                    </>
                  <li className="mt-2">
                    <NavLink className="" to="/referrals">
                      Referrals
                    </NavLink>
                  </li>
                  {/* Notifications */}
                  <li className="mt-2">
                    <NavLink className="" to="/billing">
                      Payout Details
                    </NavLink>
                  </li>
                  {/* Downloads */}
                 
                </ul>
              )}
            </li>
          }
          {/* Account */}
          <li className="mt-4">
            <div className="d-flex" onClick={handleAccountClick}>
              <span className="pe-3">
                <img src={theme === "light" ? accountdark : accountlight} width={30} alt="" />
              </span>
              <div>
                <NavLink className={openAccount ? 'active' : ''} to="/account/setting">
                  Account
                </NavLink>
              </div>
              <div className="plusicon position-absolute">{openAccount ? "-" : "+"}</div>
            </div>
            {openAccount && (
              <ul>
                {/* Account Info */}
                <li className="mt-2">
                  <NavLink className="" to="/account/setting">
                    Account Setting
                  </NavLink>
                </li>
                {/* Change Password */}
                {/* <li className="mt-2">
                  <NavLink className="" to="/ProfileresetPassword">
                    Change Password
                  </NavLink>
                </li> */}
              </ul>
            )}
          </li>
          {/* Subscriptions & Payments */}
          <li className="mt-4">
            <div className="d-flex" onClick={handlePaymentsClick}>
              <div className="pe-3">
                <img  src={theme === "light" ? sublight : subdark} width={30} alt="" />
              </div>
              <div>
                <NavLink className={openPayments ? 'active' : ''} to="/user/plan">
                  Subscriptions & <br /> Payments
                </NavLink>
              </div>
              <div className="plusicon position-absolute">{openPayments ? "-" : "+"}</div>
            </div>
            {openPayments && (
              <ul>
               
                {/* Upgrade Membership Plan */}
                <li className="mt-2">
                  <NavLink className="" to="/user/plan">
                    Upgrade Membership Plan
                  </NavLink>
                </li>
                {/* View Purchase History */}
                <li className="mt-2">
                  <NavLink className="" to="/purchased/history">
                    View Purchase History
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileSidBar;
