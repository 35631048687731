/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import { IoIosPlayCircle } from "react-icons/io";
import { BiSolidHeartCircle } from "react-icons/bi";
import { FaShareNodes } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { checkItemExistence, checkUserScope } from "../utils/helpers";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useDispatch, useSelector } from "react-redux";
import { addToFavourite } from "../redux/slices/videoSlice";
import { toast } from "react-toastify";
import lightvideo from '../assetr/videoaplayericon.png';
import darkvideo from '../assetr/darkvideoa.png';
import videoCardDefault from '../assetr/video-card-default.jpg';

const VideoCard = ({
  video,videoType
}) => {
  const videoRoute = '/video/detail/' + video?.cloud_flare_video_id+'/'+videoType;
  const shortRoute = '/video/short/' + video?.cloud_flare_video_id+'/'+videoType;
  const { theme ,userDetail} = useSelector((state) => state.user);
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const [isFavourite, setFavourite] = useState(false)
  useEffect(() => {
    setFavourite(checkItemExistence(video?.favourite, userDetail?.id));
  }, [video,userDetail]);
  const handleAddToFavourite = (cloud_flare_video_id, title, type) => {
    setFavourite(!isFavourite);
    // if (checkUserScope("addtofav", userDetail?.currentplan)) {
      let data = {
        video_id: cloud_flare_video_id,
        title: title,
        type: type,
      };
      dispacth(addToFavourite(data, handleFavResponse));
    // } else {
    //   alert("You don't have permission to Add this video to Favourite");
    // }
  }
  const handleFavResponse = (data) => {
    toast.success(data?.message, {
      position: "top-right",
      autoClose: 2000,
    });
  };
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef && videoRef.current) {
      const player = videojs(videoRef.current, {
        controls: true,
        preload: "auto",
      });
      player.src({
        src: `https://customer-m033z5x00ks6nunl.cloudflarestream.com/${video?.cloud_flare_video_id}/manifest/video.m3u8`,
        type: "application/x-mpegURL",
      });
      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, []);

  const [imgSrc, setImgSrc] = useState(videoCardDefault);

  const handleImageLoad = () => {
    setImgSrc(`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${video?.cloud_flare_video_id}/thumbnails/thumbnail.jpg`);
  };

  const handleImageError = () => {
    setImgSrc(videoCardDefault); // Fallback to default image if the actual image fails to load
  };

  return (
    <>
      <Link to={`${videoType==='short'?shortRoute:videoRoute}`}>
        <img 
        src={imgSrc} 
      onLoad={handleImageLoad}
      onError={handleImageError} 
        className="w-100" alt="" style={{ height: 'auto' }} />
      </Link>
      <div className="addtocard " onClick={() => {
        userDetail?.id !== undefined
          ?handleAddToFavourite(
            video?.cloud_flare_video_id,
            video?.title,
            video?.video_url ? "long" : "clip"
          )
          : alert(
            "Please login or register to add this to your favourite"
          );
      }}>
        <div className="ms-5 ">
          <div className="addtocart rounded-2">
            <a
              href="javascript:void(0)"
              title={
                userDetail !== undefined
                  ? "Add to Favourite"
                  : "Please login or register to add this to your favourite"
              }
            >
              <div
                className={`ms-3 icon-container ${isFavourite
                  ? "favourite"
                  : ""
                  }`}
              >
                <BiSolidHeartCircle className=" " />
              </div>
            </a>
          </div>
        </div>
      </div>

      <Link to={`${videoType==='short'?shortRoute:videoRoute}`}>
        <button className="watch-now-btn rounded" style={{ background: 'transparent' }}>
          Watch now
        </button>
        <div className="cardcontent">
          <div>
            <p className="ps-2">
              {video?.title?.length > 20 ? video?.title?.substring(0, 20) + '...' : video?.title}
            </p>
          </div>
          <div className="d-flex mb-3 ms-3">
            <div className="d-flex align-items-center video-duration">
              <p className="mb-0">
                <img src={theme === "light" ? lightvideo : darkvideo} className="videoicon" alt="" />
              </p>
              <span className="ms-1">
                {video?.video_url
                  ? ""
                  : parseInt(video?.end) - parseInt(video?.start) + "s"}
              </span>
            </div>
          </div>
        </div>
      </Link>
      {/* <div
        onTouchStart={window.innerWidth < 500 ? onHover : null}
        onTouchMove={onHide}
        onTouchEnd={onHide}
        onContextMenu={(e) => e.preventDefault()}
        onMouseOver={window.innerWidth < 500 ? onHover : () => setVideo(true)}
        onMouseLeave={window.innerWidth < 500 ? onHide : () => setVideo(false)}
      >
        <div class="shimmer-card">
          <div
            class=" d-flex justify-content-center "
            style={{ cursor: "pointer" }}
          >
            {isVideo ? (
              <iframe
                src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${video?.cloud_flare_video_id}/iframe?muted=true&autoplay=true&poster=https%3A%2F%2Fcustomer-np227mkvthh0ptnr.cloudflarestream.com%2F${video?.cloud_flare_video_id}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=true`}
                loading="lazy"
                style={{ width: "100%" }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            ) : (
              <img
                className="img-fluid"
                src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${video?.cloud_flare_video_id}/thumbnails/thumbnail.jpg`}
                alt=""
                style={{ width: "100%" }}
              />
            )}
          </div>
          <Link
            to={`/video/detail/${video?.cloud_flare_video_id}/${video?.video_url ? "long" : "clip"
              }`}
            class="loader-shimmer-content"
          >
            <div class="loader-shimmer-header" style={{ marginTop: 5 }}>
              <div class="loader-shimmer-title">
                {video?.parent_video?.title?.length > 0 ?
                  video?.parent_video?.title?.length > 35 ? video?.parent_video?.title?.substring(0, 35) + '...' : video?.parent_video?.title
                  :
                  video?.title?.length > 35 ? video?.title?.substring(0, 35) + '...' : video?.title
                }
              </div>
              <div className="play-box d-flex align-items-center">
                <IoIosPlayCircle size={25} className="coloryellow video-play-icon" />
                <p className="m-0">
                  {video?.video_url
                    ? ""
                    : parseInt(video?.end) - parseInt(video?.start) + "s"}
                </p>
              </div>
            </div>
            <div class="loader-shimmer-list">{video?.title?.length > 35 ? video?.title?.substring(0, 35) + '...' : video?.title}</div>
          </Link>
          <div class="loader-shimmer-info video-tags">
            <p className="tags">#tag1</p>
            <p className="tags">#tag2</p>
            <p className="tags">#tag3</p>
          </div>
          <div class="loader-shimmer-info video-tags">
            <a
              href="javascript:void(0)"
              title={
                userId !== undefined
                  ? "Add to Favourite"
                  : "Please login or register to add this to your favourite"
              }
            >
              <div
                className={`ms-3 icon-container ${isFavourite
                  ? "favourite"
                  : ""
                  }`}
                onClick={() => {
                  userId !== undefined
                    ? handleAddToFavourite(
                      video?.cloud_flare_video_id,
                      video?.title,
                      video?.video_url ? "long" : "clip"
                    )
                    : alert(
                      "Please login or register to add this to your favourite"
                    );
                }}
              >
                <BiSolidHeartCircle className=" " />
              </div>
            </a>
            <a href="javascript:void(0)">
              <div
                className="ms-3 icon-container"
                style={{ cursor: "pointer" }}
                onClick={() => handleShare(video?.uid, video?.title)}
              >
                <FaShareNodes className="" />
              </div>
            </a>
          </div>

        </div>
      </div> */}
    </>
  );
};

export default VideoCard;
