import React from "react";
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";

export const ProfileChangePassword = () => {
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent">Profile</h6> */}
        <div className="row justify-content-center">
          <div className="col-md-3 mt-3">
          <div className="profileinfo p-2 rounded-3 p-3">
          
          <ProfileSidBar/>
          </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3 ">
              <h6 className="font-inter fw-700 font-20"> Account Info </h6>
            </div>
            <div className="row">
              <div className="col-3"></div>

              <div className="col-12">
                <div className="profileinfo p-4 rounded-1 mt-3">
                  <h6 className="font-inter fw-700 font-12">
                  Personal Info
                  </h6>
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins text-white mb-2"
                      >
                        {" "}
                        Public Name
                      </label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="custom-input"
                      />
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins text-white mb-2"
                      >
                        {" "}
                        Profile Profile
                      </label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="custom-input"
                      />
                    </div>

                    <div className="col-lg-6 col-12 mt-2 ">
                      <label>Country</label>
                      <select id="country" class="custom-select">
                        <option selected></option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div> 
                    <div className="col-lg-6 col-12 mt-2">
                      <label>City</label>
                      <select id="country" class="custom-select">
                        <option selected></option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="d-flex">
                    <div className="">
                        <button
                          type="button"
                          className="btn-login px-2 py-2 rounded-3  mt-2"
                        >
                        Save Changes
                        </button>
                      </div>
                    
                      <div className="ms-3 ">
                        <button
                          type="button"
                          className="signupwithgoogle px-2 py-2 rounded-3 mt-2"
                        >
                          {" "}
                          Discard All
                        </button>
                      </div>
                    </div>
                     
                   
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
