import { useEffect, useState } from 'react';
import './App.css'
import AppRoutes from './routes';
import { useSelector } from 'react-redux';
function App() {
  const { theme } = useSelector((state) => state.user);
  useEffect(() => {
    localStorage.setItem('theme', theme); // Save the theme mode to local storage
    document.body.className = theme; // Apply the theme mode to the body element
  }, [theme]);
  return (
    <div className={`${theme === 'light' ?'light-mode':'dark-mode'}`}>
      <AppRoutes/>
    </div>
  );
}

export default App;
