import React from "react";
import { BiSolidHeartCircle } from "react-icons/bi";
export const Favorite = ({addVideoToFavourite,userDetail,title,uid}) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-12 p-1 position-relative mt-lg-4 mt-md-3 mt-3">
      <img src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${uid}/thumbnails/thumbnail.gif?time=1s&height=200&duration=4s`} className="w-100" alt="" />
      <div className="addtocard ">
                      <div className="ms-5 ">
                        
                      </div>
                    </div>
      <div className="cardcontent">
        <div>
          <p className="ps-2">{title}</p>
        </div>
        <div className="d-flex mb-3 ms-3">
          <div className="d-flex align-items-center">
            <p className="mb-0 ">
              {" "}
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M10.0619 20C15.5715 20 20.0378 15.5228 20.0378 10C20.0378 4.47715 15.5715 0 10.0619 0C4.55232 0 0.0859375 4.47715 0.0859375 10C0.0859375 15.5228 4.55232 20 10.0619 20Z" fill="white" />
                <path d="M14.0139 9.29298L8.61029 6.16797C8.36088 6.0013 8.02836 6.0013 7.77895 6.16797C7.52954 6.33466 7.36328 6.58464 7.36328 6.8763V13.0846C7.36328 13.3763 7.52954 13.668 7.77895 13.793C7.90365 13.8763 8.06991 13.9179 8.19462 13.9179C8.31932 13.9179 8.48558 13.8763 8.61029 13.793L13.9724 10.668C14.2218 10.5013 14.388 10.2513 14.388 9.95963C14.388 9.66796 14.2633 9.41798 14.0139 9.29298Z" fill="#0D1824" />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
