import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { useDispatch } from "react-redux";
import { resetCurrentPassword } from "../redux/slices/userSlice";
import { Alert, Spinner } from 'reactstrap';
export const ProfileresetPassword = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleAlertDismiss = () => {
    setError(null);
  };
  const handleChangePassword = () => {
    if (newPassword === confirmNewPassword) {
      setLoading(true);
      let data = {
        current_password: currentPassword,
        new_password: confirmNewPassword
      }
      dispatch(resetCurrentPassword(data, handleResponse))
    }
  }
  const handleResponse = (data) => {
    setLoading(false);
    if (data?.status) {
      alert("Your Password is Updated")
    }else{
      setError(data?.message)
    }
  }
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent">Profile</h6> */}
        <div className="row justify-content-center">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">
              <ProfileSidBar isAccount={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4  mt-3">
              <h6 className="font-inter fw-700 font-20"> Change Password </h6>
            </div>
            <div className="row">
              <div className="col-3"></div>

              <div className="col-12">
                <div className="profileinfo p-4 rounded-1 mt-3">
                  <div className="col-md-6 col-12 mt-2">
                    <label
                      htmlFor=""
                      className="font-16 font-700 poppins content mb-1"
                    >
                      Confirm Current Password
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm your Current Password"
                      className="custom-input1"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <a
                      href="javascript:void(0)"
                      className="font-13"
                      style={{ textDecoration: "none", color: "#E2B912" }}
                    >
                      Forgot your Password?
                    </a>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-700 poppins content mb-1"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        placeholder="New Password"
                        className="custom-input"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />

                    </div>
                    <div className="col-md-6 col-sm-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-700 poppins content mb-1"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        className="custom-input"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </div>
                    {error &&
                        <Alert style={{ marginTop: 20 }} color="danger" isOpen={!!error} toggle={handleAlertDismiss}>
                          {error}
                        </Alert>

                      }
                    <div className="d-flex">
                      <div className=" mt-3">
                        <div className="text-center">
                          <button
                            onClick={handleChangePassword}
                            type="button"
                            className="btn-login px-2 py-2 rounded-3  mt-2"
                          >
                          {loading ? <Spinner size="sm" color="light" /> : "Change Password"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
