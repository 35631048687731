import React from 'react'
import Navbar from '../component/Navbar'
import Videosport from '../component/Videosports'
import Fotter from '../component/Fotter'

const Sports = () => {
  return (
    <div>
      <Navbar />
       <Videosport  />
       <Fotter />
    </div>
  )
}

export default Sports
