/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import { formatDistanceToNow } from 'date-fns';
import { IoIosPlayCircle } from "react-icons/io";
import { BiSolidHeartCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { checkItemExistence, checkUserScope } from "../utils/helpers";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useDispatch, useSelector } from "react-redux";
import { addToFavourite } from "../redux/slices/videoSlice";
import { toast } from "react-toastify";
import lightvideo from '../assetr/videoaplayericon.png';
import darkvideo from '../assetr/darkvideoa.png';
import videoCardDefault from '../assetr/video-card-default.jpg';
import userimg from '../assetr/logo.png';
const NewVideoCard = ({
    video, videoType
}) => {
    const videoRoute = videoType === "long" ? "/video/long/" + video?.cloud_flare_video_id : '/video/detail/' + video?.cloud_flare_video_id + '/' + videoType;
    const shortRoute = '/video/short/' + video?.cloud_flare_video_id + '/' + videoType;
    const { theme, userDetail } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFavourite, setFavourite] = useState(false);
    const formatDate = (date) => {
        return formatDistanceToNow(new Date(date), { addSuffix: true });
    };
    useEffect(() => {
        setFavourite(checkItemExistence(video?.favourite, userDetail?.id));
    }, [video, userDetail]);

    const handleAddToFavourite = (cloud_flare_video_id, title, type) => {
        setFavourite(!isFavourite);
        // if (checkUserScope("addtofav", userDetail?.currentplan)) {
        let data = {
            video_id: cloud_flare_video_id,
            title: title,
            type: type,
        };
        dispatch(addToFavourite(data, handleFavResponse));
        // } else {
        //     alert("You don't have permission to Add this video to Favourite");
        // }
    }

    const handleFavResponse = (data) => {
        toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
        });
    };

    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef && videoRef.current) {
            const player = videojs(videoRef.current, {
                controls: true,
                preload: "auto",
            });
            player.src({
                src: `https://customer-m033z5x00ks6nunl.cloudflarestream.com/${video?.cloud_flare_video_id}/manifest/video.m3u8`,
                type: "application/x-mpegURL",
            });
            return () => {
                if (player) {
                    player.dispose();
                }
            };
        }
    }, []);

    const [imgSrc, setImgSrc] = useState(videoCardDefault);

    const handleImageLoad = (videoId) => {
        setImgSrc(`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${videoId}/thumbnails/thumbnail.jpg`);
    };

    const handleImageError = () => {
        setImgSrc(videoCardDefault); // Fallback to default image if the actual image fails to load
    };

    return (
        <>
            <div className="video-card-wrapper">
                <div className="video-card">
                    <Link
                        to={`${videoType === 'short' ? shortRoute : videoRoute}`}
                        state={{ data: { video } }}
                    >
                        <div className="video-thumbnail">
                            <img
                                src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${video?.cloud_flare_video_id}/thumbnails/thumbnail.jpg`}
                                onLoad={() => handleImageLoad(video?.cloud_flare_video_id)}
                                onError={handleImageError}
                                alt=""
                            />
                            <div className="video-duration">
                                {video?.video_url ? "" : `${parseInt(video?.end) - parseInt(video?.start)}s`}
                            </div>
                        </div>
                    </Link>
                    <div className="video-info">
                        <div className="row">
                            <div className="col-md-1" style={{ paddingRight: 0 }}>
                                <img src={userimg} alt="Creator" className="creator-img" />
                            </div>
                            <div className="col-md-11">
                                <div className="video-title republictextwhite">
                                    {video?.title?.length > 60 ? `${video?.title.substring(0, 60)}...` : video?.title}
                                </div>
                                <div className="video-uploader">
                                    Admin
                                </div>
                                <div className="video-uploader">
                                    {video?.views ? video?.views : 0} Views - {formatDate(video.created_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default NewVideoCard;
