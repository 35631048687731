import React from 'react'
import video from '../assetr/Video/mp4.mp4';
import video1 from '../assetr/Video/mp3.mp4';
import video2 from '../assetr/Video/mp5.mp4';

export const Videos = () => {
  return (
    <div>
        <div className="container">
            <div className="row">
                
                <div className="col-12 px-0">
                <video
                   className='w-100'
            
                    muted
                    loop
                    autoPlay
                  >
                    <source src={video1} type="video/mp4" />
                  </video>
                
                </div>
            </div>
               
        </div>
    </div>
  )
}
