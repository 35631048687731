import React, { useState, useEffect } from "react";
import Navbar from "../component/Navbar";
import profileonline from "../assetr/profileonline.png";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { getUserProfille, updateProfile, updateProfilePicture } from "../utils/ApiCalls";
import { countries } from '../utils/countries';
import { useDispatch, useSelector } from "react-redux";
import { affiliateRequest, earlygRequest } from "../redux/slices/userSlice";
import { checkUserScope } from "../utils/helpers";
export const Profile = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.user);
  // const [userDetail, setuserDetail] = useState({});
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [bio, setBio] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [birthday, setBirthDay] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState('');
  const [earlygStatus,setEarlyGStatus]=useState(0)

  useEffect(() => {
    setName(userDetail?.name);
    setEmail(userDetail?.email);
    setBio(userDetail?.bio);
    setCountry(userDetail?.country);
    setOccupation(userDetail?.occupation);
    setProfileImage(userDetail?.google_image == null ? userDetail?.image : userDetail?.google_image)
    setEarlyGStatus(userDetail?.is_early_bird)
  }, [userDetail]);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const objectUrl = URL.createObjectURL(file);
    setProfileImage(objectUrl);

    // Do something with the selected file
  };
  const handleUpdateProfilePicture = async () => {
    let data = {
      image: selectedFile
    }
    updateProfilePicture(data);
  }
  const handleUpdateProfile = async () => {
    let data = {
      name: name,
      email: email,
      bio: bio,
      occupation: occupation,
      country: country,
    }
    updateProfile(data);
  }

  const handleAffiliateRequest = () => {
    setAffiliateMessage("Please wait...")
    dispatch(affiliateRequest(handleAffiliateResponse))
  }
  const [message, setMessage] = useState(null);
  const [affiliateMessage, setAffiliateMessage] = useState("(Click here to become an Affiliate to start earning from website)");
  const handleAffiliateResponse = (data) => {
    if (data?.status) {
      setAffiliateMessage("(Your Request for Affiliate is awaiting approval)")
    }
  }

  const handleEarlyGResponse = (data) => {
    if (data?.status) {
      setEarlyGStatus(3)
      setAffiliateMessage("(Your Request for Early G is awaiting approval)")
    }
  }
  const handleEarlyGRequest = (tier) => {
    setAffiliateMessage("Please wait...")
    dispatch(earlygRequest(tier, handleEarlyGResponse))
  }
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 style={{ color: 'white' }}>Loading, Please wait...</h1>
  //     </div>
  //   )
  // }
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage">
        <div className="container">
          <h1>
            Profile
          </h1>
          {earlygStatus === 0 &&
            <div className="row pt-2">
              <p>Are you a real G, or faking it until you make it? Then commit if your spirit is true. And don't join if you intend to quit.</p>
              <div className="col-md-6">
                <ul>
                  <li>
                  Join our Discord Server and go to "Promos and Giveaways" Channel for deatailed information
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn-login p-4 px-2 py-2 rounded-3 mt-2"
                      onClick={() => handleEarlyGRequest(1)}
                    >
                      Spartans Join Here
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                  Join our Discord Server and go to "Promos and Giveaways" Channel for deatailed information
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn-login p-4 px-2 py-2 rounded-3 mt-2"
                      onClick={() => handleEarlyGRequest(2)}
                    >
                      Helots Join Here
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          }
          {earlygStatus === 3 &&
            <>
              <a href="javascript:void(0)" className="mb-2" style={{ color: '#FFDE59' }}>
                (Your Request for Early G is awaiting approval)
              </a>
            </>
          }
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">
              <ProfileSidBar isProfile={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4  mt-3">
              <h6 className="font-inter fw-700 font-20"> Profile Info<br />
                {checkUserScope('affiliateprogram', userDetail?.currentplan) &&
                  userDetail?.is_affiliate == 0 ?
                  <a href="javascript:void(0)" style={{ color: '#FFDE59' }} onClick={handleAffiliateRequest}>
                    {message ?
                      message :
                      affiliateMessage
                    }

                  </a> :
                  userDetail?.is_affiliate == 2 ?
                    <a href="javascript:void(0)" style={{ color: '#FFDE59' }}>
                      (Your Request for Affiliate is awaiting approval)
                    </a> : ""
                }

              </h6>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-6">
                <div for="update-profile" className="profileinfo p-2 rounded-1 mt-3">
                  <div className="d-flex">
                    <div className="mt-2 p-2 ms-3 avataricon position-relative profile-image">
                      <img src={profileImage} alt="ProfilePicture" />
                    </div>
                    <input onChange={handleFileChange} type="file" id="update-profile-input" />
                    <div className="ps-4 mt-2">
                      <label for="update-profile-input" style={{ cursor: 'pointer' }} className="font-inter font-12 fw-700">
                        Change Avatar
                      </label>
                      <p className="font-inter font-10 ">
                        120x120p size minimum
                      </p>
                      <button
                        onClick={handleUpdateProfilePicture}
                        type="button"
                        className="btn-login px-2 py-2 rounded-3"
                      >
                        Update Profile Picture
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="profileinfo p-4 rounded-1 mt-2">
                  <h6 className="font-inter fw-700 font-12">
                    About your Profile
                  </h6>
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins content mb-1"
                      >
                        {" "}
                        Public Email
                      </label>
                      <input
                        type="text"
                        placeholder="Your Email"
                        className="custom-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins content mb-1"
                      >
                        {" "}
                        Profile Name
                      </label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="custom-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <textarea
                        className="custom-textarea"
                        placeholder="Write a little description about you..."
                        cols="30"
                        rows="4"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                    </div>
                    {/* <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins text-white mb-2"
                      >
                        {" "}
                        Birthday
                      </label>
                      <input
                        type="date"
                        placeholder="mm/dd/yyyy"
                        className="custom-input"
                      />
                    </div> */}
                    <div className="col-lg-6 col-12 mt-2">
                      <label
                        htmlFor=""
                        className="font-16 font-500 poppins content mb-1"
                      >
                        Occupation
                      </label>
                      <input
                        type="text"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        placeholder="Your Occupation"
                        className="custom-input"
                      />
                    </div>
                    <div className="col-lg-6 col-12 mt-3 mb-3">
                      <label className="mb-1">Country</label>
                      <select id="country" onChange={(e) => setCountry(e.target.value)} class="custom-select form-control">
                        <option selected></option>
                        {countries?.length > 0 &&
                          countries.map((val, index) => {
                            return (
                              <option value={val?.name} selected={country && country == val?.name ? 'true' : false}>{val?.name}</option>
                            )
                          })

                        }
                      </select>
                    </div>

                    <div className="d-flex">
                      <div className="">
                        <button
                          type="button"
                          onClick={handleUpdateProfile}
                          className="btn-login px-2 py-2 rounded-3  mt-2"
                        >
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
