import React, { useState } from 'react'
import logo from "../assetr/logo.png";
import { useNavigate } from 'react-router-dom'
import { Alert, Spinner } from 'reactstrap';
import { Videos } from '../component/Videos';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../redux/slices/userSlice';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleResponse = (data) => {
        setLoading(false);
        if (data?.status) {
            localStorage.setItem('tate-clip-user-email', email)
            navigate('/otp')
        } else {
            setError(data?.message)
        }
    }
    const handleForgotPassword = () => {
        setLoading(true);
        let data = {
            email: email
        }
        dispatch(forgotPassword(data, handleResponse))
    }
    const handleAlertDismiss = () => {
        setError(null);
    };
    return (
        <div>
            <div className="container-fluid min-100vh p-0">
                <div className="row min-100vh">
                    <div className="col-4 backgroundimges">
                        <Videos />
                    </div>
                    <div className="col-lg-8 col-md-8 col-12 d-flex flex-column justify-content-between pt-lg-5 pt-md-5 pt-3 px-0">
                        <div className="container-fluid">
                            <div className="row justify-content-center me-lg-5 me-md-5 login-right-side">
                                <div className="col-lg-8 col-md-8 col-12 text-center">
                                    <img src={logo} className=' mt-2 mb-3' width={150} alt="" />
                                    <h3 className=' font-inter mt-3 fw-700 font-20 coloryellow'>Forgot Password</h3>
                                    <p className='content font-inter font-12'>Enter the email address you used when you joined and we’ll <br />
                                        send you instructions to reset your password.
                                    </p>
                                    <form className="row mt-2">
                                        <div class="col-12 mt-4 text-start">
                                            <label htmlFor="" className='mb-1 content'>Email Address</label>
                                            <input type="email" required className="form-control input-field p-3" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" aria-label="Email" />
                                            {error &&
                                                <Alert style={{ marginTop: 20 }} color="danger" isOpen={!!error} toggle={handleAlertDismiss}>
                                                    {error}
                                                </Alert>

                                            }
                                            <div className='d-flex justify-content-center '>
                                                <button type='button' className='btn-login px-4 py-2 mt-4 rounded-3' onClick={handleForgotPassword}>
                                                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
