/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
const Tabs = ({
  collectionsData,
  handleSelectCollection,
  selectedCollection,
}) => {

  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
      <div className="container">
        <div className="row mt-3">
          <Slider {...settings}>
            {collectionsData?.length > 0 &&
              collectionsData.map((val, index) => (
                <a
                  style={{
                    backgroundColor:
                      selectedCollection == val?.id ? "" : "",
                  }}
                  href="javascript:void(0)"
                  onClick={() => handleSelectCollection(val?.id,val?.title)}
                  className={`dragonsbtn rounded-2 px-2 p-1 ms-2 font-lato fw-400 ${selectedCollection === val?.id ? "activeTab" : ""
                    }`}
                >
                  {val?.title?.length > 10 ? val?.title?.substring(0, 15) + '...' : val?.title}
                </a>
              ))}
          </Slider>
        </div>
      </div>
  );
};

export default Tabs;
