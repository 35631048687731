import React, { useState } from 'react'
import logo from "../assetr/logo.png";
import { useNavigate } from 'react-router-dom'
import { Alert, Spinner } from 'reactstrap';
import { Videos } from '../component/Videos';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../redux/slices/userSlice';
const ResetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(null);
    const [cpassword, setCPassword] = useState(null);

    const email = localStorage.getItem('tate-clip-user-email');

    const handleConfirmPassword = (e) => {
        let value = e.target.value;
        setCPassword(value)
        if (value !== password) {
            setError("Password didn't match")
        }else{
            setError(null)
        }
    }

    const handleResponse = (data) => {
        setLoading(false);
        if (data?.status) {
            localStorage.removeItem('tate-clip-user-email', email)
            localStorage.setItem('video-paltform-token', data?.access_token)
            navigate('/verified/success');
        } else {
            setError(data?.message)
        }
    }
    const handleResetPassword = () => {
        if(password?.length>0 && cpassword?.length>0){
            setLoading(true);
            let data = {
                email: email,
                password: password
            }
            dispatch(resetPassword(data, handleResponse))
        }else{
            setError("Please enter Password to continue");
        }
        
    }
    const handleAlertDismiss = () => {
        setError(null);
    };
    return (
        <div>
            <div className="container-fluid min-100vh p-0">
                <div className="row min-100vh">
                    <div className="col-4 backgroundimges">
                        <Videos />
                    </div>
                    <div className="col-lg-8 col-md-8 col-12 d-flex flex-column justify-content-between pt-lg-5 pt-md-5 pt-3 px-0">
                        <div className="container-fluid">
                            <div className="row justify-content-center me-lg-5 me-md-5 login-right-side">
                                <div className="col-lg-8 col-md-8 col-12 text-center">
                                    <img src={logo} className=' mt-2 mb-3' width={150} alt="" />
                                    <h3 className=' font-inter mt-3 fw-700 font-20' style={{ color: '#ECC870' }}>Reset Password</h3>
                                    <p className='republictextwhite font-inter font-12'> Choose a new password for your account
                                    </p>
                                    <form className="row mt-2">
                                        <div class=" col-12 mt-4">
                                            <label htmlFor="" className='republictextwhite'>Your New Password</label>
                                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="form-control input-field p-3" placeholder="Enter Your New Password" aria-label="Password" />

                                        </div>
                                        <div class=" col-12 mt-4">
                                            <label htmlFor="" className='republictextwhite'>Confirm your New Password</label>
                                            <input type="password" value={cpassword} onChange={handleConfirmPassword} required className="form-control input-field p-3" placeholder="Confirm your New Password" aria-label="Password" />

                                        </div>
                                        {error &&
                                            <Alert style={{ marginTop: 20 }} color="danger" isOpen={!!error} toggle={handleAlertDismiss}>
                                                {error}
                                            </Alert>
                                        }
                                        <div className="col-12">

                                            <div>
                                                <button type='button' className='btn-login px-4 py-2 mt-3 rounded-3' onClick={handleResetPassword}>
                                                {loading ? <Spinner size="sm" color="light" /> : "Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
