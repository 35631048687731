import React, { useEffect } from 'react'
import Navbar from '../component/Navbar'
import { Accordain } from '../component/Accordain';
import Fotter from "../component/Fotter";
import { useDispatch, useSelector } from 'react-redux';
import { getFaqData } from '../redux/slices/themeSlice';
const Faqs = () => {
  const dispatch = useDispatch();
  const { faqs } =
    useSelector((state) => state.theme);
  useEffect(() => {
    dispatch(getFaqData());
  }, [dispatch]);
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row px-sm-0 px-4">
          <h2 className='content my-5 font-60 font-inter'>
            FAQ
          </h2>
          <Accordain faqs={faqs} />
        </div>
      </div>
      <Fotter />
    </div>
  )
}

export default Faqs
