import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import debitdark from '../assetr/creditcard.png'
import debitlight from '../assetr/lightcreditcard.png'
import darkMembershipBg from '../assetr/creditcard.png';
import lightMembershipBg from '../assetr/lightcreditcard.png';
import paypallight from '../assetr/lightpaypal.png';
import paypaldark from '../assetr/paypal.png';
import ProfileSidBar from "../component/ProfileSidBar";
import Fotter from "../component/Fotter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutDetail, updatePayoutDetail } from "../redux/slices/userSlice";

export const BillingandPayment = () => {
  const { userDetail, theme } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispacth = useDispatch();

  const [stripeEmail, setStripeEmail] = useState(null);
  const [taxId, setTaxId] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);

  const { userPayout } = useSelector((state) => state.user);

  useEffect(() => {
    dispacth(getPayoutDetail())
  }, []);
  const handleStripeCard = () => {
    let data = {
      stripeEmail: stripeEmail,
      taxId: taxId,
      name: name,
      address: address
    }
    dispacth(updatePayoutDetail(data))
  }
  useEffect(() => {
    if(userPayout?.id){
      setStripeEmail(userPayout?.stripe_email);
      setTaxId(userPayout?.tax_id);
      setName(userPayout?.name);
      setAddress(userPayout?.address)
    }
  }, [userPayout]);
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent">Profile</h6> */}
        <div className="row justify-content-center">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">
              <ProfileSidBar isAffiliate={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3 ">
              <h6 className="font-inter fw-700 font-20"> Billing & Payout </h6>
            </div>
            <div className="row">
              <div className="col-3"></div>
              <div className="col-12">
                <div className="profileinfo p-4 rounded-1 mt-3">
                  <h6>Payment Information</h6>
                  <div className="d-flex">
                    <div> <img src={theme === "light" ? lightMembershipBg : darkMembershipBg} width={50} alt="" /> </div>
                    <div className="ms-2"> <img src={theme === "light" ? debitlight : debitdark} width={50} alt="" /> </div>
                    <div className="ms-2"> <img src={theme === "light" ? paypallight : paypaldark} width={50} alt="" /> </div>
                  </div>
                  <h6 className="mt-1"> Enter Your Payment Details </h6>
                  <div className="col-12 mt-2">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      Stripe Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your Email"
                      className="custom-input1"
                      value={stripeEmail}
                      onChange={(e) => setStripeEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="custom-input1"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <label
                      className="font-16 font-500 poppins content mb-2">
                      Tax ID(If applicable)
                    </label>
                    <input
                      type="text"
                      placeholder="Tax ID"
                      className="custom-input1"
                      value={taxId}
                      onChange={(e) => setTaxId(e.target.value)}
                    />
                  </div>

                  <div className="col-12 mt-2 d-block">
                    <label
                      htmlFor="address"
                      className="font-16 font-500 poppins content mb-2"
                    >
                      Address
                    </label>
                    <textarea value={address} id="address" className="custom-input w-100" onChange={(e) => setAddress(e.target.value)}>
                      
                    </textarea>
                  </div>

                  <div className="row">
                    <div class=" col-12 mt-4">
                      <button type='button' className='btn-login px-4 py-2 mt-3 rounded-3' onClick={handleStripeCard}>Submit</button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
