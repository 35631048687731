import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { uploadVideoToCloud } from '../redux/slices/videoSlice';
import dropzonImage from '../assetr/dropzone.gif';
import { ToastContainer, toast } from "react-toastify";

function ReactDropzone({ handleResponseUpload }) {
    const dispacth = useDispatch();
    const { categories } =
        useSelector((state) => state.video);
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [newFileName, setFileName] = useState(null);
    const [categoryId, setCategory] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]; // Only process the first file
        if (!file) return;

        const previewUrl = URL.createObjectURL(file);

        if (file.type.startsWith('video/')) {
            setFileName(file.name)
            setPreview({ type: 'video', src: previewUrl, name: file.name });
            setSelectedFile(file);
        } else {
            setPreview({ type: 'file', name: file.name });
            setSelectedFile(null);
        }
    }, []);
    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);
            };

            video.onerror = () => {
                reject(new Error('Failed to load video metadata.'));
            };

            video.src = URL.createObjectURL(file);
        });
    };
    const uploadFile = async () => {
        if (!selectedFile) {
            alert("No file selected for upload.");
            return;
        }
        const duration = await getVideoDuration(selectedFile);
        console.log('Video duration:', duration);
        setUploading(true);
        const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });
        const formData = new FormData();
        formData.append('file', newFile);

        try {
            const response = await axios.post('https://api.cloudflare.com/client/v4/accounts/3595a34d4e6f3615cc1fb5372256f0c7/stream', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer nZCO6cOAdoKDEspspV50fj5J8RfcM0V2nZUcmowe'
                }
            });

            toast.success("Video Uploaded Successfully!", {
                position: "top-right",
                autoClose: 2000,
            });
            let data = {
                videoId: response?.data?.result?.uid,
                title: response?.data?.result?.meta?.name,
                categoryId: 1,
                duration: parseInt(duration)
            }
            dispacth(uploadVideoToCloud(data, handleResponseUpload))
            // window.location.reload();
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file.');
        } finally {
            setUploading(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'video/*', // Accept only video files
        maxFiles: 1 // Allow only one file
    });

    return (
        <div className='row'>
            <ToastContainer />
            <div className='col-md-12 text-center' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a video file here, or click to select a video file</p>
                {!preview &&
                    <img src={dropzonImage} style={{ width: "60%" }} className='img-fluid' />
                }

            </div>
            <div className='col-md-12 text-center'>
                {preview && (
                    <div>
                        <p>
                            <div className='row'>
                                <div className='form-group col-md-6'>
                                    <label for="title">
                                        Please Enter Video Title
                                    </label>
                                    <input className='form-control' name='title' id='title' value={newFileName} onChange={(e) => setFileName(e.target.value)} />
                                </div>
                                {/* <div className='form-group col-md-6'>
                                    <label for="title">
                                        Please Select Category
                                    </label>
                                    <select className='form-control' name='category_id' onChange={(e)=>setCategory(e.target.value)}>
                                    <option>Please Select</option>
                                        {categories?.length > 0
                                            && categories.map((val, index) => {
                                                return (
                                                    <option value={val?.id}>{val?.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                            </div>
                        </p>
                        {preview.type === 'video' && <video src={preview.src} controls style={{ width: "100%" }} />}
                        {preview.type === 'file' && <p>Cannot preview this file type</p>}
                    </div>
                )}
            </div>
            <div className='col-md-12 text-left mb-1'>
                {selectedFile && (
                    <button className="btn-login px-2 py-2 rounded-3  mt-2" onClick={uploadFile} disabled={uploading}>
                        {uploading ? 'Uploading...' : 'Upload Video'}
                    </button>
                )}
            </div>
        </div>
    );
}

export default ReactDropzone;
