/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faFilter } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom/dist";
import { checkUserScope, paginateArray, shuffleArray } from '../utils/helpers'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoCard from "../component/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecentSearches,
  getAllLongVideos,
  getCategoryWithClips,
  getCategoryWithVideos,
  getLatestVideos,
  getRecentSearches,
  getShortVideos,
  getVideoClips,
} from "../redux/slices/videoSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import NewVideoCard from "../component/NewVideoCard";
import Tabs from '../component/Tabs'
import { FaFilter } from "react-icons/fa";
const Home = () => {
  const [collectionsData, setCollections] = useState(null);
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filtericon, setfiltericon] = useState(false);
  const [queryVideoType, setQueryVideoType] = React.useState(null)
  const [queryVideoCategory, setQueryVideoCategory] = React.useState(null)

  const [orderBy, setOrderBy] = React.useState('')
  const [type, setType] = React.useState('')
  const [color, setColor] = React.useState('')
  const handleChangeVideoType = (event) => {
    setQueryVideoType(event.target.value)
  }
  const handleChangeCategory = (event) => {
    setQueryVideoCategory(event.target.value)
  }
  const handleChangeType = (event) => {
    setType(event.target.value)
  }

  const handlefiltericon = () => {
    setfiltericon(!filtericon);
  };

  const handleChangeColor = (event) => {
    setColor(event.target.value)
  }
  const { longVideos, isVideoLoading } =
    useSelector((state) => state.video);
  const handleSelectCollection = (categoryId) => {
    setSelectedCollection(categoryId)
    if (parseInt(categoryId) === 1) {
      // Get the current date
      const currentDate = new Date();

      // Get the date 30 days ago from the current date
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // Filter videoClips based on categoryId and created_at within the last 30 days
      const filteredVideoClips = longVideos.filter(video => {
        // Convert the created_at timestamp to a Date object
        const createdAtDate = new Date(video.created_at);
        // Check if the createdAtDate is within the last 30 days
        return createdAtDate >= thirtyDaysAgo && createdAtDate <= currentDate;
      });

      // Shuffle the filteredVideoClips array
      const shuffledClips = shuffleArray(filteredVideoClips);
      setFilteredData(shuffledClips)
    } else {
      const filteredLongVideos = longVideos.filter(video => video?.category_id === categoryId);
      // Shuffle the filteredVideoClips array
      const shuffledClips = shuffleArray(filteredLongVideos);

      // Get random  clips from the shuffled array
      // const randomClips = shuffledClips.slice(0, 00);
      setFilteredData(shuffledClips)
    }


  }

  const { themeData } =
    useSelector((state) => state.theme);
  const { videos, latestVideos, shortVideos, videoClips, recentSearch } =
    useSelector((state) => state.video);
  const { userDetail } = useSelector((state) => state.user);
  //Recent Searchs
  useEffect(() => {
    setSuggestions(recentSearch);
    const filteredSuggestions = recentSearch.filter(
      (item) => item?.user_id === userDetail?.id
    );
    setUserSuggestions(filteredSuggestions);
  }, [recentSearch, userDetail]);
  useEffect(() => {
    setSuggestions([]);
    setUserSuggestions([]);
    dispacth(getLatestVideos());
    dispacth(getShortVideos());
    dispacth(getVideoClips());
    dispacth(getAllLongVideos())
  }, [dispacth]);
  const handleSearchVideos = async (e) => {
    e.preventDefault();
    let data = {
      search: search,
    };
    dispacth(addRecentSearches(data));
    if (queryVideoType === 'long') { // Ensure 'queryVideoType' is defined in your component's state
      navigate("/videos?search=" + encodeURIComponent(search));
    } else {
      navigate("/video/result?search=" + encodeURIComponent(search) + "&type=" + encodeURIComponent(queryVideoType) + "&category=" + encodeURIComponent(queryVideoCategory));
    }
  };

  const handleSelectRecentSearch = (value) => {
    if (queryVideoType === 'long') { // Ensure 'queryVideoType' is defined in your component's state
      navigate("/videos?search=" + encodeURIComponent(value));
    } else {
      navigate("/video/result?search=" + encodeURIComponent(value) + "&type=" + encodeURIComponent(queryVideoType) + "&category=" + encodeURIComponent(queryVideoCategory));
    }
  };
  const [isShowRecentSearch, setRecentSearcShow] = useState(false)
  const handleRecentSearch = () => {
    dispacth(getRecentSearches());
    setRecentSearcShow(true);
    setfiltericon(false);
  };
  const handleSearchQuery = (e) => {
    setSearch(e.target.value);
    let query = e.target.value;
    const filteredSuggestions = recentSearch.filter((item) =>
      item?.query?.toLowerCase().includes(query?.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="container-fluid bgimage opcity"
        style={{
          backgroundImage: `url(https://portal.tateclips.com/asset/frontend/light/images/banner/${themeData?.banner?.content?.hero_background})`,
        }}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <div>
                <p className="font-14 font-inter mb-0 fw-500">
                  {themeData?.banner?.content?.title}
                </p>
                <h3 className="font-inter font-40 fw-700">
                  {themeData?.banner?.content?.description} <br />
                </h3>
              </div>
              <div className="row justify-content-center position-relative">
                <div className="col-lg-10">

                  <form method="get" onSubmit={handleSearchVideos}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control rounded-0"
                        placeholder="Search your video here..."
                        aria-describedby="basic-addon2"
                        value={search}
                        onClick={handleRecentSearch}
                        onChange={handleSearchQuery}
                      />
                      <span
                        className="input-group-text px-4 rounded-0"
                        id="basic-addon2"
                      >
                        <button
                          type="submit"
                          style={{ border: 0, background: "transparent" }}
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </form>
                  {isShowRecentSearch &&
                    <div className="suggestion-box">
                      {suggestions?.length > 0 && (
                        <p className="suggestion-item d-flex justify-content-between">
                          <span>Recent Search</span>
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setSuggestions([]);
                              setUserSuggestions([]);
                            }}
                            style={{ color: "black" }}
                          >
                            Close
                          </a>
                        </p>
                      )}

                      {userSuggestions?.length > 0 &&
                        userSuggestions.map((val, index) => {
                          return (
                            <p
                              onClick={() => handleSelectRecentSearch(val?.query)}
                              className="suggestion-item"
                            >
                              {val?.query}
                            </p>
                          );
                        })}
                      {suggestions?.length > 0 &&
                        suggestions.map((val, index) => {
                          return (
                            <p
                              onClick={() => handleSelectRecentSearch(val?.query)}
                              className="suggestion-item"
                            >
                              {val?.query}
                            </p>
                          );
                        })}
                    </div>
                  }
                  <div onClick={handlefiltericon} className="position-absolute filtericons">
                    <FaFilter size={20} color="black" />
                  </div>
                </div>
                <div className="col-md-10 mt-1">
                  <div className={`card ${filtericon ? '' : 'd-none'}`}>
                    <h1 className='card-title text-center' style={{ marginTop: '10px', fontSize: '25px' }}>Filters</h1>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6 col-6'>
                          <div className='form-group'>
                            <label className="ms-2">
                              Video Type
                            </label>
                            <select className='select-field' onChange={handleChangeVideoType}>
                              <option value={"all"}>
                                Please Select
                              </option>
                              <option value={"long"}>
                                Long Videos
                              </option>
                              <option value={"short"}>
                                Short Clips
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-6 col-6'>
                          <div className='form-group'>
                            <label className="ms-2">
                              Video category
                            </label>
                            <select className='select-field' onChange={handleChangeCategory}>
                              <option value={"all"}>
                                Please Select
                              </option>
                              <option value={"short"}>
                                Short
                              </option>
                              <option value={"aicurated"}>
                                AI Curated
                              </option>
                              <option value={"humancurated"}>
                                Human Curated
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <div className="container-fluid pt-4 mt-3 p-lg-5">
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="font-inter font-18 fw-600 mb-0">
                <span className="linenew">Shorts</span>
              </p>
            </div>
            <div>
              <button
                onClick={() => navigate("/video/short/all/short")}
                type="button"
                className="btn-Viewmore"
              >
                View more
              </button>
            </div>
          </div>
          <div className="row">
            <Slider {...settings}>
              {shortVideos?.length > 0 &&
                shortVideos.map((val, index) => (
                  <div
                    className="col-lg-3 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
                    key={index}
                  >
                    <VideoCard video={val} videoType="short" />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="font-inter font-18 fw-600 mb-0">
                <span className="linenew">New</span>
              </p>
            </div>
            <div>
              <button
                onClick={() => navigate("/captions?type=new")}
                type="button"
                className="btn-Viewmore"
              >
                View more
              </button>
            </div>
          </div>
          <div className="row">
            {latestVideos?.length > 0 &&
              latestVideos.map((val, index) => (
                <div
                  className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
                  key={index}
                >
                  <NewVideoCard video={val} videoType="clip" />
                </div>
              ))}
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="font-inter font-18 fw-600 mb-0">
                <span className="linenew">Micro Clips</span>
              </p>
            </div>
            <div>
              <button
                onClick={() => navigate("/videos?type=micro")}
                type="button"
                className="btn-Viewmore"
              >
                View more
              </button>
            </div>
          </div>
          <div className="row">
            {videoClips?.length > 0 &&
              videoClips.map((val, index) => {
                return (
                  <div
                    className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
                    key={index}
                  >
                    <NewVideoCard video={val} videoType="clip" />
                  </div>
                )
              })}
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="font-inter font-18 fw-600 mb-0">
                <span className="linenew">AI Curated Clips</span>
              </p>
            </div>
            <div>
              <button
                onClick={() => navigate("/videos?type=ai")}
                type="button"
                className="btn-Viewmore"
              >
                View more
              </button>
            </div>
          </div>
          <div className="row">
            {videoClips?.length > 0 &&
              videoClips.map((val, index) => {
                return (
                  <div
                    className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
                    key={index}
                  >
                    <NewVideoCard video={val} videoType="clip" />
                  </div>
                )
              })}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 col-12 text-center">
            <button
              type="button"
              className="navbtn rounded-5 font-lato"
            >
              LOAD MORE
            </button>
          </div>
        </div>
      </div>

      <Fotter />
    </div>
  );
};

export default Home;
