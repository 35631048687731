import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  isVideoLoading:false,
  createError: null,
  updateError: null,
  getError: null,
  deleteError: null,
  videos: [],
  longVideos:[],
  allLongVideos:[],
  latestVideos: [],
  shortVideos: [],
  videoDetail: {},
  longVideoDetail: {},
  favourites:[],
  recentSearch:[],
  videoClips:[],
  categories:[],
  reportCases:[],
  folders:[]
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    startVideoLoading(state) {
      state.isVideoLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
    stopVideoLoading(state) {
      state.isVideoLoading = false
    },
    //HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false
      state.createError = action.payload
    },
    //UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false
      state.updateError = action.payload
    },
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload
    },

    // GET Long Videos Success
    getLongVideoSuccess(state, action) {
      state.isLoading = false;
      state.longVideos = action.payload
    },
    // GET Clips Success
    getObjectsSuccess(state, action) {
      state.isLoading = false;
      state.videos = action.payload
    },
    // GET Video Detail Success
    getVideoDetailSuccess(state, action) {
      state.isLoading = false;
      state.videoDetail = action.payload
    },
    getLongFormVideoDetailSuccess(state, action) {
      state.isLoading = false;
      state.longVideoDetail = action.payload
    },
     // GET User Success
     getFavouriteSuccess(state, action) {
      state.isLoading = false;
      state.favourites = action.payload
    },
    getRecentSearchSuccess(state, action) {
      state.isLoading = false;
      state.recentSearch = action.payload
    },
    //Long Videos Clips
    getLongVideosSuccess(state, action) {
      state.isLoading = false;
      state.longVideos = action.payload
    },
     //Long Videos Clips
     getAllLongVideosSuccess(state, action) {
      state.isLoading = false;
      state.allLongVideos = action.payload
    },
//Short Videos
getShortVideosSuccess(state, action) {
  state.isLoading = false;
  state.shortVideos = action.payload
},
    //Latest Videos
    getLatestVideosSuccess(state, action) {
      state.isLoading = false;
      state.latestVideos = action.payload
    },
    //Video Clips
    getVideoClipsSuccess(state, action) {
      state.isLoading = false;
      state.videoClips = action.payload
    },

    //Video Categories
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload
    },

    //Support Cases
    getReportCaseSuccess(state, action) {
      state.isLoading = false;
      state.reportCases = action.payload
    },

    //User Folders
    getUserFolderSuccess(state, action) {
      state.isLoading = false;
      state.folders = action.payload
    },

    // GET User DETAILS
    getObjectSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },
  },
})
export const { getObjectSuccess } = videoSlice.actions;
export default videoSlice.reducer

// User Functions

//Category Wise Videos
export function getCategoryWithVideos() {
    return async () => {
      dispatch(videoSlice.actions.startLoading());
      try {
        const response = await axios.get('category/video');
        if(!response.data.status){
          dispatch(videoSlice.actions.hasGetError(response?.data?.message));
        }
        dispatch(videoSlice.actions.getLongVideoSuccess(response.data.data));
      } catch (error) {
        dispatch(videoSlice.actions.hasGetError(error?.message));
      }
    };
  }
export function getCategoryWithClips() {
    return async () => {
      dispatch(videoSlice.actions.startLoading());
      try {
        const response = await axios.get('category/clips');
        if(!response.data.status){
          dispatch(videoSlice.actions.hasGetError(response?.data?.message));
        }
        dispatch(videoSlice.actions.getObjectsSuccess(response.data.data));
      } catch (error) {
        dispatch(videoSlice.actions.hasGetError(error?.message));
      }
    };
  }
//short Videos
export function getShortVideos(page=1,limit=24,search=null) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token');
    let route = `videos/shorts?page=${page}&limit=${limit}`;
    if(search){
      route = `videos/shorts?page=${page}&limit=${limit}&search=${search}`;
    }
    
  dispatch(videoSlice.actions.startVideoLoading());
  try {
    const response = await axios.get(route,{  headers: { 
    'Accept': 'application/json', 
    'Content-Type': 'application/json',
    'Authorization':'Bearer '+accessToken
  }});
  dispatch(videoSlice.actions.stopVideoLoading());
  dispatch(videoSlice.actions.getShortVideosSuccess(response.data.data));
  } catch (error) {
    dispatch(videoSlice.actions.hasGetError(error?.message));
  }
};
}
//Latest Videos Clips
export function getLatestVideos(page=1,limit=24,search=null) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token');
    let route = `videos/latest?page=${page}&limit=${limit}`;
    if(search){
      route = `videos/latest?page=${page}&limit=${limit}&search=${search}`;
    }
    
  dispatch(videoSlice.actions.startVideoLoading());
  try {
    const response = await axios.get(route,{  headers: { 
    'Accept': 'application/json', 
    'Content-Type': 'application/json',
    'Authorization':'Bearer '+accessToken
  }});
  dispatch(videoSlice.actions.stopVideoLoading());
  dispatch(videoSlice.actions.getLatestVideosSuccess(response.data.data));
  } catch (error) {
    dispatch(videoSlice.actions.hasGetError(error?.message));
  }
};
}
  //Long Videos
export function getLongVideos(page=1,limit=24,search=null) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token');
    let route = `videos?page=${page}&limit=${limit}`;
    if(search){
      route = `videos?page=${page}&limit=${limit}&search=${search}`;
    }
  dispatch(videoSlice.actions.startVideoLoading());
  try {
    const response = await axios.get(route,{  headers: { 
    'Accept': 'application/json', 
    'Content-Type': 'application/json',
    'Authorization':'Bearer '+accessToken
  }});
  dispatch(videoSlice.actions.stopVideoLoading());
  dispatch(videoSlice.actions.getLongVideosSuccess(response.data.data));
  } catch (error) {
    dispatch(videoSlice.actions.hasGetError(error?.message));
  }
};
}

  //Long Videos
  export function getAllLongVideos(page=1,limit=1000,search=null) {
    return async () => {
      let accessToken = localStorage.getItem('video-paltform-token');
      let route = `videos?page=${page}&limit=${limit}`;
      if(search){
        route = `videos?page=${page}&limit=${limit}&search=${search}`;
      }
    dispatch(videoSlice.actions.startVideoLoading());
    try {
      const response = await axios.get(route,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    dispatch(videoSlice.actions.stopVideoLoading());
    dispatch(videoSlice.actions.getAllLongVideosSuccess(response.data.data));
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
  }

  //Get User Details
//User Login
export function addToFavourite(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('favourite',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function addToLike(data, handleClose,isDispatchDetail=true) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('likes',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
      if(isDispatchDetail){
        dispatch(getVideoDetail(data['video_id'],data['type']))
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}



export function getFavouriteList(handleResponse) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('favourite',{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    handleResponse(response.data.data);
      dispatch(videoSlice.actions.getFavouriteSuccess(response.data.data));
    } catch (error) {
      handleResponse(error)
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}



export function addRecentSearches(data) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    let route = 'suggestions';
    if(accessToken){
      route = 'auth/suggestions';
    }
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post(route,
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getRecentSearches() {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('suggestions',{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      
      dispatch(videoSlice.actions.getRecentSearchSuccess(response.data.data));
    } catch (error) {
      
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getVideoClips(page=1,limit=24,search=null) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token');
      let route = `clips?page=${page}&limit=${limit}`;
      if(search){
        route = `clips?page=${page}&limit=${limit}&search=${search}`;
      }
      
    dispatch(videoSlice.actions.startVideoLoading());
    try {
      const response = await axios.get(route,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    dispatch(videoSlice.actions.stopVideoLoading());
      dispatch(videoSlice.actions.getVideoClipsSuccess(response.data.data));
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getVideoDetail(id,type) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('video/'+id+"/"+type,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    dispatch(videoSlice.actions.getVideoDetailSuccess(response.data.data));
    } catch (error) {
      
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateVideoViews(id) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('update/video/views/'+id,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    } catch (error) {
      
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getLongVideoDetail(id,type) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('video/long/'+id,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    dispatch(videoSlice.actions.getVideoDetailSuccess(response.data.data));
    } catch (error) {
      
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getLongFormVideoDetail(id) {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('long/video/'+id,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
    dispatch(videoSlice.actions.getLongFormVideoDetailSuccess(response.data.data));
    } catch (error) {
      
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getAllCategories() {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token');
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('category',{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      
      dispatch(videoSlice.actions.getCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getSupportCases() {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token');
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('report/case',{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      
      dispatch(videoSlice.actions.getReportCaseSuccess(response.data.data));
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getFolders() {
  return async () => {
      let accessToken = localStorage.getItem('video-paltform-token');
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.get('folder',{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      
      dispatch(videoSlice.actions.getUserFolderSuccess(response.data.data));
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function createFolder(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('folder',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(response.data.status){
        dispatch(getFolders());
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function addVideoToPlaylist(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('playlist',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(response.data.status){
        dispatch(getFolders());
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function removeFromPlaylist(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('playlist/remove',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}

export function uploadVideoToCloud(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post('creator/video',
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      handleClose(response.data);
      if(response.data.status){
        dispatch(getFolders());
      }
    } catch (error) {
      handleClose(error);
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}
export function createSupportTicket(data) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
   
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post("report/video",
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}


export function addCreatorClip(data,handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post("creator/clip",
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
      handleResponse(response.data)
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}


export function generateDownloadUrl(data,handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(videoSlice.actions.startLoading());
    try {
      const response = await axios.post("download",
    data,{  headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+accessToken
    }});
      if(!response.data.status){
        dispatch(videoSlice.actions.hasGetError(response?.data?.message));
      }
      handleResponse(response.data)
    } catch (error) {
      dispatch(videoSlice.actions.hasGetError(error?.message));
    }
  };
}