// src/ReturnsPolicy.js
import React from 'react';
import Fotter from "../component/Fotter";
import Navbar from "../component/Navbar";


const ReturnsPolicy = () => {
    return (
        <>
            <Navbar />
            <div className="returns-policy-container">
                <div className="returns-policy-content">
                    <h2 className="returns-policy-title">TateClips Returns Policy</h2>
                    <p className="last-updated">Last updated: June 10, 2024</p>
                    <p className="returns-policy-text">
                        As a general principle, we kindly inform you that we do not offer refunds on any of our products or services. This policy is in place due to the digital nature of our resources and the significant investment we make in creating, maintaining, and delivering top-notch content.
                    </p>
                    <h3 className="returns-policy-subtitle">Exceptional Circumstances</h3>
                    <p className="returns-policy-text">
                        We understand that life can be unpredictable, and exceptional circumstances may arise that warrant consideration on a case-by-case basis. If you believe your situation calls for an exception to our refund policy, please don't hesitate to contact our caring support team at support@tateclips.com.
                    </p>
                    <h3 className="returns-policy-subtitle">Cancellation & Replacement Policy</h3>
                    <p className="returns-policy-text">
                        You can cancel your TateClips membership at any time by logging to your user account and changing your preference.
                    </p>
                    <h3 className="returns-policy-subtitle">Questions</h3>
                    <p className="returns-policy-text">
                        For questions relating to our returns policy, please contact us at support@tateclips.com.
                    </p>
                </div>
            </div>
            <Fotter />
        </>
    );
};

export default ReturnsPolicy;
