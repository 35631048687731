/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import reporticon from "../assetr/reporticon.png"
import { BiPencil } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { createSupportTicket, getSupportCases } from '../redux/slices/videoSlice';


function ReportModal({ show, handleClose , videoId}) {
  const dispatch = useDispatch();
  const { reportCases } =
    useSelector((state) => state.video);
  const [review, setReview] = useState(false);
  const [report, setReport] = useState(false);
  const [main, setMain] = useState(true);
  const [aboutreport, setaboutreport] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [reportReasonDescription, setReasonDescription] = useState('');
  const { theme } = useSelector((state) => state.user);

  const handleBackClick = () => {
    if (aboutreport) {
      setaboutreport(false);
      setReport(true);
    } else {
      setReview(false);
      setReport(false);
      setMain(true);
    }
  };
  const handleReasonSelect = (reason, description) => {
    setSelectedReason(reason);
    setReasonDescription(description)
  };
  useEffect(() => {
    dispatch(getSupportCases())
  }, []);

  const handleSubmitReport=()=>{
    let data={
      subject:"Reported Video as "+selectedReason,
      video_uid:videoId,
      message:reportReasonDescription
    }
    dispatch(createSupportTicket(data))
    handleClose();
    
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report this Video</Modal.Title>
        </Modal.Header>
        <Modal.Body  className={`${theme === 'light' ?'light-mode':'dark-mode'}`}>
          {aboutreport &&
            <div className='row'>
              <div className="col-12" onClick={() => aboutreport(true)}>

              </div>
            </div>

          }
          {review &&
            <div className='row'>
              <div className='col-12' onClick={() => setReview(true)}>
                <h6 className='reporttext'>Tell us why to help improve the content</h6>
                <div className='checkbox-container reporttext'>
                  <FormControlLabel control={<Checkbox />} label="I'm not interested in the author
" />
                  <FormControlLabel control={<Checkbox />} label="I'm not interested in this Video" />
                  <FormControlLabel control={<Checkbox />} label="I've seen too many Videos on this topic
" />
                  <FormControlLabel control={<Checkbox />} label="I've seen this post before
" />
                  <FormControlLabel control={<Checkbox />} label="This post is old
" />
                  <FormControlLabel control={<Checkbox />} label="It's something else
" />

                </div>

              </div>

            </div>
          }
          {report &&
            <div className='row'>
              <div className='col-12'>
                <div className="d-flex pt-4 ms-3">
                  <div className="mb-3">
                    {reportCases?.length > 0 &&
                      reportCases.map((val, index) => {
                        return (
                          <a
                            href="#"
                            className={`dragonsbtn1 px-3 p-1 ms-1 font-lato font-10 fw-400 ${selectedReason === val?.title ? 'active' : ''}`}
                            onClick={() => handleReasonSelect(val?.title, val?.description)}
                          >
                            {val?.title}
                          </a>
                        )
                      })
                    }
                    <p className='font-10 mt-3 ms-2 reporttext'> Please select a reason</p>
                  </div>
                </div>
              </div>
            </div>
          }
          {aboutreport &&
            <div className='row'>
              <div className='col-12'>
                <h6 className='reporttext'>You’ve selected the following reason</h6>
                <div className='aboutreport p-2'>
                  <h6 className='reporttext'> {selectedReason}</h6>
                  <p className='reporttext'>{reportReasonDescription}</p>
                </div>
                <h6 className='mt-3 reporttext'>Want to follow the status of your report?</h6>
                <FormControlLabel className='reporttext' control={<Checkbox />} label="Receive updates on this report
" />
              </div>
            </div>
          }
          {main &&
            <div className='row'>
              <div className='col-12' onClick={() => {
                setReview(true)
                setReport(false)
                setMain(false)
              }}>
                {/* <h1>Report this post </h1> */}
                <h6 className='reporttext'> Select an action</h6>
                <div className="repostcontent p-1 rounded-2 row">
                  <div className='col-1'>
                    <BiPencil size={30} className='reporttext'/>

                  </div>
                  <div className='col-10'>
                    <h6 className='reporttext'>  Provide feedback to change your feed </h6>
                    <p className='m-0 reporttext'>If you think this is inappropriate, you can give us feedback instead of reporting.</p>

                  </div>
                  <div className='col-1'>
                    <FaLongArrowAltRight size={25} className='reporttext' />
                  </div>
                </div>
              </div>
              <div className='col-12 mt-2' onClick={() => {
                setReview(false)
                setReport(true)
                setMain(false)
              }}>

                <div className="repostcontent p-1 rounded-2 row">
                  <div className='col-1'>
                    <MdReport size={30} className='reporttext' />

                  </div>
                  <div className='col-10'>
                    <h6 className='reporttext'>  Report content for review </h6>
                    <p className='m-0 reporttext'>Tell us how this goes against our policies or request help for someone.</p>

                  </div>
                  <div className='col-1'>
                    <FaLongArrowAltRight size={25} className='reporttext' />
                  </div>
                </div>
              </div>
            </div>
          }

          {/* <div className='col-12'>
            <button onClick={() => {
                setReview(false)
                setReport(false)
                setMain(true)
              }} className='btn btn-warning' disabled={main}>Back</button>
          </div> */}

        </Modal.Body>
        {review && (
          <Modal.Footer className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
            <Button variant="primary" onClick={handleBackClick}>
              Back
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Submit
            </Button>
          </Modal.Footer>
        )}
        {report && (
          <Modal.Footer className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
            <Button variant="primary" onClick={handleBackClick}>
              Back
            </Button>
            <Button variant="primary" onClick={() => {
              if (selectedReason) {
                setReport(false);
                setaboutreport(true);
              }
            }} disabled={!selectedReason}>
              Next
            </Button>
          </Modal.Footer>
        )}
        {aboutreport && (
          <Modal.Footer className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
            <Button variant="primary" onClick={handleBackClick}>
              Back
            </Button>
            <Button variant="primary" onClick={handleSubmitReport}>
              Submit report
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ReportModal;