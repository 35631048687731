import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";

import Fotter from "../component/Fotter";
import { generateStripeCheckout, getAllPaymentGateways } from "../utils/ApiCalls";
import { generateRandomToken } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

export const PaymentGateways = () => {
    const navigate = useNavigate();

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    // Get a specific parameter value
    const id = queryParams.get('id');
    const type = queryParams.get('type');

    const [gateways, setGateways] = useState([]);
    const [planId, setPlanId] = useState();
    const [subscriptionType, setType] = useState();
    const [loading, setLoading] = useState(false);

    const getPaymentGateways = async () => {
        setGateways(await getAllPaymentGateways())
    }
    useEffect(() => {
        setPlanId(id);
        setType(type);
    }, [id, type]);
    useEffect(() => {
        getPaymentGateways();
    }, []);

    const [paymentGatewayId, setPaymentGatewayId] = useState(null);
    const handlePayNow = (gatewayId) => {
        setLoading(true);
        setPaymentGatewayId(gatewayId);
        let firstToken = generateRandomToken(60);
        let secondToken = generateRandomToken(60);

        let data = {
            payment: planId,
            gatewayId: gatewayId,
            subscriptionType: subscriptionType
        }
        generateStripeCheckout(data, handleResponse)
    }
    const handleResponse = (data) => {
        setLoading(false);
        if (data?.is_now) {
            navigate('/payment/nowpayment');
        } else {
            window.location.href = data.data.url
        }
    }
    return (
        <div>
            <Navbar />
            {loading &&
                <div class="loading">Loading&#8230;</div>
            }
            <div className="container">
                <h1 className="text-center content  font-inter  font-75 fw-500 mt-5">
                    Choose Payment Gateway
                </h1>
                <div className="row">
                    {gateways?.data?.length > 0 &&
                        gateways?.data.map((val, index) => {
                            return (
                                <div className="col-lg-4 p-3 rounded-2">
                                    <div className="card p-4">
                                        <h3 className="card-title text-center" style={{ textTransform: 'uppercase' }}>{val?.name}</h3>
                                        <div className="card-body text-center">
                                            <img src={val?.image} className="img-fluid" alt={val?.name} />
                                            <button onClick={() => handlePayNow(val?.id)} type='button' className='getstarted px-5 mt-3'>
                                                {loading ? <Spinner size="sm" color="light" /> : "Pay Now"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }

                </div>

            </div>

            <Fotter />
        </div>
    );
};
