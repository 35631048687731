import React from 'react'
import logo from '../assetr/logo.png'
import { FaRegCircle } from "react-icons/fa6";
import youtube from '../assetr/youtubeicon.png'
import vimo from '../assetr/vimoicon.png'
import pintrest from '../assetr/pintresticon.png'
import link from '../assetr/linkicon.png'
import facebook from '../assetr/facbookicon.png'
import { useSelector } from 'react-redux';
const Fotter = () => {
  const { themeData } =
    useSelector((state) => state.theme);
  return (
    <div>
      <div className="container-fluid bg-footer border-top: 1px solid #F5F5F5 mt-5 p-0">
        <div className="container mb-5 py-lg-5 py-3">
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-12">
              <div className='p-2'>
                <h3 className='font-inter font-18 fw-600 mb-0 fotterline '> <span className='linenew1'>{themeData?.footer?.content?.footer_title}</span> </h3>
                <img src={"https://portal.tateclips.com/asset/frontend/light/images/banner/" + themeData?.banner?.content?.logo} className='logo mt-4  mb-3' alt="w8" />
                <p className='font-inter mt-2 me-3'>{themeData?.footer?.content?.footer_short_details}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 ps-lg-4 ps-md-4 ">
              <div className='p-2'>
                <h3 className='font-inter font-18 fw-600 mb-0 fotterline '> <span className='linenew1'>Quick Links</span> </h3>
                <ul className="info-list mt-3">
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/">Home</a>
                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/videos">Videos</a>
                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/pricing">Pricing</a>
                  </li>

                  {themeData?.about?.content?.is_about_enable === "1" &&
                    <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/about">About us</a>
                  </li>
                  }
                  
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/contact">Contact us</a>
                  </li>

                  {/* Add more Information links as needed */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 ps-lg-4 ps-md-4 ps-0">
              <div className='p-2'>
                <h3 className='font-inter font-18 fw-600 mb-0 fotterline '> <span className='linenew1'>Info</span> </h3>
                <ul className="info-list mt-3">
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/commitment">Our Commitment</a>
                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/returnsPolicy">Returns Policy</a>
                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/acceptableUsePolicy">Acceptable Use Policy</a>

                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/termsOfService">Terms of Service</a>

                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/privacyPolicy">Privacy Policy</a>

                  </li>
                  <li className='fotterline'>
                    <FaRegCircle className="circle-icon" /> <a href="/cookiespolicy">Cookie Policy</a>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='Connectline'>
          <h6 className='text-center font-13 fw-600 font-inter mt-4'> Connect with us </h6>
          <div className='d-flex justify-content-center mt-2 mb-3'>
            <a href=" "> <img src={youtube} width={50} alt="" /> </a>
            <a href=" " className='mx-3'> <img src={vimo} width={50} alt="" /> </a>
            <a href=" "> <img src={pintrest} width={50} alt="" /> </a>
            <a href=" " className='mx-3'> <img src={link} width={50} alt="" /> </a>
            <a href=" "> <img src={facebook} width={50} alt="" /> </a>
          </div>
        </div>
        <div className='text-center mt-3 Copyrightline' >
          <p className='font-13 font-inter mb-0 p-2 mt-2'>Copyright 2024 Tate Clips</p>
        </div>
      </div>
    </div>
  )
}

export default Fotter
