import React from 'react'
import logo from "../assetr/logo.png";
import { useNavigate } from 'react-router-dom'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { Videos } from '../component/Videos';
const Successfulyl = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="container-fluid min-100vh p-0">
                <div className="row min-100vh">
                    <div className="col-4 backgroundimges">
                        <Videos />
                    </div>
                    <div className="col-lg-8 col-md-8 col-12 d-flex flex-column justify-content-between pt-lg-5 pt-md-5 pt-3 px-0">
                        <div className="container-fluid">
                            <div className="row justify-content-center me-lg-5 me-md-5 login-right-side">
                                <div className="col-lg-8 col-md-8 col-12 ">
                                    <div className='row' >
                                        <div className="col-8 mt-lg-5 mt-md-3 mt-3 ms-3">
                                            <div className='bgSUCCESS rounded-3 p-4 text-center'>
                                                <IoMdCheckmarkCircleOutline size={45} className='blue' />
                                                <h3 className='blue'>SUCCESS!</h3>
                                                <h6 className='content font-inter fw-500 font-20'> Your Password has been reset Successfully!
                                                </h6>
                                                <div className='mt-3'>
                                                    <button type='button' onClick={()=>navigate('/profile')} className='btn-login px-5 py-2 rounded-3'>Go To Profile</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Successfulyl
