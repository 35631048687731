import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  createError: null,
  updateError: null,
  getError: null,
  deleteError: null,
  userList: [],
  userDetail: {},
  card: {},
  payment: [],
  affiliateCustomers:[],
  userPayout:{},
  theme:{}
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
    //HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false
      state.createError = action.payload
    },
    //UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false
      state.updateError = action.payload
    },
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload
    },
    // GET User Success
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },
    getUserPayoutSuccess(state, action) {
      state.isLoading = false;
      state.userPayout = action.payload
    },

    //Stripe Card Function
    getUserStripeCard(state, action) {
      state.isLoading = false;
      state.card = action.payload
    },


    //Payments function
    getUserPayment(state, action) {
      state.isLoading = false;
      state.payment = action.payload
    },
    //Affiliate function
    getAffiliateCustomer(state, action) {
      state.isLoading = false;
      state.affiliateCustomers = action.payload
    },
    switchMode(state, action) {
      state.isLoading = false;
      state.theme = action.payload
    },
    logout: (state) => {
      console.log(state.userDetail)
      state.userDetail = null;
    },
  },
})
export const { getUserDetailsSuccess, logout } = userSlice.actions;
export default userSlice.reducer

// User Functions

//User Login
export function loginWithGoogle(data, handleClose) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/google',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function userLogin(data, handleClose) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('user/login',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}


export function forgotPassword(data,handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('forgot-password', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleClose(response.data)
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error)
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function resetPassword(data,handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('reset-password', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleClose(response.data)
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error)
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function resetCurrentPassword(data,handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/password/reset', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleClose(response.data)
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error)
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function verifyOTP(data,handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('otp-verify', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleClose(response.data)
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error)
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Affiliate Request
export function affiliateRequest(handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('profile/affiliate', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleResponse(response.data);
      // dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleResponse(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Early G Request
export function earlygRequest(tier,handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('profile/earlyg?tier='+tier, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleResponse(response.data);
      // dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleResponse(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Get Affiliate Referral
export function getffiliateReferrals() {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('affiliate/referrals', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      dispatch(userSlice.actions.getAffiliateCustomer(response.data.data));
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Contact
export function createContact(data,handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('contact', 
      data,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleResponse(response.data);
      // dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleResponse(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

//Get User Details
export function getUser() {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    console.log(accessToken)
    // dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('auth/profile', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}


export function updatePayoutDetail(data) {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('payout',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      dispatch(userSlice.actions.getUserPayoutSuccess(response.data.data));
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}


export function getPayoutDetail() {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('payout', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      dispatch(userSlice.actions.getUserPayoutSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getPurchaseHistory() {
  return async () => {
    let accessToken = localStorage.getItem('video-paltform-token')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('payments', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUserPayment(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function darkLightModeSwitch(mode) {
  return async () => {
    try {
      
      dispatch(userSlice.actions.switchMode(mode));
    } catch (error) {
    }
  };
}
