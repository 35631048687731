import React from "react";

export const Accordain = ({ faqs }) => {
  return (
    <div className="conatiner">
      <div className="row">
        <div className="col-sm-10 col-12">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            {faqs?.length > 0 &&
              faqs.map((val, index) => {
                return (
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        className="accordion-button collapsed font-inter font-12 mt-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#flush-collapse"+index}
                        aria-expanded="false"
                        aria-controls={"flush-collapse"+index}
                      >
                        {val?.question}
                      </button>
                    </h2>
                    <div
                      id={"flush-collapse"+index}
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {/* <code>
                          Welcome! Sit back and count on us for item-support and
                          assistance!
                        </code> */}
                        <p className="content font-12">
                          {val?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};
