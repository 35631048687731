import React from "react";
import payment from '../assetr/mastercard.png'
import paymentone from '../assetr/visa-logo.png'
import paymenttwo from '../assetr/googlePay.png'
import { FaCircle } from "react-icons/fa";

const Checkoutstep = () => {
  return (
    <div>
      <h3 className="text-center text-white">Checkout step 1 of 2 </h3>
      <ul
        class="nav nav-pills mytab mb-3 justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
         
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div className="container">
            <div className="row">
              <div className="text-center text-white">
              <FaCircle size={40} />
                <h6>Join Company Name</h6>
                <p>
                  Already have an ccount?{" "}
                  <a href=" " className=" text-white">
                    Log in
                  </a>{" "}
                </p>
              </div>
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  {" "}
                  Name
                </label>
                <input
                  type="email"
                  class="form-control input-fieldblack p-2 rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  {" "}
                  Email
                </label>
                <input
                  type="password"
                  class="form-control input-fieldblack p-2 rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>

              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  Password
                </label>
                <select
                  class="form-select input-fieldblack p-2  rounded-3 mb-3"
                  aria-label="Default select example"
                >
                  <option selected></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                 
                </label>
                <input
                  type="password"
                  class="form-control input-login p-2 text-center rounded-3"
                  placeholder="Complete Purchase"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <p className="font-16 font-500 poppins text-white mb-0 text-center">
              Or, continue with    {" "}
                  <a href=" " className=" text-white">
                  Google
                  </a>{" "}  or {" "}
                  <a href=" " className=" text-white">
                  Facebook
                  </a>{" "}
              
                </p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabindex="0"
        >
          <div className="container">
            <div className="row">
              <div className="text-center text-white">
                <h6>Payment details</h6>
                <div className="d-flex justify-content-center">
                    <div className="paymentimg px-2 py-1 rounded-3">

                    <a href=" "> <img src={payment} width={30}  alt="" /></a>
                    </div>
                    <div className="paymentimg mx-2 px-2 py-1 rounded-3">

                    <a href=" "> <img src={paymentone} width={30} alt="" /></a>
                    </div>
                    <div className="paymentimg px-2 py-1 rounded-3">

                    <a href=" "> <img src={paymenttwo} width={30}  alt="" /></a>
                    </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  {" "}
                  Name on Card
                </label>
                <input
                  type="email"
                  class="form-control input-fieldblack p-2 rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  {" "}
                  Card Number
                </label>
                <input
                  type="password"
                  class="form-control input-fieldblack p-2 rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>

              <div className="col-6 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  MM/YY
                </label>
                <input
                  type="password"
                  class="form-control input-fieldblack p-2  rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-6 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  CVC
                </label>
                <input
                  type="password"
                  class="form-control input-fieldblack p-2  rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-6 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  Select Country
                </label>
                <select
                  class="form-select input-fieldblack p-2  rounded-3 mb-3"
                  aria-label="Default select example"
                >
                  <option selected></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label font-12 poppins text-white" for="flexCheckDefault">
                    I am purchased of personal use
                  </label>
                </div>
              </div>
              <div className="col-6 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                  Zip Code
                </label>
                <input
                  type="password"
                  class="form-control input-fieldblack p-2  rounded-3"
                  placeholder=""
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              
            
              <div className="col-12 mb-3">
                <label
                  htmlFor=""
                  className="font-16 font-500 poppins text-white mb-2"
                >
                 
                </label>
                <input
                  type="password"
                  class="form-control input-login p-3 text-center rounded-3"
                  placeholder="Complete Purchase"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkoutstep;
