import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom'
import { persistor, store } from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import './index.css';
import App from './App';
import { Provider } from 'react-redux'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <GoogleOAuthProvider  clientId='205027145792-91rr7mugp7glaenggp2clth4bs433msm.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
