import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	TabContent, TabPane, Nav,
	NavItem, NavLink, Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import TabsData from './TabsData';
import VideoCard from './VideoCard';
import NewVideoCard from './NewVideoCard';
// import { TabsData } from './TabsData';

function ClipsTab({ aiClips, humanClips, allClips }) {

	// State for current active Tab 
	const [currentActiveTab, setCurrentActiveTab] = useState('1');

	// Toggle active state for Tab 
	const toggle = tab => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	}
	const [limit, setLimit] = useState(28);
	const handlLoadMore = async () => {
		setLimit(parseInt(limit) + 24);
	  }
	return (
		<div className='react-tabs'>
			<Nav tabs className='justify-content-evenly'>
				<NavItem className=''>
					<NavLink
						className={classnames({
							active:
								currentActiveTab === '1'
						})}
						onClick={() => { toggle('1'); }}
					>
						{"Caption Clips(" + parseInt(allClips?.length) + ")"}
					</NavLink>
				</NavItem>
				<NavItem className=''>
					<NavLink
						className={classnames({
							active:
								currentActiveTab === '2'
						})}
						onClick={() => { toggle('2'); }}
					>
						{"AI Curated Clips(" + aiClips?.length + ")"}
					</NavLink>
				</NavItem>
				<NavItem className=''>
					<NavLink
						className={classnames({
							active:
								currentActiveTab === '3'
						})}
						onClick={() => { toggle('3'); }}
					>
						{"Human Curated Clips(" + humanClips?.length + ")"}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={currentActiveTab}>
				<TabPane tabId="1">
					<Row>
						{allClips?.length > 0 &&
							allClips.map((val, index) => {
								if(index<limit)
								return (
									<div
										className="col-lg-4 p-1 col-md-4 col-sm-6 col-12 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
										key={val?.index}
									>
										<NewVideoCard
											video={val}
											videoType="clip"
										/>
									</div>
								)
							})
						}
					</Row>
					{allClips?.length > 0 && <div className="row mt-5">
						<div className='col-md-12 col-12 text-center'>
							<button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
								LOAD MORE
							</button>
						</div>
					</div>}
				</TabPane>
				<TabPane tabId="2">
					<Row>
						{aiClips?.length > 0 &&
							aiClips.map((val, index) => {
								if(index<limit)
								return (
									<div
										className="col-lg-4 p-1 col-md-4 col-sm-6 col-12 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
										key={val?.index}
									>
										<NewVideoCard
											video={val}
											videoType="clip"
										/>
									</div>
								)
							})

						}
					</Row>
					{aiClips?.length > 0 && <div className="row mt-5">
						<div className='col-md-12 col-12 text-center'>
							<button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
								LOAD MORE
							</button>
						</div>
					</div>}
				</TabPane>
				<TabPane tabId="3">
					<Row>
						{humanClips?.length > 0 &&
							humanClips.map((val, index) => {
								if(index<limit)
								return (
									<div
										className="col-lg-4 p-1 col-md-4 col-sm-6 col-12 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
										key={val?.index}
									>
										<NewVideoCard
											video={val}
											videoType="clip"
										/>
									</div>
								)
							})

						}
					</Row>
					{humanClips?.length > 0 && <div className="row mt-5">
						<div className='col-md-12 col-12 text-center'>
							<button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
								LOAD MORE
							</button>
						</div>
					</div>}
				</TabPane>
				<TabPane tabId="4">
					<Row>
						<Col sm="12">
							{/* <TabsData/> */}
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</div>
	);
}

export default ClipsTab;
