import React from 'react'
import '../App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//Components
import ProtectedRoute from './protectedRoute'
import Home from '../page/Home';
import Videodetail from '../page/Videodetail';
import Searchresult from '../component/Searchresult';
import Videopage from '../page/Videopage';
import { Pricing } from '../page/Pricing';
import Sports from '../page/Sports';
import Checkoutsteponeoftywo from '../page/Checkoutsteponeoftywo';
import Aboutus from '../page/Aboutus';
import ContactUs from '../page/ContactUs';
import Login from '../page/Login';
import Faqs from '../page/Faqs';
import Register from '../page/Register';
import ForgotPassword from '../page/ForgotPassword';
import Successfulyl from '../page/Successfulyl';
import ResetPassword from '../page/ResetPassword';
import { Favritevideo } from '../page/Favritevideo';
import { Profile } from '../page/Profile';
import { BillingandPayment } from '../page/BillingandPayment';
import { ProfileChangePassword } from '../page/ProfileChangePassword';
import { ProfileresetPassword } from '../page/ProfileresetPassword';
import { ProfileNotification } from '../page/ProfileNotification';
import { Upgrademembership } from '../page/Upgrademembership';
import DownloadedVideos from '../page/DownloadedVideos';
import DownloadVideo from '../page/DownloadVideo';
import AddToCartView from '../page/AddToCartView';
import Videoadtocart from '../page/Videoadtocart';
import { PaymentGateways } from '../page/PaymentGateways';
import PaymentSuccess from '../page/PaymentSuccess';
import OtpVerification from '../page/OtpVerification';
import { Affiliate } from '../page/Affiliate';
import PaymentCancel from '../page/PaymentCancel';
import Refarrals from '../page/Refarrals';
import NowPayment from '../page/NowPayment';
import LongFormVideoclips from '../page/LongFormVideoclips';
import Myvideo from '../component/Myvideo';
import Myvideocotent from '../component/Myvideocotent';
import { CreatorPage } from '../page/CreatorPage';
import NewHome from '../page/NewHome';
import ShortVideo from '../page/ShortVideo';
import NewVideoCard from '../component/NewVideoCard';
import SearchResultPage from '../page/SearchResultPage';
import Captions from '../page/Captions';
import Commitment from '../page/Commitment';
import ReturnsPolicy from '../page/ReturnsPolicy';
import AcceptableUsePolicy from '../page/AcceptableUsePolicy';
import TermsOfService from '../page/TermsOfService';
import PrivacyPolicy from '../page/PrivacyPolicy';
import CookiesPolicy from '../page/CookiesPolicy';

function AppRoutes() {
  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/newhome' element={<NewHome />} />
        <Route path='/video/detail/:id/:type' element={<Videodetail />} />
        <Route path='/about' element={<Aboutus />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/faq' element={<Faqs />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/video/result' element={<SearchResultPage />} />
        <Route path='/videos' element={<Videopage />} />
        <Route path='/captions' element={<Captions />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/checkoutsteponeoftywo' element={<Checkoutsteponeoftywo />} />
        <Route path='/contactUs' element={<ContactUs />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/resetPassword' element={<ResetPassword />} />
        <Route path='/otp' element={<OtpVerification />} />
        <Route path='/verified/success' element={<Successfulyl />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route path='/pricing/plan/:id1/:id2' element={<PaymentGateways />} />
          <Route path='/payment/success' element={<PaymentSuccess />} />
          <Route path='/payment/nowpayment' element={<NowPayment />} />
          <Route path='/payment/cancel' element={<PaymentCancel />} />
          <Route path='/favourit/videos' element={<Favritevideo />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/affiliate' element={<Affiliate />} />
          <Route path='/notification' element={<ProfileNotification />} />
          <Route path='/downloads' element={<DownloadedVideos />} />
          <Route path='/billing' element={<BillingandPayment />} />
          <Route path='/account/setting' element={<ProfileresetPassword />} />
          <Route path='/ProfileChangePassword' element={<ProfileChangePassword />} />
          <Route path='/user/plan' element={<Upgrademembership />} />
          <Route path='/purchased/history' element={<DownloadVideo />} />
          <Route path='/addToCartView' element={<AddToCartView />} />
          <Route path='/videoadtocart' element={<Videoadtocart />} />
          <Route path='/referrals' element={<Refarrals />} />
          <Route path='/myvideos' element={<Myvideo />} />
          <Route path='/creator' element={<CreatorPage />} />
          <Route path='/myvideos/content' element={<Myvideocotent />} />
          <Route path='/video/long/:id' element={<LongFormVideoclips />} />
          <Route path='/video/short/:id/:type' element={<ShortVideo />} />
          <Route path='/newvideocard' element={<NewVideoCard />} />
          <Route path='/commitment' element={<Commitment />} />
          <Route path='/returnsPolicy' element={<ReturnsPolicy />} />
          <Route path='/acceptableUsePolicy' element={<AcceptableUsePolicy />} />
          <Route path='/termsOfService' element={<TermsOfService />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/cookiespolicy' element={<CookiesPolicy />} />
       
        </Route>
      </Routes>
      </div>
    </Router>
  )
}

export default AppRoutes
