import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import purchase from "../assetr/purchasepic.png";
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { getAllDownloads } from "../utils/ApiCalls";
import { paginateArray } from "../utils/helpers";

const DownloadedVideos = () => {
  const [downloads, setDownloads] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const pages = Math.round(downloads?.length / 5);
  const handlNext = async () => {
    if (page < pages) {
      setFilteredData(await paginateArray(downloads, parseInt(limit), parseInt(page) + 1));
      setLimit(parseInt(limit))
      setPage(parseInt(page) + 1)
    }
  }
  const handlPrev = async () => {
    if (page !== 1) {
      setFilteredData(await paginateArray(downloads, parseInt(limit), parseInt(page) - 1));
      setLimit(parseInt(limit))
      setPage(parseInt(page) - 1)
    }
  }
  const handlPageClick = async (pageVal) => {
    setFilteredData(await paginateArray(downloads, parseInt(limit), parseInt(pageVal)));
    setPage(parseInt(pageVal))
  }
  useEffect(() => {
    const getProfileDownloads = async () => {
      // setCollections(await getAllCollections())
      let data = await getAllDownloads();
      setFilteredData(await paginateArray(data, limit, page))
      setDownloads(data);
    }
    getProfileDownloads();
  }, []);

  const [search,setSearch] = useState(null);
  const handleSearch = () => {
    const filteredDownloads = downloads.filter((val) => {
      // Convert title and search query to lowercase for case-insensitive search
      const title = val.title.toLowerCase();
      const query = search.toLowerCase();
      // Check if the title contains the search query
      return title.includes(query);
    });
    // Update state with filtered downloads
    setFilteredData(filteredDownloads);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent"> Profile</h6> */}
        <div className="row">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">

              <ProfileSidBar isProfile={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
              <h6 className="font-inter fw-700 font-20"> Downloads </h6>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6"></div>
              <div className="col-12">
                <div className="profileinfo p-4 rounded-1 mt-3">
                  <div className="d-flex">
                    <div className="search-container">
                      <input type="text" className="custom-input" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search..." />
                    </div>
                    <button onClick={handleSearch} type="button" style={{marginLeft:10}} className="searchicon rounded-2">
                      <FaSearch />
                    </button>
                  </div>
                  <table class="custom-table mt-3">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.length > 0 ?
                        filteredData.map((val, index) => {
                          return (
                            <tr className="membershinlein">
                              <td  >
                                <img src={val?.thumbnail} style={{ width: '10%' }} alt="" />
                                <span className="font-inter" style={{ marginLeft: '10px' }}>
                                  {val?.title}
                                </span>
                              </td>
                              <td>{val?.created_at}</td>
                              <td>
                                <button class="delete-button">Delete</button>
                              </td>
                            </tr>
                          )
                        }) :

                        <tr className="membershinlein">
                          <td colSpan={3}>
                            <h2>No Downloads Found</h2>
                          </td>
                        </tr>
                      }


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination" style={{ backgroundColor: "transparent" }}>
                <li className="page-item">
                  <button type="button" onClick={handlPrev} className="page-link" aria-label="Previous">
                    Previous
                  </button>
                </li>
                {Array.from({ length: pages }, (_, index) => (
                  <li key={index} className="page-item ms-1 rounded-2">
                    <button
                      type="button"
                      onClick={() => handlPageClick(index + 1)}
                      className={`page-link ${(index + 1) === page ? 'active-page' : ''}`}>{index + 1}</button>
                  </li>
                ))}

                <li className="page-item ms-1">
                  <button type="button" onClick={handlNext} className="page-link" aria-label="Next">
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};

export default DownloadedVideos;
