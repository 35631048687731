import React from "react";
import { useSelector } from "react-redux";

import Navbar from "../component/Navbar";
import { BsArrowRightShort } from "react-icons/bs";
import linedark from "../assetr/linetestimoniol.png";
import linlight from "../assetr/lightmode.png";
import Testimoniol from "../component/Testimoniol";
import Fotter from "../component/Fotter";
import { Videos } from "../component/Videos";
import { useNavigate } from "react-router-dom";
const Aboutus = () => {
  const { theme } = useSelector((state) => state.user);
  const { themeData } =
    useSelector((state) => state.theme);
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="container">
        <h3 className="font-inter fw-500 ms-sm-0 ms-4 content font-50 my-5">
          {themeData?.about?.content?.title}
        </h3>
        <div className="row aboutuspage p-4 mx-sm-1 mx-4 rounded-2 mt-2">
          <div className="col-lg-4 col-md-6 mb-md-0 mb-4">
            <h1 className="font-inter fw-500  text-white  font-50">
              {themeData?.about?.content?.sub_title}
            </h1>
            <p className="font-inter  text-white font-16">
              {themeData?.about?.content?.short_description}
            </p>

            <div className="d-flex">
              <div>
                <button
                  onClick={() => navigate("/pricing")}
                  type="button"
                  className="aboutusbtn p-2 rounded-2 font-inter fw-500 font-12"
                >
                  See plans
                  <span className="ms-2 arrowline rounded-1 p-1">
                    <BsArrowRightShort />
                  </span>
                </button>
              </div>
              <div className="ms-2">
                <button
                  onClick={() => navigate("/register")}
                  type="button"
                  className="aboutusbtn2 p-2 rounded-2 font-inter fw-500 font-12"
                >
                  Join for free
                  <span className="ms-2 arrowline1 rounded-1 ps-0 p-1">
                    {" "}
                    <BsArrowRightShort />{" "}
                  </span>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            <Videos />
          </div>
        </div>
        <div className="row px-sm-0 px-4">
          {themeData?.benefits?.length > 0 &&
            themeData?.benefits.map((val, index) => {
              return (
                <div className="col-sm-6 col-md-3">
                  <div className="aboutuscard text-white mt-5 rounded-3 p-3 ps-4">
                    <div className="mt-3">
                      <i style={{fontSize:'30px'}} className={'content '+val?.content?.icon}/>
                    </div>
                    <div>
                      <h6 className="mt-4 font-inter fw-500 font-20  content">
                        {val?.content?.title}
                      </h6>
                      <p className="font-inter font-12 mt-3 pe-5  content">
                        {val?.content?.description}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })
          }          
        </div>
      </div>
      <div class="container-fluid">
        <div class="ourbackground mt-4 p-3">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center text-white">
                <h6 class="font-inter fw-400 font-25  content">
                  {themeData?.about?.content?.background_text}
                </h6>
                <p class="font-inter font-10  content">
                  {themeData?.about?.content?.description}
                </p>
              </div>
              <div class="col-12 col-md-4">
                <img src={'https://portal.tateclips.com/asset/frontend/light/images/about/' + themeData?.about?.content?.background_image_one} class="img-fluid" alt="" />
              </div>
              <div class="col-12 col-md-4">
                <img src={'https://portal.tateclips.com/asset/frontend/light/images/about/' + themeData?.about?.content?.background_image_two} class="img-fluid" alt="" />
              </div>
              <div class="col-12 col-md-4">
                <img src={'https://portal.tateclips.com/asset/frontend/light/images/about/' + themeData?.about?.content?.background_image_three} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="container pt-4">
        <div className="row justify-content-center">
          <div className="text-center ">
            <h6
              className="font-inter font-25 fw-400 blue"

            >
              {themeData?.testimonialHeadings?.content?.title}
            </h6>
            <p className="content fw-600 font-20">
              {themeData?.testimonialHeadings?.content?.sub_title}
            </p>
          </div>

          <div className="col-11 position-relative ">
            <img
              src={theme === "light" ? linlight : linedark}
              width={155}
              className="position-absolute img-Checklinr z-1"
              alt=""
            />

            <div className="bg-Testimonials position-relative z-3">
              <Testimoniol sliderData={themeData?.testimonial} />
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};

export default Aboutus;