import axios from "axios";
import DefaultImage from "../assetr/default.jpg";
import { generate, count } from "random-words";
import { baseUrl } from "./config";


const getQuerySystemVideos = async (query = null, page, limit) => {
  const data = await axios(`https://api.cloudflare.com/client/v4/accounts/3595a34d4e6f3615cc1fb5372256f0c7/stream?search=${query}`,
    {
      headers: {
        'Authorization': 'Bearer nZCO6cOAdoKDEspspV50fj5J8RfcM0V2nZUcmowe'
      }
    }
  );
  return data?.data?.result;
};

const getAllSystemVideos = async (query = null, page, limit) => {
  const data = await axios(`https://api.cloudflare.com/client/v4/accounts/3595a34d4e6f3615cc1fb5372256f0c7/stream`,
    {
      headers: {
        'Authorization': 'Bearer nZCO6cOAdoKDEspspV50fj5J8RfcM0V2nZUcmowe'
      }
    }
  );
  return data?.data?.result;
};
const getVideos = async (query, page, limit) => {
  // const pexelVideos = await getPexelVideos(query, page);
  // const pixabayVideos = await getPixabayVideos(query, page);
  var videos = [];
  if (query != null) {
    videos = await getQuerySystemVideos(query, page, limit);
  } else {
    videos = await getAllSystemVideos(null, page, limit);
  }
  // const concatVideos = [...queryVideos, ...allVideos];
  //   const shuffledVideos = concatVideos.sort(() => 0.5 - Math.random());
  return videos;

};
const getVideoDetail = async (id) => {
  const data = await axios("https://api.cloudflare.com/client/v4/accounts/3595a34d4e6f3615cc1fb5372256f0c7/stream/" + id,
    {
      headers: {
        'Authorization': 'Bearer nZCO6cOAdoKDEspspV50fj5J8RfcM0V2nZUcmowe'
      }
    }
  );
  return data?.data?.result;
};

const getDownloadUrl = async (id) => {
  const data = await axios("https://api.cloudflare.com/client/v4/accounts/3595a34d4e6f3615cc1fb5372256f0c7/stream/" + id + "/downloads",
    {
      headers: {
        'Authorization': 'Bearer nZCO6cOAdoKDEspspV50fj5J8RfcM0V2nZUcmowe'
      }
    }
  );
  return data?.data?.result;
};


const generateDownloadUrl = async (data) => {
  let access_token = localStorage.getItem('video-paltform-token');
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl+'download',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer '+access_token
    },
    data : data
  };
  
 let response =  axios.request(config)
  .then((response) => {
    return response?.data;
  })
  .catch((error) => {
  });
  return response;
};


const getAllCollections = async () => {
  // const data = await axios("https://video.bunnycdn.com/library/194172/collections?page=1&itemsPerPage=100&orderBy=date",
  //   {
  //     headers: {
  //       'AccessKey': 'cbcb399f-330e-4e46-88210a043459-5091-403a'
  //     }
  //   }
  // );
  return [];
};

const loginUser = async (data, handleResponse) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'auth/login',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };
  axios.request(config)
    .then((response) => {
      handleResponse(response.data)
    })
    .catch((error) => {
      handleResponse(error?.response?.data)
      return error;
    });
}

const registerUser = async (data, handleResponse) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'auth/register',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };
  axios.request(config)
    .then((response) => {
      
      handleResponse(response.data)
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
}

const loginWithGoogle = async (data, handleResponse) => {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'auth/google',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };
  axios.request(config)
    .then((response) => {
      
      handleResponse(response.data)
    })
    .catch((error) => {
      handleResponse(error?.response?.data);
    });
}


const getUserProfille = async (handleResponse) => {
  let access_token = localStorage.getItem('video-paltform-token');
  if (access_token) {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: baseUrl + 'auth/profile',
      headers: {
        'Authorization': 'Bearer ' + access_token,
      }
    };

    axios.request(config)
      .then((response) => {
        handleResponse(response.data)
        // 
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          localStorage.removeItem("video-paltform-token");
        }
      });
  }
}

const getAllPlans = async () => {
  const data = await axios(baseUrl + "plans");
  return data?.data;
};
const getAllPaymentGateways = async () => {
  const data = await axios(baseUrl + "gateway");
  return data?.data;
};
const generateStripeCheckout = (data, handleResponse) => {
  let access_token = localStorage.getItem('video-paltform-token');

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'paynow/2',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + access_token,
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      handleResponse(response.data);
      
    })
    .catch((error) => {

    });

}
const activateFreePlan = (data, handleResponse) => {
  let access_token = localStorage.getItem('video-paltform-token');
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'plan/free',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + access_token,
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      handleResponse(response.data);
      
    })
    .catch((error) => {
    });

}
const updateProfilePicture = (data, handleResponse) => {
  let access_token = localStorage.getItem('video-paltform-token');
  let formData = new FormData();
  formData.append('image', data.image);
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'auth/profile/image',
    headers: { 
      'Authorization': 'Bearer '+access_token,
    },
    data : formData
  };
  
  axios.request(config)
  .then((response) => {
    
  })
  .catch((error) => {

  });
  

}
const updateProfile = (data, handleResponse) => {
  let access_token = localStorage.getItem('video-paltform-token');
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: baseUrl + 'auth/profile',
    headers: { 
      'Authorization': 'Bearer '+access_token,
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    
  })
  .catch((error) => {
  });
  

}
const getAllNotifications = async () => {
  let access_token = localStorage.getItem('video-paltform-token');
  let data = [];
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: baseUrl+'profile/notification',
  headers: { 
    'Authorization': 'Bearer '+access_token
  }
};

data = axios.request(config)
.then((response) => {
  return response?.data?.data;
})
.catch((error) => {
});

  return data;
};


const getAllDownloads = async () => {
  let access_token = localStorage.getItem('video-paltform-token');
  let data = [];
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: baseUrl+'download',
  headers: { 
    'Authorization': 'Bearer '+access_token
  }
};

data = axios.request(config)
.then((response) => {
  return response?.data?.data;
})
.catch((error) => {
});

  return data;
};
export {loginWithGoogle,getAllDownloads,generateDownloadUrl,activateFreePlan,updateProfilePicture,getAllNotifications,updateProfile, getDownloadUrl, getVideos, getVideoDetail, loginUser, registerUser, getUserProfille, getAllCollections, getAllPlans, getAllPaymentGateways, generateStripeCheckout };
