/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import reporticon from "../assetr/reporticon.png"
import { BiCommentAdd, BiPencil } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { addVideoToPlaylist, createFolder, createSupportTicket, getFolders, getSupportCases } from '../redux/slices/videoSlice';
import { Spinner } from 'reactstrap';


function PlaylistModal({ show, handleClose, videoId }) {
    const dispatch = useDispatch();
    const { folders } =
        useSelector((state) => state.video);
    const { theme } = useSelector((state) => state.user);

    const [folderTitle, setFolderTitle] = useState('');
    const [privacy, setPrivacy] = useState('');
    const [isCreateShow, setCreateShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCreateFolder = () => {
        setCreateShow(!isCreateShow)
    };

    const handleSubmitFolder = () => {
        setLoading(true);
        let data = {
            title: folderTitle,
            privacy: privacy
        }
        dispatch(createFolder(data, handleFolderResponse))
    }
    const handleFolderResponse = (data) => {
        setCreateShow(!isCreateShow)
        setLoading(false);
    }
    useEffect(() => {
        dispatch(getFolders())
    }, []);

    const handleAddToPlaylist = (e) => {
        if (e.target.checked) {
            let folderId = e.target.value
            let data = {
                videoId: videoId,
                folderId: folderId
            }
            dispatch(addVideoToPlaylist(data, handlePlaylistResponse))
        }
    }
    const handlePlaylistResponse = (data) => {

    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save video to...</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
                    <div className='row'>
                        {folders?.length > 0 &&
                            folders.map((val, index) => {
                                return (
                                    <div className='col-md-8'>
                                        <div class="form-check">
                                            <input class="form-check-input" onChange={(e) => handleAddToPlaylist(e)} type="checkbox" value={val?.id} id={"flexCheckDefault" + index} />
                                            <label class="form-check-label reporttext" for={"flexCheckDefault" + index}>
                                                {val?.title}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className='col-md-8 reporttext' style={{ cursor: 'pointer' }} onClick={handleCreateFolder}>
                            <BiCommentAdd style={{ marginRight: '10px' }} />
                            Create New Folder
                        </div>
                    </div>
                    {isCreateShow &&
                        <div className='row mt-3'>
                            <div className='col-md-12 form-group'>
                                <label for="title">Name</label>
                                <input value={folderTitle} onChange={(e) => setFolderTitle(e.target.value)} className='form-control' name='title' id="title" />
                            </div>
                            <div className='col-md-12 form-group'>
                                <label for="title">Privacy</label>
                                <select value={privacy} onChange={(e) => setPrivacy(e.target.value)} className='form-control' name='privacy'>
                                    <option value={'public'}>Public</option>
                                    <option value={'private'}>Private</option>
                                </select>
                            </div>
                            <div className='col-md-12 form-group mt-3'>
                                <a href='javascript:void(0)' className='btn btn-primary' onClick={handleSubmitFolder}>
                                    {loading ? <Spinner size="sm" color="light" /> : "Create"}
                                </a>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
                    <button className='btn-login p-4 px-2 py-2 rounded-3' onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PlaylistModal;