/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause, FaThumbsUp, FaThumbsDown, FaComment, FaShare, FaHeart } from 'react-icons/fa';
import mp4 from '../assetr/Video/shor.mp4';
import mp5 from '../assetr/Video/💧Exploring Big Dam Holes🕳️.mp4';
import userimg from '../assetr/logo.png';
import Navbar from "../component/Navbar";
import { useDispatch, useSelector } from 'react-redux';
import { addToFavourite, addToLike, getShortVideos, getVideoDetail } from '../redux/slices/videoSlice';
import { useParams } from 'react-router-dom';
import ShortVideoCard from '../component/ShortVideoCard';
import { ToastContainer, toast } from 'react-toastify';
import { checkItemExistence, checkUserScope } from '../utils/helpers';


const ShortVideos = () => {
  const { userDetail } = useSelector((state) => state.user);
  const { id, type } = useParams();
  const [loading, setLoading] = useState(false);
  const dispacth = useDispatch();
  const { shortVideos, videoDetail } =
    useSelector((state) => state.video);
  const [playingIndex, setPlayingIndex] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowIcon(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [showIcon]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispacth(getShortVideos())
  }, [dispacth]);
  useEffect(() => {
    if (id !== 'all') {
      dispacth(getVideoDetail(id, "long"));
      setPlayingIndex(videoDetail?.id);
      setDetailShow(true);
      setLoading(true);
    }
  }, [id])

  const togglePlaying = (index) => {
    console.log("Toggling playing state for video at index:", index);
    setPlayingIndex(index === playingIndex ? null : index);
    setShowIcon(true);

  };
  const [isDetailShow, setDetailShow] = useState(false);
  const handleLikeResponse = (data) => {

  };
  const [isLiked, setLiked] = useState(false);
  const addVideoToLikes = async (videoId, title) => {
    if (userDetail?.id > 0) {
      setLiked(!isLiked);
      let data = {
        type: "long",
        video_id: videoId,
        title: title,
      };
      dispacth(addToLike(data, handleLikeResponse,false));
    } else {
      alert("Please login and then try to like the video");
    }

  };


  const [isFavourite, setFavourite] = useState(false)
  useEffect(() => {
    setFavourite(checkItemExistence(videoDetail?.favourite, userDetail?.id));
  }, [videoDetail,userDetail]);
  const handleAddToFavourite = (cloud_flare_video_id, title) => {
    setFavourite(!isFavourite);
    // if (checkUserScope("addtofav", userDetail?.currentplan)) {
      let data = {
        video_id: cloud_flare_video_id,
        title: title,
        type: "long",
      };
      dispacth(addToFavourite(data, handleFavResponse));
    // } else {
    //   alert("You don't have permission to Add this video to Favourite");
    // }
  }
  const handleFavResponse = (data) => {
    toast.success(data?.message, {
      position: "top-right",
      autoClose: 2000,
    });
  };
  const handleShare = async () => {
      try {
        if (navigator.share) {
          await navigator.share({
            title: videoDetail?.title,
            text: "Checkout this short on Tateclips",
            url: "http://localhost:3000/video/short/"+videoDetail?.cloud_flare_video_id+"/short",
          });
        } else {
          throw new Error("Web Share API not supported");
        }
      } catch (error) {
        console.error("Error sharing:", error.message);
        // Fallback to other methods if Web Share API is not supported or sharing fails
        // For example, you can use a library like react-share: https://www.npmjs.com/package/react-share
      }
    };
  return (
    <div>
      <Navbar />
      <ToastContainer />
      {loading &&
        <div class="loading">Loading&#8230;</div>
      }
      <div className="short-videos-container">
        {isDetailShow &&
          <div key={videoDetail?.id} className="short-video">
            <div className="video-wrapper" onClick={() => togglePlaying(videoDetail?.id)}>
              <ReactPlayer
                className="react-player"
                light={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${videoDetail?.cloud_flare_video_id}/thumbnails/thumbnail.jpg`}
                url={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${videoDetail?.cloud_flare_video_id}/manifest/video.m3u8`}
                playing={videoDetail?.id === playingIndex}
                controls={false}
                width="100%"
                height="100%"
              />
              {showIcon && (
                <div className="play-pause-icon">
                  {videoDetail?.id === playingIndex ? (
                    <FaPause />
                  ) : (
                    <FaPlay />
                  )}
                </div>
              )}
              <div className="video-info">
                <h2 className="uploader"><span><img src={userimg} width={30} alt="" /></span> Admin</h2>
                <h4 className="title mt-2">{videoDetail?.title}</h4>
              </div>
            </div>
            <div className="video-actions">
                <a
                    title={
                        userDetail?.id !== undefined
                            ? "Like Video"
                            : "Please login or register to Like this clip"
                    }
                    href="javascript:void(0)"
                    onClick={() => {
                        userDetail?.id !== undefined
                            ? addVideoToLikes(videoDetail?.cloud_flare_video_id, videoDetail?.title)
                            : alert(
                                "Please login or register to Like this clip"
                            );
                    }
                    }
                    className={`${isLiked ? "short-liked" : ""}`}
                >
                    <FaThumbsUp className={`short-icon ${isLiked ? "short-liked" : ""}`}
                        style={{}}
                    />
                </a>
                <a
                    title={
                        userDetail?.id !== undefined
                            ? "Add to Favourite"
                            : "Please login or register to add this clip to Favourite"
                    }
                    href="javascript:void(0)"
                    onClick={() => {
                        userDetail?.id !== undefined
                            ? handleAddToFavourite(videoDetail?.cloud_flare_video_id, videoDetail?.title)
                            : alert(
                                "Please login or register to add this clip to Favourite"
                            );
                    }
                    }
                    className={`${isFavourite ? "short-liked" : ""}`}
                >
                    <FaHeart className="short-icon mt-2"/>
                </a>
                <a
                    title='Share Video'
                    onClick={handleShare}
                >
                <FaShare className="short-icon mt-2" />
                </a>
            </div>
          </div>
        }
        {shortVideos.map((video, index) => (
          <ShortVideoCard video={video} index={index}/>
        ))}
      </div>
    </div>
  );
};

export default ShortVideos;
