import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import lightMembershipBg from "../assetr/plan-bg.png.png";
import darkMembershipBg from "../assetr/membershipbg.png";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { activateFreePlan, getAllPlans } from "../utils/ApiCalls";
import { useNavigate } from "react-router-dom";
import { generateRandomToken } from "../utils/helpers";
import { useSelector } from "react-redux";

export const Upgrademembership = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const { userDetail, theme } = useSelector((state) => state.user);

  useEffect(() => {
    getPaymentPlans();
  }, []);

  const getPaymentPlans = async () => {
    setPlans(await getAllPlans());
  };

  const firstToken = generateRandomToken(60);
  const secondToken = generateRandomToken(60);

  const handleSelectFreePlan = (planId) => {
    let data = { plan_id: planId };
    activateFreePlan(data, handleFreePlanResponse);
  };

  const handleFreePlanResponse = (data) => {
    let firstToken = generateRandomToken(60);
    let secondToken = generateRandomToken(60);
    navigate('/payment/success?_token=' + firstToken + "&auth=true&aid=" + secondToken);
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">
              <ProfileSidBar isSubscription={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
              <h6 className="font-inter fw-700 font-20">Upgrade Membership Plan</h6>
            </div>
            <div className="row justify-content-center mt-3">
              {plans?.data?.length > 0 &&
                plans.data.map((val, index) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4 mt-2" key={index}>
                      <div className="membershipcard rounded-3 p-4 text-center position-relative">
                        <div className="membershinlein">
                          <h6 className="text-center mb-2 content font-inter fw-700 font-14">
                            {val?.name}
                          </h6>
                        </div>
                        <div className="mt-2">
                          <h6 className="font-40 fw-700 font-inter bluecolr">
                            {val?.price_type === "paid"
                              ? parseFloat(val?.price)?.toFixed(2)
                              : "Free"}
                          </h6>
                        </div>
                        <button
                          onClick={() =>
                            val?.price_type === "paid"
                              ? navigate(
                                  `/pricing/plan/${firstToken}/${secondToken}?id=${val?.id}&name=${val?.name}&type=${val?.level < userDetail?.currentplan?.level ? 'downgrade' : 'upgrade'}`
                                )
                              : handleSelectFreePlan(val?.id)
                          }
                          type="button"
                          className="membershipbtn rounded-5 mb-0"
                          disabled={userDetail?.currentplan?.plan_id === val?.id}
                        >
                          {userDetail?.currentplan?.plan_id === val?.id
                            ? "Already Activated"
                            : "Buy Now!"}
                        </button>
                        <img
                          src={theme === "light" ? lightMembershipBg : darkMembershipBg}
                          className="position-absolute membersjipbgimg"
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
