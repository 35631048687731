import React, { useState } from 'react';
import controller from "../assetr/manimg.png";
import twoController from "../assetr/maniimone.png";
import threeController from "../assetr/maniimone.png";
import forController from "../assetr/mainimage1.png";
import fivController from "../assetr/mainimg4.png";
import sixController from "../assetr/mainimg5.png";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const ImageSlider = () => {
  const data = [
    {
      image: controller,
      // caption: "Image 1",
    },
    {
      image: twoController,
      // caption: "Image 2",
    },
    {
      image: threeController,
      // caption: "Image 3",
    },
    {
      image: forController,
      // caption: "Image 3",
    },
    {
      image: fivController,
      // caption: "Image 3",
    },
    {
      image: sixController,
      // caption: "Image 3",
    },
 
  ];

  const [selectedSlide, setSelectedSlide] = useState(0);

  const handleThumbClick = (index) => {
    setSelectedSlide(index);
  };
  return (
    <div className="imgSlider p-2 vertical-carousel-container">
     
      <div className='d-flex'>
      <div className="custom-thumbs-container">
          {/* Render custom thumbnails on the left side */}
          <div className="custom-thumbs " >
            {data.map((item, index) => (
              <div key={index} className="thumb p-1" onClick={() => handleThumbClick(index)}>
                <img src={item.image} alt={item.caption} />
              </div>
            ))}
          </div>
        </div>
        <div className='sliderimagess p-3 ps-0 text-center rounded-3 ms-3'>
      <Carousel
        showThumbs={false}
        selectedItem={selectedSlide}
        infiniteLoop={true}
        autoPlay={false}
        dots={false}
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        className="vertical-carousel" // Add a custom class for vertical carousel
      >
       
        {data.map((item, index) => (
          
          <div key={index} className='controller-bg vertical-slide position-relative'>
                
            <div className="cardcontent3">
              <div>
                <p className="ps-2">Avengers Infinity War</p>
              </div>
              <div className="d-flex mb-3 ms-3">
                <div className="d-flex align-items-center">
                  <p className="mb-0 ">
                    {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.0619 20C15.5715 20 20.0378 15.5228 20.0378 10C20.0378 4.47715 15.5715 0 10.0619 0C4.55232 0 0.0859375 4.47715 0.0859375 10C0.0859375 15.5228 4.55232 20 10.0619 20Z" fill="white"/>
  <path d="M14.0139 9.29298L8.61029 6.16797C8.36088 6.0013 8.02836 6.0013 7.77895 6.16797C7.52954 6.33466 7.36328 6.58464 7.36328 6.8763V13.0846C7.36328 13.3763 7.52954 13.668 7.77895 13.793C7.90365 13.8763 8.06991 13.9179 8.19462 13.9179C8.31932 13.9179 8.48558 13.8763 8.61029 13.793L13.9724 10.668C14.2218 10.5013 14.388 10.2513 14.388 9.95963C14.388 9.66796 14.2633 9.41798 14.0139 9.29298Z" fill="#0D1824"/>
</svg>
                  </p>
                  <span className="ms-2">3m</span>
                </div>
              </div>
            </div>
            <img src={item.image}  alt={item.caption} />
            <div className=' playicon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="44" viewBox="0 0 46 44" fill="none">
  <path d="M43.3678 8.49174C41.0109 6.6709 32.6989 5.24219 23 5.24219C13.3011 5.24219 4.98911 6.6709 2.63215 8.49174C0.46611 10.1621 0 17.7139 0 22C0 26.2861 0.46611 33.8379 2.63215 35.5083C4.98911 37.3291 13.3011 38.7578 23 38.7578C32.6989 38.7578 41.0109 37.3291 43.3678 35.5083C45.534 33.8379 46 26.2861 46 22C46 17.7139 45.534 10.1621 43.3678 8.49174ZM29.9012 22.2068L19.2238 28.303C19.1452 28.346 19.0497 28.3486 18.971 28.3057C18.8924 28.2627 18.8433 28.1821 18.8433 28.0961V22V15.9038C18.8433 15.8165 18.8924 15.7372 18.971 15.6942C19.0497 15.6513 19.1452 15.6539 19.2238 15.6969L29.9012 21.7931C29.977 21.8361 30.0233 21.914 30.0233 21.9999C30.0233 22.0833 29.9769 22.1638 29.9012 22.2068Z" fill="#2C3E50"/>
</svg>
            </div>
          </div>
        ))}
      </Carousel>

        </div>
      </div>

    

    </div>
  );
};

export default ImageSlider;