import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaFolder } from "react-icons/fa";
import ProfileSidBar from './ProfileSidBar';
import Navbar from "../component/Navbar";
import Fotter from './Fotter';
import ReactDropzone from "./ReactDropzone";
import { useDispatch, useSelector } from "react-redux";
import { getFolders } from "../redux/slices/videoSlice";
const Myvideo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { folders } =
        useSelector((state) => state.video);
    const handleDoubleClick = (path, type, data) => {
        navigate(path, { state: { type, data } });
    };
    useEffect(() => {
        dispatch(getFolders())
    }, []);
    const [isShowUpload, setShowUpload] = useState(false);
    const handleVideoUpload = () => {
        setShowUpload(!isShowUpload);
    }

    return (
        <div>
            <Navbar />
            <div className="container-fluid profilepage"></div>
            <div className="container">
                {/* <h6 className="profilecontent">Profile</h6> */}
                <div className="row">
                    <div className="col-md-3 mt-3">
                        <div className="profileinfo p-2 rounded-3 p-3">
                            <ProfileSidBar isProfile={true} />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
                            <h6 className="font-inter fw-700 font-20 content"> My Videos </h6>
                        </div>
                        <div className="profileinfo p-4 rounded-1 mt-3">
                            <div className="row justify-content-center">
                            {!isShowUpload && 
                                <div className="col-md-12" style={{ textAlign: 'right' }}>
                                    <button
                                        type="button"
                                        className="btn-login px-2 py-2 rounded-3  mt-2"
                                        onClick={handleVideoUpload}
                                    >
                                        Upload Video
                                    </button>
                                </div>
                            }
                                {isShowUpload &&
                                    <>
                                        <div className="col-md-12 dropzone-custom mt-3">
                                            <ReactDropzone handleResponseUpload={handleVideoUpload}/>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="profileinfo p-4 rounded-1 mt-3">
                            <div className="row">
                                {folders?.length > 0 &&
                                    folders.map((val, index) => {
                                        return (
                                            <div
                                                className="col-4 "
                                                onDoubleClick={() => handleDoubleClick("/myvideos/content", val?.title, val?.playlist)}
                                            >
                                                <FaFolder size={50} className="iconcolor" />
                                                <h6 className='mt-2 font-10'>{val?.title}</h6>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fotter />
        </div>
    );
};

export default Myvideo;
