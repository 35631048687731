import React, { useState, useEffect, useRef } from "react";
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { useDispatch, useSelector } from "react-redux";
import { getffiliateReferrals } from "../redux/slices/userSlice";
export const Affiliate = () => {
    const dispatch = useDispatch();
    const { userDetail, affiliateCustomers } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getffiliateReferrals())
    }, [dispatch]);
    const inputRef = useRef(null);
    const [message, setMessage] = useState(null)
    // Function to copy the URL from the input field
    const copyURL = () => {
        // Select the input field
        inputRef.current.select();
        // Execute copy command
        document.execCommand('copy');
        // Deselect the input field
        window.getSelection().removeAllRanges();
        setMessage("URL Copied!")
    };
    // Get the hostname from window.location.hostname
    const hostname = window.location.hostname;

    // Determine the protocol based on whether we're on localhost or a live host
    const protocol = hostname === 'localhost' ? 'http://' : 'https://';

    // Define the port based on whether we're on localhost
    const port = hostname === 'localhost' ? ':3000' : '';

    // Construct the host URL
    const hostURL = `${protocol}${hostname}${port}/`;

    return (
        <div>
            <Navbar />
            <div className="container-fluid profilepage"></div>
            <div className="container">
                {/* <h6 className="profilecontent">Affiliate Dashboard</h6> */}
                <div className="row justify-content-center">
                    <div className="col-md-3 mt-3">
                        <div className="profileinfo p-2 rounded-3 p-3">
                            <ProfileSidBar isAffiliate={true} />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="profileinfo p-2 rounded-1 ps-4  mt-3">
                            <h6 className="font-inter fw-700 font-20">Welcome to Affiliate Dashboard<br />
                            </h6>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="profileinfo p-4 rounded-1 mt-2">
                                    <h6 className="font-inter fw-700 font-12">
                                        Find your program summary below.
                                    </h6>
                                    <div className="row">
                                        <div className="col-lg-8 col-12 mt-2">
                                            <label
                                                htmlFor=""
                                                className="font-16 font-500 poppins text-white mb-2"
                                            >
                                                {" "}
                                                Share your link to refer people and start your earning
                                            </label>
                                            <div className="input-group" style={{ justifyContent: 'space-around', alignItems: 'baseline' }}>
                                                <input
                                                    ref={inputRef}
                                                    type="text"
                                                    placeholder="Your Your Link"
                                                    className="custom-input"
                                                    value={hostURL + "register?aff=" + userDetail?.referral_code}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={copyURL}
                                                    className="btn-login px-2 py-2 rounded-3  mt-2"
                                                >
                                                    Copy URL
                                                </button>
                                            </div>
                                            {message &&
                                                <span style={{ marginLeft: "15px" }}>
                                                    {message}
                                                </span>

                                            }
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4 col-6">
                                                    <div className="profileinfo p-2 rounded-1 mt-3" style={{ border: '1px solid' }}>
                                                        <h6 className="font-inter fw-700 font-20 text-center">
                                                            {affiliateCustomers?.referrals?.length}
                                                        </h6>
                                                        <h6 className="font-inter fw-700 font-20 text-center">Signups
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-6">
                                                    <div className="profileinfo p-2 rounded-1 mt-3" style={{ border: '1px solid' }}>
                                                        <h6 className="font-inter fw-700 font-20 text-center">
                                                            {affiliateCustomers?.paid}
                                                        </h6>
                                                        <h6 className="font-inter fw-700 font-20 text-center">Paid Customers
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-6">
                                                    <div className="profileinfo p-2 rounded-1 mt-3" style={{ border: '1px solid' }}>
                                                        <h6 className="font-inter fw-700 font-20 text-center">
                                                            {userDetail?.commissions_total_amount?.[0]?.total_amount > 0 ? parseFloat(userDetail?.commissions_total_amount?.[0]?.total_amount) : 0} USD
                                                        </h6>
                                                        <h6 className="font-inter fw-700 font-20 text-center">Revenue
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 overflowXScroll">
                                            <h3>Referrals</h3>
                                            <table class="custom-table mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Rewards</th>
                                                        <th>Joining Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {affiliateCustomers?.referrals?.length > 0 ?
                                                        affiliateCustomers?.referrals.map((val, index) => {
                                                            return (
                                                                <tr className="membershinlein">
                                                                    <td>
                                                                        <img src={val?.image} style={{ width: '10%' }} alt="" />
                                                                        <span className="font-inter" style={{ marginLeft: '10px' }}>
                                                                            {val?.name}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {val?.email}
                                                                    </td>
                                                                    <td style={{ width: "15%" }}>
                                                                        {val?.commission_to_partner?.[0]?.total_amount > 0 ? parseFloat(val?.commission_to_partner?.[0]?.total_amount) : 0} USD
                                                                    </td>
                                                                    <td style={{ width: "15%" }}>
                                                                        {val?.created_at.split("T")[0]}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :

                                                        <tr className="membershinlein">
                                                            <td colSpan={3}>
                                                                <h2>No Referrals Found</h2>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fotter />
        </div>
    );
};
