import React, { useEffect, useState } from 'react'
import Navbar from '../component/Navbar'
import Tabs from '../component/Tabs'
import Travel from '../component/Travel'
import Fotter from '../component/Fotter'
import { Link, useNavigate } from 'react-router-dom'
import { shuffleArray } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { addRecentSearches, addToFavourite, getAllCategories, getAllLongVideos, getLongVideos } from '../redux/slices/videoSlice'
import { FaSearch } from 'react-icons/fa'
import NewVideoCard from '../component/NewVideoCard'
const Videopage = () => {
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [collectionsData, setCollections] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionTitle, setSelectedCollectionTitle] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  const [videoDetail, setVideoDetail] = React.useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const { videos,allLongVideos, longVideos, categories,} =
    useSelector((state) => state.video);
  const { userDetail } = useSelector((state) => state.user);

  const queryParams = new URLSearchParams(window.location.search);
  const searchval = queryParams.get('search');
  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const paramName = queryParams.get('search');
    const category = queryParams.get('category');
    if(paramName){
      setSearch(paramName)
      dispacth(getLongVideos(page, limit, paramName))
    }else{
      if (category) {
        setCategoryId(category);
      }
      dispacth(getLongVideos(page, limit))
    }
    dispacth(getAllCategories());    
    dispacth(getAllLongVideos())
  }, [dispacth]);
  useEffect(() => {
    setCollections(categories);
  }, [categories]);

  const handleSelectCollection = (categoryId,categoryTitle) => {
    setSelectedCollection(categoryId)
    setSelectedCollectionTitle(categoryTitle)
    if (parseInt(categoryId) === 1) {
      // Get the current date
      const currentDate = new Date();

      // Get the date 30 days ago from the current date
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // Filter videoClips based on categoryId and created_at within the last 30 days
      const filteredVideoClips = allLongVideos.filter(video => {
        // Convert the created_at timestamp to a Date object
        const createdAtDate = new Date(video.created_at);
        // Check if the createdAtDate is within the last 30 days
        return createdAtDate >= thirtyDaysAgo && createdAtDate <= currentDate;
      });

      // Shuffle the filteredVideoClips array
      const shuffledClips = shuffleArray(filteredVideoClips);
      setFilteredData(shuffledClips)
    } else {
      const filteredLongVideos = allLongVideos.filter(video => video?.category_id === categoryId);
      // Shuffle the filteredVideoClips array
      const shuffledClips = shuffleArray(filteredLongVideos);

      // Get random  clips from the shuffled array
      // const randomClips = shuffledClips.slice(0, 00);
      setFilteredData(shuffledClips)
    }


  }

  const handlLoadMore = async () => {
    if(selectedCollection){
      setLimit(parseInt(limit) + 24);
    }else{
      setLoading(true);
      setLimit(parseInt(limit) + 24);
      dispacth(getLongVideos(1, parseInt(limit) + 24))

    }
  }
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(28);

  useEffect(() => {
    if (longVideos?.length > 0) {
      setFilteredData(longVideos);
      setLoading(false);
    }
  }, [longVideos]);
  // const [search,setSearch] = useState(null);
  const handleSearchVideos = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      search: search,
    };
    dispacth(addRecentSearches(data));
    // dispacth(getVideoClips(1,1000,search))
    dispacth(getLongVideos(page, limit,search))
    navigate("/videos?search=" + encodeURIComponent(search));
    window.location.reload();
  };
  return (
    <div>
      <Navbar />
      {loading &&
                <div class="loading">Loading&#8230;</div>
            }
      <Tabs collectionsData={collectionsData} handleSelectCollection={handleSelectCollection} selectedCollection={selectedCollection} />
      <div>
        <div className="container">
          <div className="row justify-content-center" >
            <div className="col-lg-4 col-md-2 mt-3">
              <form method="get" onSubmit={handleSearchVideos}>
                <div className="d-flex">
                  <div className="search-container">
                    <input type="text" className="custom-input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  </div>
                  <button type="submit" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                    <FaSearch />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid p-lg-5">
          <div className="row">
          <div className='col-md-12'>
            <h1>{selectedCollectionTitle}</h1>
          </div>
            {filteredData?.length > 0 ?
              filteredData.map((clip, index) => {
                if(index<limit)
                  return (
                    <div className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card" key={index}>
                      <NewVideoCard
                        video={clip}
                        videoType="long"
                      />
                    </div>
                  )
              }) :
              <div className='col-md-12 text-center mt-5'>
                <h3 className='text-white'>{loading ?"Please wait...":"No Clip Found!"}</h3>
              </div>
            }
          </div>
          {filteredData?.length > 24 && <div className="row mt-5">
            <div className='col-md-12 col-12 text-center'>
              <button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
                LOAD MORE
              </button>
            </div>
          </div>}
        </div>
      </div>
      <Fotter />
    </div>
  )
}

export default Videopage