import React, { useEffect, useState } from 'react'
import Navbar from '../component/Navbar'
import { Favorite } from '../component/Favorite'
import Fotter from "../component/Fotter";
import { useDispatch, useSelector } from 'react-redux';
import { addToFavourite, getFavouriteList } from '../redux/slices/videoSlice';
import { checkUserScope } from '../utils/helpers';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Favritevideo = () => {
  const dispacth = useDispatch();
  const { favourites } = useSelector((state) => state.video);
  const { userDetail } = useSelector((state) => state.user);
  const addVideoToFavourite = async (id, title, type) => {
    // if (checkUserScope("addtofav", userDetail?.currentplan)) {
      let data = {
        video_id: id,
        title: title,
        type: type,
      };
      dispacth(addToFavourite(data, handleFavResponse));
    // } else {
    //   alert("You don't have permission to Add this video to Favourite");
    // }
  };
  const handleFavResponse = (data) => {
    toast.success(data?.message, {
      position: "top-right",
      autoClose: 2000,
    });
  };
  const [message, setMessage] = useState(null);
  const handleResponse = () =>{
    setMessage(null);
  }
  useEffect(() => {
    setMessage("Please wait...")
    dispacth(getFavouriteList(handleResponse))
  }, []);

  return (
    <div>
    <ToastContainer />
      <Navbar />
      <div className="container">
        <p class="font-inter font-18 fw-600 mt-5">
          {" "}
          <span class="linenew">
            Favourite Videos
          </span>{" "}
        </p>
        {!message ?
          <div className="row">
        {favourites?.length>0?
        favourites.map((val,index)=>{
          return(
            <Favorite addVideoToFavourite={addVideoToFavourite} userDetail={userDetail} title={val?.title} uid={val?.video_id}/>
          )
        }):
        <h1>No Videos in Favourite List</h1>
        }
        </div>
        :
        <h1>{message}</h1>
        }
        
      </div>
      <Fotter />
    </div>
  )
}
