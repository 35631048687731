import React, { useEffect, useState } from 'react'
import Navbar from '../component/Navbar'
import Tabs from '../component/Tabs'
import Fotter from '../component/Fotter'
import {  useNavigate } from 'react-router-dom'
import VideoCard from '../component/VideoCard'
import { shuffleArray } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { addRecentSearches, addToFavourite, getAllCategories, getLongVideos, getVideoClips } from '../redux/slices/videoSlice'
import Modal from "react-bootstrap/Modal";
import { FaSearch } from 'react-icons/fa'
import NewVideoCard from '../component/NewVideoCard'
const SearchResultPage = () => {
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [collectionsData, setCollections] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const { categories, videoClips } =
    useSelector((state) => state.video);
  const { userDetail } = useSelector((state) => state.user);
  useEffect(() => {
    setLoading(true);
    setFilteredData([])
    const queryParams = new URLSearchParams(window.location.search);
    const paramName = queryParams.get('search');
    const category = queryParams.get('category');
    if(paramName){
      if(paramName != ''){
        setSearch(paramName)
        dispacth(getVideoClips(1, 1000, encodeURIComponent(paramName)))
      }
      
    }
    dispacth(getAllCategories());    
  }, [dispacth]);
  useEffect(() => {
    setCollections(categories);
  }, [categories]);

  const handleSelectCollection = (categoryId) => {
    console.log(categoryId);
    setFilteredData([])
    setSelectedCollection(categoryId)
    if (parseInt(categoryId) === 1) {
      // Get the current date
      const currentDate = new Date();

      // Get the date 30 days ago from the current date
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // Filter videoClips based on categoryId and created_at within the last 30 days
      const filteredVideoClips = videoClips.filter(video => {
        // Convert the created_at timestamp to a Date object
        const createdAtDate = new Date(video.created_at);
        // Check if the createdAtDate is within the last 30 days
        return createdAtDate >= thirtyDaysAgo && createdAtDate <= currentDate;
      });

      // Shuffle the filteredVideoClips array
      const shuffledClips = shuffleArray(filteredVideoClips);
      setFilteredData(shuffledClips)
    } else {
      const filteredLongVideos = filteredData.filter(video => video?.category_id === categoryId);
      const shuffledClips = shuffleArray(filteredLongVideos);
      setFilteredData(shuffledClips)
    }


  }

  const handlLoadMore = async () => {
    setLimit(parseInt(limit) + 24)
  }
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(28);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramName = queryParams.get('search');
    if (paramName) {
      if (videoClips?.length > 0) {
        setFilteredData(videoClips)
        setLoading(false);
      }else{
        setFilteredData([]);
        setLoading(false);
      }
    } 
    
  }, [videoClips]);

  const addVideoToFavourite = async (id, title) => {
    // if (checkUserScope('addtofav', userDetail?.currentplan)) {
      let data = {
        video_id: id,
        title: title,
      }
      dispacth(addToFavourite(data, handleFavResponse))
    // } else {
    //   alert("You don't have permission to Add this video to Favourite");
    // }
  }

  const handleShare = async (id, title) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: title,
          text: 'Checkout this video on Tateclips',
          url: 'https://tateclips.com/video/detail/' + id,
        });

      } else {
        throw new Error('Web Share API not supported');
      }
    } catch (error) {
      // console.error('Error sharing:', error.message);
      // Fallback to other methods if Web Share API is not supported or sharing fails
      // For example, you can use a library like react-share: https://www.npmjs.com/package/react-share
    }
  };
  const [message, setMessage] = useState(null);
  const handleFavResponse = (data) => {
    setMessage(data?.message)
  }
  let modalTimeout1;

  const { theme } = useSelector((state) => state.user);
  // const [search,setSearch] = useState(null);
  const handleSearchVideos = async (e) => {
    e.preventDefault();
    setFilteredData([])
    setLoading(true);
    let data = {
      search: search,
    };
    dispacth(addRecentSearches(data));
    // dispacth(getVideoClips(1,1000,search))
    dispacth(getVideoClips(1, 1000,search))
    navigate("/video/result?search=" + search);
    // window.location.reload();
  };
  return (
    <div>
      <Navbar />
      {loading &&
                <div class="loading">Loading&#8230;</div>
            }
      <Tabs collectionsData={collectionsData} handleSelectCollection={handleSelectCollection} selectedCollection={selectedCollection} />
      <div>
        <div className="container">
          <div className="row justify-content-center" >
            <div className="col-lg-4 col-md-2 mt-3">
              <form method="get" onSubmit={handleSearchVideos}>
                <div className="d-flex">
                  <div className="search-container">
                    <input type="text" className="custom-input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  </div>
                  <button type="submit" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                    <FaSearch />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid p-lg-5">
          <div className="row">
            {filteredData?.length > 0 ?
              filteredData.map((clip, index) => {
                if (index < limit)
                  return (
                    <div className="col-lg-4 p-1 col-md-4 col-sm-6 col-6 position-relative mt-lg-3 mt-md-3 mt-3 video-card" key={index}>
                      <NewVideoCard
                        video={clip}
                        videoType="clip"
                      />
                    </div>
                  )
              }) :
              <div className='col-md-12 text-center mt-5'>
                <h3 className='text-white'>{loading ?"Please wait...":"No Clip Found!"}</h3>
              </div>
            }
          </div>
          {filteredData?.length > 0 && <div className="row mt-5">
            <div className='col-md-12 col-12 text-center'>
              <button onClick={handlLoadMore} type='button' className='navbtn rounded-5 font-lato'>
                LOAD MORE
              </button>
            </div>
          </div>}
        </div>
      </div>
      <Fotter />
    </div>
  )
}

export default SearchResultPage