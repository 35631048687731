/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import music from "../assetr/musicicon.png";
import views from "../assetr/blackion.png";
import { FaDownload, FaSearch } from "react-icons/fa";
import { BiSolidHeartCircle, BiSolidLike, BiSave } from "react-icons/bi";
import { FaShareNodes } from "react-icons/fa6";
import searchline from "../assetr/searchline.png";
import Fotter from "../component/Fotter";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import VideoCard from "../component/VideoCard";
import { FaFontAwesomeFlag } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { MdFileDownloadOff } from "react-icons/md";

import {
  getPlanScopes,
  Scopes,
  paginateArray,
  checkUserScope,
  checkItemExistence,
} from "../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecentSearches,
  addToFavourite,
  addToLike,
  getVideoClips,
  getVideoDetail,
  updateVideoViews,
} from "../redux/slices/videoSlice";
import Modal from "react-bootstrap/Modal";
import Videodetailcard from "../component/Videodetailcard";
import ReportModal from "../component/ReportModal";
import { generateDownloadUrl } from "../utils/ApiCalls";
import PlaylistModal from "../component/PlaylistModal";
import NewVideoCard from "../component/NewVideoCard";
const Videodetail = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const location = useLocation();
  const dataObject = location.state?.data;
  const dispacth = useDispatch();
  const [singleVideoDetail,setVideoDetail] = useState(null);
  const { userDetail } = useSelector((state) => state.user);
  const {videoDetail } =
    useSelector((state) => state.video);
  const [modalShow, setModalShow] = useState(false);
  const addVideoToFavourite = async () => {
    if (userDetail?.id > 0) {
      // if (checkUserScope("addtofav", userDetail?.currentplan)) {
        setFavourite(!isFavourite);
        let data = {
          type: type,
          video_id: id,
          title: singleVideoDetail?.title,
        };
        dispacth(addToFavourite(data, handleFavResponse));
      // } else {
      //   alert("You don't have permission to Add this video to Favourite");
      // }

    } else {
      alert("Please login and then try to add this video to Favourite");
    }
  };

  const addVideoToLikes = async () => {
    if (userDetail?.id > 0) {
      setLiked(!isLiked);
      let data = {
        type: type,
        video_id: id,
        title: singleVideoDetail?.title,
      };
      dispacth(addToLike(data, handleLikeResponse));
    } else {
      alert("Please login and then try to like the video");
    }

  };
  const handleFavResponse = (data) => {

  };
  const handleLikeResponse = (data) => {

  };

  const handleFileDownload = async () => {
    if (type === "long") {
      alert("Downloading is not allowed for long videos.");
      return;
    }
    let data = {
      videoId: id,
      title: singleVideoDetail?.title,
      thumbnail:
        "https://customer-np227mkvthh0ptnr.cloudflarestream.com/" +
        id +
        "/thumbnails/thumbnail.jpg",

    };
    let downloadData = await generateDownloadUrl(data);
    if (downloadData?.status) {
      const downloadUrl = downloadData?.data?.url;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", singleVideoDetail?.title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(downloadData?.message)
    }
    // dispacth(generateDownloadUrl(data,handleDownloadResponse))
  };

  useEffect(() => {
    if(dataObject?.video){
      console.log(dataObject?.video);
      setVideoDetail(dataObject?.video);
      dispacth(getVideoDetail(dataObject?.video?.parent_video_uid,'long'))
    }
  }, [dataObject]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispacth(updateVideoViews(id));
  }, [dispacth, id, type]);
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: singleVideoDetail?.title,
          text: "Checkout this video on Tateclips",
          url: "https://tateclips.com/video/detail/" + id,
        });
      } else {
        throw new Error("Web Share API not supported");
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
      // Fallback to other methods if Web Share API is not supported or sharing fails
      // For example, you can use a library like react-share: https://www.npmjs.com/package/react-share
    }
  };

  const [modalVideo, setModalVideo] = useState(null)

  let modalTimeout1;

  const showVideoModal = (clip) => {
    clearTimeout(modalTimeout1);
    modalTimeout1 = setTimeout(() => {
      setModalShow(true);
      setModalVideo(clip)
    }, 2000);
  };
  useEffect(() => {
    setLiked(checkItemExistence(singleVideoDetail?.likes, userDetail?.id));
    setFavourite(checkItemExistence(singleVideoDetail?.favourite, userDetail?.id));
    if(singleVideoDetail?.id>0){
      setLoading(false);
    }
  }, [singleVideoDetail, userDetail]);
  const [isLiked, setLiked] = useState(false);
  const [isFavourite, setFavourite] = useState(false);
  const [reportModalShow, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playlistModalShow, setPlaylistModalShow] = useState(false);
  const handleClosePlaylistModal = () => setPlaylistModalShow(false);
  const handleClose = () => setReportModal(false);
  const handleShowReportModal = () => {
    setReportModal(true);
  }
  const handleShowPlaylistModal = () => {
    setPlaylistModalShow(true);
  }
  const [search, setSearch] = useState(null);
  const handleSearchVideos = async (e) => {
    e.preventDefault();
    let data = {
      search: search,
    };
    dispacth(addRecentSearches(data));
    navigate("/video/result?search=" + search);
  };
  return (
    <div>
     {loading &&
        <div class="loading">Loading&#8230;</div>
      }
      <PlaylistModal show={playlistModalShow} handleClose={handleClosePlaylistModal} videoId={id} />
      <ReportModal show={reportModalShow} handleClose={handleClose} videoId={id} />
    
      <Navbar />
      {userDetail?.id > 0 ? "" : (
        <div className="container-fluid" style={{ backgroundColor: "black" }}>
          <div className="container bg-maineimg">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="forpremiumuser p-3 rounded-3 text-white">
                  <h6 className="premiumcard font-lato font-10">
                    This content is for Plus members only.
                  </h6>
                  <div className="d-flex justify-content-center">
                    <div>
                      <button className="premiumbtn font-lato font-8">
                        Trailer
                      </button>
                    </div>
                    <div className="ms-3">
                      <button className="premiumbtn1 font-lato font-8">
                        Join Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

      }
      {/* Dragons */}
      <div className="container">
          <div className="row justify-content-center" >
            <div className="col-lg-4 col-md-2 mt-3">
              <form method="get" onSubmit={handleSearchVideos}>
                <div className="d-flex">
                  <div className="search-container">
                    <input type="text" className="custom-input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                  </div>
                  <button type="submit" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                    <FaSearch />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      <div className="container pt-5 ">
        <div className="row ">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="dragonscard ">
              <div className="">
                <h6 className="font-inter font-20 fw-600 ps-2 republictextwhite">
                  {singleVideoDetail?.data?.tags}
                </h6>
                <iframe
                  style={{ width: "100%", height: 350 }}
                  autoplay={true}
                  muted={true}
                  title={singleVideoDetail?.title}
                  src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${singleVideoDetail?.cloud_flare_video_id}/watch`}
                  loading="lazy"
                  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                  allowfullscreen="true"
                ></iframe>
                <div className="d-flex p-2">
                  <div>
                    <img src={music} width={13} alt="" />{" "}
                    <span className="font-lato font-18 video-title">
                      {singleVideoDetail?.title}
                    </span>
                  </div>
                  <div className="ps-4">
                    <img src={views} width={15} alt="" />{" "}
                    <span className="font-lato font-12 video-title">
                      {" "}
                      {singleVideoDetail?.likes?.length > 0 ? singleVideoDetail?.likes?.length : 0} Likes
                    </span>
                    <span className="font-lato font-12 video-title ms-2">
                      {" "}
                      {singleVideoDetail?.favourite?.length > 0 ? singleVideoDetail?.favourite?.length : 0} Favourite
                    </span>
                    <span className="font-lato font-12 video-title ms-2">
                      {" "}
                      {singleVideoDetail?.downloads?.length > 0 ? singleVideoDetail?.downloads?.length : 0} downloads
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3 iconscard ">
                <div className="d-flex justify-content-center mb-3 p-4 ">
                  <div className="icon-container">
                    <a
                      title={
                        userDetail?.id !== undefined
                          ? "Download Video"
                          : "Please login or register to download this clip"
                      }
                      href="javascript:void(0)"
                      onClick={() => {
                        userDetail?.id !== undefined
                          ? handleFileDownload()
                          : alert(
                            "Please login or register to download this clip"
                          );
                      }}
                      download={"Download Video"}
                    >
                      {type === "long" ? <MdFileDownloadOff size={23} className="coloryellow" /> : <IoMdDownload size={23} className="coloryellow" />}
                    </a>
                  </div>
                  <div
                    className={`ms-3 icon-container ${isFavourite
                      ? "favourite"
                      : ""
                      }`}
                    onClick={addVideoToFavourite}
                  >
                    <BiSolidHeartCircle className=" " />
                  </div>
                  <div
                    className={`ms-3 icon-container ${isLiked
                      ? "favourite"
                      : ""
                      }`}
                    onClick={addVideoToLikes}
                  >
                    <BiSolidLike className="" />


                  </div>

                  <div
                    className="ms-3 icon-container"
                    style={{ cursor: "pointer" }}
                    onClick={handleShare}
                  >
                    <FaShareNodes className="" />
                  </div>
                  <div
                    className="ms-3 icon-container"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPlaylistModal}
                  >
                    <BiSave className="" />
                  </div>
                  {checkUserScope('reportfaultyclips', userDetail?.currentplan) &&
                    <div className="ms-3 icon-container" onClick={handleShowReportModal}>
                      <FaFontAwesomeFlag />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
            <Videodetailcard longVideoDetail={videoDetail} videodetail={singleVideoDetail?.parent_video} type={type} videoId={type === 'long' ? singleVideoDetail?.cloud_flare_video_id : singleVideoDetail?.parent_video?.cloud_flare_video_id} />
          </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
          <div>
            <p className="font-inter font-18 fw-600 mb-0 ">
              {" "}
              <span className="linenew">Related Videos</span>
            </p>
          </div>

          {videoDetail?.clips?.length > 0 &&
            videoDetail?.clips.map((val, index) => {
              if (index < 12) {
                return (
                  <div
                    className="col-lg-12 p-1 col-md-12 col-sm-12 col-12 position-relative mt-lg-3 mt-md-3 mt-3 video-card"
                    key={val?.index}
                  >
                    <NewVideoCard
                      video={val}
                      videoType="clip"
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      {

      }
      <div className="container">
        <div className="row justify-content-center">
         
        </div>
      </div>
      <div className="container pt-4 mt-3x ">
        <div className="row">
          <div>
            <img src={searchline} className="w-100 mt-4" alt="" />
          </div>
        </div>
      </div>

      <Fotter />
    </div>
  );
};

export default Videodetail;
