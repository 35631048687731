import React from 'react';
import { FaCheck } from "react-icons/fa6";

const MycardPricing = ({splitTitle, freePlans, currentPlan, plans, duration, handleSelectPlan, handleSelectFreePlan }) => {
  return (
    <div>
      <div className="container p-5">
        <div className="row justify-content-center">
        {freePlans?.length > 0 &&
            freePlans.map((val, index) => {
              return (
                <div className=" col-12 col-sm-6 col-md-4 col-lg-3 ps-0 mb-3">
                  <div className={`${val?.isRecommended == 1 ? 'Recommended h-25 ' : ''}rounded-3`}>
                    {val?.isRecommended == 1 ?
                      <h6 className='text-center py-2 mb-0'>Recommended</h6> : <h6 style={{ visibility: 'hidden' }}>Recommended</h6>
                    }
                    <div className='pricingannualcard h100 pricing-card-wrapper align-items-stretch p-4'>
                      <h6 className='font-inter fw-500 font-25'>{val?.name}</h6>
                      {/* <p className='font-inter font-15'>
                        Grow your business with
                        essential video tools
                      </p> */}
                      {val?.price_type == 'paid' ?
                        <h3 className='font-inter font-35'> ${parseFloat(val?.price)?.toFixed(2)} </h3>
                        :
                        <h3 className='font-inter font-35'> $0 </h3>

                      }
                      <p className='font-inter font-15'>
                        Duration: {val?.duration ? val?.duration : ''}
                      </p>
                      <div>
                        {val?.status ?
                          <button
                            onClick={() => handleSelectPlan(val?.id, val?.name, val?.price, val?.level)}
                            type='button' className='getstarted px-3'>{val?.id == currentPlan ? 'Currently Activated' : 'Choose Plan'}</button>
                          :
                          ""
                        }
                      </div>

                      <div className='mt-3'>
                        <h6 className='font-inter fw-700 font-12'> Includes everything in {val?.include_everything_in} </h6>
                        {val?.websitefeatures?.length > 0 &&
                          val?.websitefeatures.map((feature) => {
                            if (feature?.feature?.title?.startsWith("(Coming Soon)")) {
                              return (
                                <div className='d-flex' style={{ marginTop: '10px' }}>
                                  <div>
                                    <FaCheck size={12} />
                                  </div>
                                  <div>
                                    <p className='ps-2 mb-0 font-inter font-13 mt-2'>{feature?.feature?.title}</p>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div className='d-flex'>
                                  <div>
                                    <FaCheck size={12} style={{ marginTop: '10px' }} />
                                  </div>
                                  <div>
                                    <p className='ps-2 mb-0 font-inter font-13 fw-bold mt-2'>{feature?.feature?.title}</p>
                                  </div>
                                </div>
                              )
                            }
                          })

                        }

                      </div>
                    </div>
                  </div>
                </div>
              )

            })

          }
          {plans?.length > 0 &&
            plans.map((val, index) => {
              return (
                <div className=" col-12 col-sm-6 col-md-4 col-lg-3 ps-0 mb-3">
                  <div className={`${val?.isRecommended == 1 ? 'Recommended h-25 ' : ''}rounded-3`}>
                    {val?.isRecommended == 1 ?
                      <h6 className='text-center py-2 mb-0'>Recommended</h6> : <h6 style={{ visibility: 'hidden' }}>Recommended</h6>
                    }
                    <div className='pricingannualcard h100 pricing-card-wrapper align-items-stretch p-4'>
                    {splitTitle ?
                    <>
                      <h6 className='font-inter fw-500 font-25'>{val?.name?.split(" - ")?.[0]}</h6>
                      <h6 className='font-inter fw-500'>{val?.name?.split(" - ")?.[1]}</h6>

                    </>
                      
                    :
                      <h6 className='font-inter fw-500 font-25'>{val?.name}</h6>
                    }
                      
                      {/* <p className='font-inter font-15'>
                        Grow your business with
                        essential video tools
                      </p> */}
                      {val?.price_type == 'paid' ?
                        <h3 className='font-inter font-35'> ${parseFloat(val?.price)?.toFixed(2)} </h3>
                        :
                        <h3 className='font-inter font-35'> $0 </h3>

                      }
                      <p className='font-inter font-15'>
                        Duration: {val?.duration ? val?.duration : 'Unlimited'}
                      </p>
                      <div>
                        {val?.status ?
                          <button
                            onClick={() => handleSelectPlan(val?.id, val?.name, val?.price, val?.level)}
                            type='button' className='getstarted px-3'>{val?.id == currentPlan ? 'Currently Activated' : 'Choose Plan'}</button>
                          :
                          ""
                        }
                      </div>

                      <div className='mt-3'>
                        <h6 className='font-inter fw-700 font-12'> Includes everything in {val?.include_everything_in} </h6>
                        {val?.websitefeatures?.length > 0 &&
                          val?.websitefeatures.map((feature) => {
                            if (feature?.feature?.title?.startsWith("(Coming Soon)")) {
                              return (
                                <div className='d-flex' style={{ marginTop: '10px' }}>
                                  <div>
                                    <FaCheck size={12} />
                                  </div>
                                  <div>
                                    <p className='ps-2 mb-0 font-inter font-13 mt-2'>{feature?.feature?.title}</p>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div className='d-flex'>
                                  <div>
                                    <FaCheck size={12} style={{ marginTop: '10px' }} />
                                  </div>
                                  <div>
                                    <p className='ps-2 mb-0 font-inter font-13 fw-bold mt-2'>{feature?.feature?.title}</p>
                                  </div>
                                </div>
                              )
                            }
                          })

                        }

                      </div>
                    </div>
                  </div>
                </div>
              )

            })

          }

        </div>
      </div>
    </div>
  )
}

export default MycardPricing