import React from 'react'
import Navbar from '../component/Navbar'
import { FaCheck } from 'react-icons/fa'
import Checkoutstep from '../component/Checkoutstep'
import Fotter from '../component/Fotter'

const Checkoutsteponeoftywo = () => {
  return (
    <div>
        <Navbar/>
        <div className="container pt-5 mb-5">
            <div className="row">
                <div className="col-lg-2"></div>
            <div className="col-3 text-white mt-5">
                <div >
                    <h3 className='mt-3 font-inter font-29 fw-500'>Standard Package</h3>
                    <h6 className='mb-4 font-inter font-22'>$33 per seat / month</h6>
                    
                </div>
                <div className='Includes'></div>
                <div className='mt-4'>
                    <p>Includes everything in Starter </p>
                </div>
                <div className='d-flex'>
                        <div>                                  
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>120 videos</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>Brand kit</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>Teleprompter</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>Text-based video editing</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>AI script generator</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>Marketing integrations</p>
                        </div>
                       </div>
                       <div className='d-flex'>
                        <div>
                        <FaCheck size={12} />
                        </div>
                        <div>
                          <p className='ps-2 mb-0 font-inter font-10 mt-2'>Marketing integrations</p>
                        </div>
                       </div>
            </div>
            <div className="col-1">

            </div>
            <div className="col-6">
                <div className='Checkout p-5 rounded-3'>

                <Checkoutstep/>
                </div>
            </div>
            </div>
        </div>
        <Fotter />

    </div>
  )
}

export default Checkoutsteponeoftywo