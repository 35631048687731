import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Fotter from './Fotter';
import { FaSearch } from 'react-icons/fa';
import ProfileSidBar from './ProfileSidBar';
import Navbar from "../component/Navbar";
import { useDispatch } from 'react-redux';
import {  removeFromPlaylist} from '../redux/slices/videoSlice';
import { ToastContainer, toast } from "react-toastify";

const Myvideocotent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { type,data } = location.state || { type: "Downloads",data:[] };
    const dispatch = useDispatch();
    const handleDeleteVideoFromFolder = async (id) => {
            let data = {
                id: id,
            };
            dispatch(removeFromPlaylist(data, handleRemoveResponse));
    };

    const handleRemoveResponse = (data) => {
        toast.success(data?.message, {
            position: "top-right",
            autoClose: 2000,
        });
    };

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        setFilteredData(data.slice(startIndex, endIndex));
    }, [data, page, limit]);

    const pages = Math.ceil(data.length / limit);

    const handleNext = () => {
        if (page < pages) {
            setPage(page + 1);
        }
    };

    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handlePageClick = (pageVal) => {
        setPage(pageVal);
    };

    return (
        <div>
            <Navbar />
            <ToastContainer />
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mt-3">
                        <div className="profileinfo p-2 rounded-3 p-3">
                            <ProfileSidBar isProfile={true} />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
                            <h6 className="font-inter fw-700 font-20">{type}</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-6"></div>
                            <div className="col-12">
                                <div className="profileinfo p-4 rounded-1 mt-3">
                                    <div className="d-flex">
                                        <div className="search-container">
                                            <input type="text" className="custom-input" placeholder="Search..." />
                                        </div>
                                        <button type="button" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                                            <FaSearch />
                                        </button>
                                    </div>
                                    <table className="custom-table mt-3">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Video</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.length > 0 && filteredData.map((value, index) => (
                                                <tr key={index} className="membershinlein">
                                                    <td>
                                                        <h6>
                                                        <a href={`/video/detail/${value?.videos?.cloud_flare_video_id}/clip`}
                                                        >
                                                        {value?.videos?.title}
                                                        </a>
                                                        </h6>
                                                    </td>
                                                    <td><img src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${value?.videos?.cloud_flare_video_id}/thumbnails/thumbnail.jpg`} width={40} alt="" /></td>
                                                    <td>
                                                        <button type='button' onClick={() => handleDeleteVideoFromFolder(value?.id)} className="delete-button">Delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-start mt-3">
                                        <button
                                            className="btn-login rounded-2"
                                            onClick={() => navigate('/myvideos')}
                                        >
                                            Back
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination" style={{ backgroundColor: "transparent" }}>
                                                <li className="page-item">
                                                    <button type="button" onClick={handlePrev} className="page-link" aria-label="Previous">
                                                        Previous
                                                    </button>
                                                </li>
                                                {Array.from({ length: pages }, (_, index) => (
                                                    <li key={index} className="page-item ms-1 rounded-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => handlePageClick(index + 1)}
                                                            className={`page-link ${(index + 1) === page ? 'active-page' : ''}`}>{index + 1}</button>
                                                    </li>
                                                ))}
                                                <li className="page-item ms-1">
                                                    <button type="button" onClick={handleNext} className="page-link" aria-label="Next">
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fotter />
        </div>
    );
};

export default Myvideocotent;
