import React, { useState } from 'react'
import logo from "../assetr/logo.png";
import { useNavigate } from 'react-router-dom'
import { Videos } from '../component/Videos';
import { Alert, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { verifyOTP } from '../redux/slices/userSlice';
const OtpVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp,setOTP]=useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const email = localStorage.getItem('tate-clip-user-email');

    const handleResponse = (data) => {
        setLoading(false);
        if (data?.status) {
            localStorage.setItem('tate-clip-user-email', email)
            navigate('/resetPassword')
        } else {
            setError(data?.message)
        }
    }
    const handleVerifyOTP=()=>{
        setLoading(true);
        let data={
            email:email,
            token:otp
        }
        dispatch(verifyOTP(data,handleResponse))
    }
    const handleAlertDismiss = () => {
        setError(null);
    };
    return (
        <div>
            <div className="container-fluid min-100vh p-0">
                <div className="row min-100vh">
                    <div className="col-4 backgroundimges">
                        <Videos />
                    </div>
                    <div className="col-lg-8 col-md-8 col-12 d-flex flex-column justify-content-between pt-lg-5 pt-md-5 pt-3 px-0">
                        <div className="container-fluid">
                            <div className="row justify-content-center me-lg-5 me-md-5 login-right-side">
                                <div className="col-lg-8 col-md-8 col-12 text-center">
                                    <img src={logo} className=' mt-2 mb-3' width={150} alt="" />
                                    <h3 className=' font-inter mt-3 fw-700 font-20 coloryellow'>OTP Verification</h3>
                                    <p className='content font-inter font-12'> 6 digit code has been sent on your Email please check your inbox, also check spam list.
                                    </p>
                                    <form className="row mt-2">
                                        <div class=" col-12 mt-4">
                                            <label htmlFor="" className='content'>Enter Verification Code</label>
                                            <input type="text" value={otp} onChange={(e)=>setOTP(e.target.value)} required className="form-control input-field p-3" placeholder="Enter Your Verification Code" aria-label="Enter Verification Code" />
                                        </div>
                                        {error &&
                                                <Alert style={{ marginTop: 20 }} color="danger" isOpen={!!error} toggle={handleAlertDismiss}>
                                                    {error}
                                                </Alert>

                                            }
                                        <div className="col-12">
                                            <div>
                                                <button type='button' className='btn-login px-4 py-2 mt-3 rounded-3' onClick={handleVerifyOTP}>
                                                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpVerification
