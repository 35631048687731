// src/Commitment.js
import React from 'react';
import Navbar from "../component/Navbar";
import Fotter from "../component/Fotter";


const Commitment = () => {
    return (
        <>
            <Navbar />
            <div className="commitment-container">
                <div className="commitment-content">
                    <h2 className="commitment-title">Our Commitment</h2>
                    <p className="last-updated">Last updated: June 10, 2024</p>
                    <p className="commitment-text">
                        At TateClips, we have meticulously crafted our business model to ensure that it does not negatively impact the income sources of the Tate Brothers or the platform, The Real World. Our focus is to serve as a powerful tool that enhances these income streams by providing the community with advanced tools that increase the reach, awareness, and influence of the Tate Brothers' content.
                    </p>
                    <p className="commitment-text">Two key examples of our commitment are:</p>
                    <ol className="commitment-list">
                        <li>
                            <strong>Exclusive Content on Rumble:</strong> All original uncut content is exclusively viewable on the official Rumble channels. This ensures that revenue generated from views on the Rumble platform remains unaffected, as users will continue to watch the full uncut content there instead of on TateClips.
                        </li>
                        <li>
                            <strong>Complementary Course Materials:</strong> We have developed complementary course materials that are designed to work in conjunction with the video courses. These materials are only useful when users have gone through the original video courses first. This approach incentivizes TateClips users who are not yet part of The Real World community to join, allowing them to benefit fully from both platforms.
                        </li>
                    </ol>
                    <p className="commitment-text">
                        Our goal is to exponentially increase the momentum of the Tate Brothers' online presence and financial growth while fostering a thriving community that benefits everyone involved.
                    </p>
                </div>
            </div>
            <Fotter />
        </>
    );
};

export default Commitment;
