import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";

// import SwitchButton from "../component/SwitchButton";
import Fotter from "../component/Fotter";
import MycardPricing from "../component/MycardPricing";
import { activateFreePlan, getAllPlans, getUserProfille } from "../utils/ApiCalls";
import { generateRandomToken } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const Pricing = () => {
  const navigate = useNavigate();
  const { userDetail } = useSelector((state) => state.user);
  const { themeData } =
    useSelector((state) => state.theme);
  const [plans, setPlans] = useState([]);
  const getPaymentPlans = async () => {
    setPlans(await getAllPlans())
  }
  useEffect(() => {
    getPaymentPlans();
  }, []);

  const [freePlans, setFreePlans] = useState([]);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [lifetimePlans, setLifetimePlans] = useState([]);

  useEffect(() => {
    console.log(plans)
    if(plans?.data?.length>0){

      const free = plans?.data?.filter(plan => plan.price_type === 'free');
      const monthly = plans?.data?.filter(plan => plan.duration === 30);
      const yearly = plans?.data?.filter(plan => plan.duration === 365);
      const lifetime = plans?.data?.filter(plan => plan.duration === null);
  
      setFreePlans(free);
      setMonthlyPlans(monthly);
      setYearlyPlans(yearly);
      setLifetimePlans(lifetime);
    }
  }, [plans?.data]);
  const handleSelectPlan = async (planId, planName, planPrice, planLevel) => {
    const token = await localStorage.getItem("video-paltform-token");
    if (!token) {
      let token = generateRandomToken(60)
      navigate('/register?from=pricing&tokon=' + token)
    } else {
      let type = 'upgrade';
      if (parseInt(planLevel) < parseInt(userDetail?.currentplan?.level)) {
        type = 'downgrade'
      }
      let firstToken = generateRandomToken(60);
      let secondToken = generateRandomToken(60);
      navigate(`plan/${firstToken}/${secondToken}?id=${planId}&name=${planName}&type=${type}`)
    }
  }
  const handleSelectFreePlan = (planId, planName, planPrice) => {
    let data = {
      plan_id: planId
    }
    activateFreePlan(data, handleFreePlanResponse)
  }

  const handleFreePlanResponse = (data) => {
    let firstToken = generateRandomToken(60);
    let secondToken = generateRandomToken(60);
    navigate('/payment/success?_token=' + firstToken + "&auth=true&aid=" + secondToken);
  }
  return (
    <div>
      <Navbar />
      <div className="contaoner-fluid">
        <h1 className="text-center  font-inter content font-75 fw-500 mt-5 mb-4">
          {themeData?.plans?.content?.title}
        </h1>
        <div className="d-flex justify-content-center">
          <div className="">
            <div className="btn-Choose rounded-5  mytab">
              <ul className="nav nav-pills p-1 justify-content-around" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link p-1 rounded-4 active" id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true">Monthly</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link p-1 rounded-4 " id="pills-yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-yearly" type="button" role="tab" aria-controls="pills-yearly" aria-selected="false">Yearly</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link p-1 rounded-4 " id="pills-lifetime-tab" data-bs-toggle="pill" data-bs-target="#pills-lifetime" type="button" role="tab" aria-controls="pills-lifetime" aria-selected="false">Lifetime</button>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="">
            <label className="ms-4 savebtn" htmlFor="pricing-toggle-monthly">
              Save 40%
            </label>
          </div> */}
        </div>
        <div className="col-12">
          <div className="tab-content" id="pills-tabContent">
            {/* Monthly */}
            <div className="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
              <MycardPricing handleSelectFreePlan={handleSelectFreePlan} currentPlan={userDetail?.currentplan?.plan_id} plans={monthlyPlans} freePlans={freePlans} handleSelectPlan={handleSelectPlan} />
            </div>
            {/* Yearly */}
            <div className="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab">
              <MycardPricing handleSelectFreePlan={handleSelectFreePlan} currentPlan={userDetail?.currentplan?.plan_id} plans={yearlyPlans} handleSelectPlan={handleSelectPlan} />
            </div>
            {/* Lifetime */}
            <div className="tab-pane fade" id="pills-lifetime" role="tabpanel" aria-labelledby="pills-lifetime-tab">
              <MycardPricing splitTitle={true} handleSelectFreePlan={handleSelectFreePlan} currentPlan={userDetail?.currentplan?.plan_id} plans={lifetimePlans} handleSelectPlan={handleSelectPlan} />
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
