import React, { useEffect, useState } from 'react'
import Navbar from "../component/Navbar";
import ProfileSidBar from '../component/ProfileSidBar';
import { FaSearch } from "react-icons/fa";
import { RiCalendarLine } from "react-icons/ri";
import { CiFilter } from "react-icons/ci";
import { useSelector } from 'react-redux';
import Fotter from '../component/Fotter';

const Refarrals = () => {
    const { userDetail, affiliateCustomers } = useSelector((state) => state.user);
    const [search, setSearch] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        setFilteredData(affiliateCustomers?.referrals)
    }, [affiliateCustomers]);
    const handleSearch = () => {
        const filteredDownloads = affiliateCustomers?.referrals.filter((val) => {
            // Convert title and search query to lowercase for case-insensitive search
            const title = val.name?.toLowerCase();
            const query = search?.toLowerCase();
            // Check if the title contains the search query
            return title.includes(query);
        });
        // Update state with filtered downloads
        setFilteredData(filteredDownloads);
    };
    const handleDateFilter = (e) => {
        let queryDate = e.target.value;
        const filteredDownloads = affiliateCustomers?.referrals.filter((val) => {
            // Convert title and search query to lowercase for case-insensitive search
            const date = val?.created_at.split("T")[0];
            const query = search?.toLowerCase();
            // Check if the title contains the search query
            return date.includes(queryDate);
        });
        // Update state with filtered downloads
        setFilteredData(filteredDownloads);
    };
    return (
        <div>
            <Navbar />
            <div className="container-fluid profilepage"></div>
            <div className="container">
                {/* <h6 className="profilecontent">Affiliate Dashboard</h6> */}
                <div className="row justify-content-center">
                    <div className="col-md-3 mt-3">
                        <div className="profileinfo p-2 rounded-3 p-3">
                            <ProfileSidBar isAffiliate={true} />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="profileinfo p-2 rounded-1 ps-4  mt-3">
                            <h6 className="font-inter fw-700 font-20">Welcome to Affiliate Dashboard<br />
                            </h6>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className='profileinfo p-4 rounded-1 mt-2'>
                                    <h6> Referrals</h6>
                                    <p> See your referral activity, and search or filter it by date or action.</p>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-4 mt-2">
                                            <div className="d-flex">
                                                <div className="search-container">
                                                    <input type="text" className="custom-input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                                                </div>
                                                <button onClick={handleSearch} type="button" style={{ marginLeft: 10 }} className="searchicon rounded-2">
                                                    <FaSearch />
                                                </button>
                                            </div>
                                            {/* <div className="d-flex">
                                                
                                                <div className="search-container">
                                                    <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search..." />
                                                </div>
                                                <button type="button" onClick={handleSearch} className="searchicon rounded-2" style={{ marginLeft: "10px", padding: '10px' }}>
                                                    <FaSearch />
                                                </button>
                                            </div> */}

                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <div className="filter-container">
                                                <select className="form-select me-3">
                                                    <option value="any">Any</option>
                                                    <option value="live">Sale</option>
                                                </select>
                                                <CiFilter className="filter-icon" />
                                            </div>
                                        </div>

                                        <div className="col-md-4 mt-2">
                                            <div className="custom-input-container">
                                                <input
                                                    type="date"
                                                    placeholder="mm/dd/yy"
                                                    className='custom-input3'
                                                    onChange={handleDateFilter}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3 mt-2 ms-3">
                                            <button type="submit" className="searchicon p-1 rounded-2 ps-3">
                                                <FaSearch />
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 overflowXScroll'>
                                            <table className="custom-table mt-3">
                                                <thead>
                                                    <tr className='membershinlein'>
                                                        <th>Name</th>
                                                        <th>Action</th>
                                                        <th>Reward</th>
                                                        <th>Status</th>
                                                        <th>Date</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData?.length > 0 ?
                                                        filteredData.map((val, index) => {
                                                            return (
                                                                <tr className="membershinlein">
                                                                    <td>{val?.name}</td>
                                                                    <td>
                                                                        <button className="btn btn-primary">Sale</button>
                                                                    </td>
                                                                    <td>
                                                                        {val?.commission_to_partner?.[0]?.total_amount > 0 ? parseFloat(val?.commission_to_partner?.[0]?.total_amount) : 0} USD
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-success">OK</button>
                                                                    </td>
                                                                    <td>{val?.created_at.split("T")[0]}</td>

                                                                </tr>

                                                            )
                                                        }) :

                                                        <tr className="membershinlein">
                                                            <td colSpan={3}>
                                                                <h2>No Referrals Found</h2>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fotter />
        </div>

    )
}

export default Refarrals
