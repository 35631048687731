import React, { useEffect, useState } from 'react'
import Navbar from "../component/Navbar";
import ClipsTab from '../component/ClipsTab';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLongFormVideoDetail, getVideoDetail } from '../redux/slices/videoSlice';
import Clipsmodule from '../component/Clipsmodule';
import Fotter from '../component/Fotter';


const LongFormVideoclips = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [videoDetail, setVideoDetail] = useState(null);
    const location = useLocation();
    const dataObject = location.state?.data?.video;
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true);
        dispatch(getLongFormVideoDetail(id))
    }, [id])
    const { longVideoDetail } =
        useSelector((state) => state.video);
    const [clipVideoModal, setClipVideoModal] = useState(false);
    const handleShow = () => setClipVideoModal(true);
    const handleClose = () => setClipVideoModal(false);

    useEffect(() => {
        if (dataObject?.id > 0) {
            setLoading(false);
        }
    }, [dataObject]);
    return (
        <div>
            <Navbar />
            <Clipsmodule show={clipVideoModal} handleClose={handleClose} videoId={dataObject?.cloud_flare_video_id} />
            {loading &&
                <div class="loading">Loading&#8230;</div>
            }
            <div className="container">
                <div className="row justify-content-center" >
                    <div className="col-12 col-md-8 col-lg-8 justify-content-center">
                        <div className='p-5 profileinfo1 mt-4 text-center'

                            style={{
                                backgroundImage: `url(${dataObject?.thumbnail})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100%100%",
                                width: "100%",
                                height: "300px"
                            }}
                        >
                            {/* <iframe
                                style={{ width: "100%", height: 350 }}
                                autoplay={true}
                                muted={true}
                                title={dataObject?.title}
                                src={`https://customer-np227mkvthh0ptnr.cloudflarestream.com/${dataObject?.cloud_flare_video_id}/watch`}
                                loading="lazy"
                                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                                allowfullscreen="true"
                            ></iframe> */}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-8 ">
                            <div>
                                <a href={dataObject?.video_url}>
                                    <h4 className='profileinfo p-2 rounded-1 ps-4  mt-3 content'> {dataObject?.title}</h4>
                                </a>
                            </div>

                        </div>
                        <div className="col-12 col-md-8 col-lg-8 ">
                            <div className='profileinfo p-1 rounded-1 ps-4  mt-3 content justify-content-between row'>
                                <div className='col-md-6 col-12'>
                                    <h4>
                                        Create your own Clips on this Video
                                    </h4>
                                </div>
                                <div className='col-md-4 col-12'>
                                <button
                                    type="button"
                                    className="btn-login p-4 px-2 py-2 rounded-3"
                                    onClick={handleShow}
                                >
                                    Create clip
                                </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='mt-4'>
                        <ClipsTab allClips={longVideoDetail?.clips} aiClips={longVideoDetail?.aiclips} humanClips={longVideoDetail?.humanclips} />
                    </div>
                </div>
            </div>
            <Fotter />
        </div>
    )
}

export default LongFormVideoclips
