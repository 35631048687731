import React from "react";
import Navbar from "../component/Navbar";
import viewcart from "../assetr/viewcart.png";
import viewcarticon from "../assetr/ViewCartCross.png";
import { FaArrowLeft } from "react-icons/fa";
import viewcartboy from "../assetr/viewcartboy.png";
import american from "../assetr/payment.png";
import mastercard from "../assetr/mastercardpayment.png";
import paypal from "../assetr/paypalpayment.png";
import visacard from "../assetr/visapayment.png";
import applepayment from "../assetr/applepayment.png";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Fotter from "../component/Fotter";

const AddToCartView = () => {
  return (
    <div>
      <Navbar />
      <div className="container mt-5 text-white">
        <h6>My cart (3) </h6>
        <div className="row">
          <div className="col-8">
            <div className="profileinfo p-4 rounded-1 mt-2">
              <div className="d-flex bordertop">
                <div>
                  {" "}
                  <img src={viewcart} width={50} alt="" />
                </div>
                <div className="ms-2 mt-1">
                  <h6 className="font-inter font-12 fw-600">
                    Jack Ma Motivational Video | Believe In Your Dreams |
                    Inspirational Speech
                  </h6>
                  <p
                    className="font-inter font-12 fw-600"
                    style={{ color: "#E2B912" }}
                  >
                    Price: $200
                  </p>
                </div>
                <div className="ms-5">
                  <img src={viewcarticon} width={30} alt="" />
                </div>
              </div>
              <div className="d-flex bordertop mt-2">
                <div>
                  {" "}
                  <img src={viewcart} width={50} alt="" />
                </div>
                <div className="ms-2 mt-1">
                  <h6 className="font-inter font-12 fw-600">
                    Jack Ma Motivational Video | Believe In Your Dreams |
                    Inspirational Speech
                  </h6>
                  <p
                    className="font-inter font-12 fw-600"
                    style={{ color: "#E2B912" }}
                  >
                    Price: $200
                  </p>
                </div>
                <div className="ms-5">
                  <img src={viewcarticon} width={30} alt="" />
                </div>
              </div>
              <div className="d-flex bordertop mt-2">
                <div>
                  {" "}
                  <img src={viewcart} width={50} alt="" />
                </div>
                <div className="ms-2 mt-1">
                  <h6 className="font-inter font-12 fw-600">
                    Jack Ma Motivational Video | Believe In Your Dreams |
                    Inspirational Speech
                  </h6>
                  <p
                    className="font-inter font-12 fw-600"
                    style={{ color: "#E2B912" }}
                  >
                    Price: $200
                  </p>
                </div>
                <div className="ms-5">
                  <img src={viewcarticon} width={30} alt="" />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-center">
                  <Link to="/videoadtocart" className="add-to-cart-link">
                    <button
                      type="button"
                      className="btn-login px-2 py-1 rounded-3 mt-3"
                    >
                      <FaArrowLeft
                        className="me-2"
                        style={{ color: "black" }}
                      />{" "}
                      Back to videos
                    </button>
                  </Link>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="signupwithgoogle px-2 py-1 rounded-3 mt-3"
                  >
                    {" "}
                    Remove all
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4  tableline">
            <div className="profileinfo p-4 rounded-1 mt-2">
              <div className="d-flex justify-content-between">
                <div>
                  <p>Card Details</p>
                </div>
                <div>
                  {" "}
                  <img src={viewcartboy} width={30} alt="" />
                </div>
              </div>
              <p>Card type</p>
              <div className="d-flex ">
                <div>
                  <img src={american} width={50} alt="" />
                </div>
                <div className="ms-3">
                  <img src={mastercard} width={50} alt="" />
                </div>
                <div className="ms-3">
                  <img src={paypal} width={50} alt="" />
                </div>
                <div className="ms-3">
                  <img src={visacard} width={50} alt="" />
                </div>
                <div className="ms-3">
                  <img src={applepayment} width={50} alt="" />
                </div>
              </div>

              <div className="col-lg-4 col-12 mt-2">
                <label
                  htmlFor=""
                  className="font-12 font-500 font-inter text-white mb-2"
                >
                  {" "}
                  Name on card
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  className="custom-inputdetail"
                />
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <label
                  htmlFor=""
                  className="font-12 font-500 font-inter text-white mb-2"
                >
                  {" "}
                  Card Number
                </label>
                <input
                  type="text"
                  placeholder="111 222 444"
                  className="custom-inputdetail"
                />
              </div>
              <div className="row ">
                <div className="col-6">
                  <label
                    htmlFor=""
                    className="font-12 font-500 font-inter text-white mb-2"
                  >
                    {" "}
                    Expiration date
                  </label>
                  <input
                    type="text"
                    placeholder="yy/mm"
                    className="custom-inputdetail1"
                  />
                </div>
                <div className="col-6 ">
                  <label
                    htmlFor=""
                    className="font-12 font-500 font-inter text-white mb-2"
                  >
                    {" "}
                    CVV
                  </label>
                  <input
                    type="text"
                    placeholder="123"
                    className="custom-inputdetail1"
                  />
                </div>
                <div className="d-flex justify-content-between mt-3 bordertop1">
                  <div className="mt-2">
                    <p className="font-inter font-12">Subtotal</p>
                    <p className="font-inter font-12">Total (Tax incl.)</p>
                  </div>
                  <div className="mt-2">
                    <p className="font-inter font-12">$1,668</p>
                    <p className="font-inter font-12">$1,672</p>
                  </div>
                </div>

                <button className="btn btn-primary d-flex justify-content-between align-items-center">
                  <span>$1,672</span>
                  <span>
                    Checkout <FaArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};

export default AddToCartView;
