/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCreatorClip, createSupportTicket, getSupportCases } from '../redux/slices/videoSlice';
function Clipsmodule({ show, handleClose, videoId }) {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getSupportCases())
  }, []);
  const [title,setTitle] = useState(null);
  const [startTime,setStartTime] = useState(0);
  const [endTime,setEndTime] = useState(1);

  const submitVideoClip = () => {
    let data = {
      videoId: videoId,
      title: title,
     startTime:startTime,
     endTime:endTime
    }
    dispatch(addCreatorClip(data,handleResponse))
    handleClose();
  }
  const handleResponse = (data)=>{
    if(data?.status){
      toast.success("Clip Created Successfully!", {
        position: "top-right",
        autoClose: 2000,
      });
    }else{
      toast.error(data?.message, {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }

  return (
    <>
    <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Your Clips</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
          <form>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor="" style={{ color: "white" }}>
                  Start Time
                </label>
                <input
                  type="number"
                  onChange={(e)=>setStartTime(e.target.value)}
                  value={startTime}
                  required
                  className="form-control input-field p-3"
                  placeholder="Start Time"
                  aria-label=" Start Time"
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor="" style={{ color: "white" }}>
                  End Time
                </label>
                <input
                  type="number"
                  onChange={(e)=>setEndTime(e.target.value)}
                  value={endTime}
                  required
                  className="form-control input-field p-3"
                  placeholder=" End Time"
                  aria-label="End Time"
                />
              </div>
              <div className='form-group col-md-12'>
                <label htmlFor="" style={{ color: "white" }}>
                  Clip Title
                </label>
                <input
                  type="text"
                  onChange={(e)=>setTitle(e.target.value)}
                  value={title}
                  required
                  className="form-control input-field p-3"
                  placeholder="Clip Title"
                  aria-label="Clip Title"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={submitVideoClip}>
              Submit
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
}

export default Clipsmodule;