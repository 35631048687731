import React from 'react'
import Navbar from "../component/Navbar";

const NewHome = () => {
  return (
    <div>
            <Navbar />
        <div className="container-fluid">
            <div className="container">

      <h1> MY NEW HOME PAGE  </h1>
            </div>
        </div>
    </div>
  )
}

export default NewHome
