import React from "react";
import testdark from "../assetr/testimonialgirl.png";
import testlight from "../assetr/testimoniol.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

const Testimoniol = ({ sliderData }) => {
  const { userDetail, theme } = useSelector((state) => state.user);

  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="container-fluid mt-4 b-radius  mb-5 favourit-slider text-white">
        <Slider {...settings}>
          {sliderData?.length > 0 &&
            sliderData.map((val, index) => {
              return (
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-12 cursor-pointer"

                    >
                      <img src={'https://portal.tateclips.com/asset/frontend/light/images/testimonial/'+val?.content?.image_one} className="w-100 rounded-4" alt="" />
                    </div>
                    <div className="col-lg-6  col-12 cursor-pointer align-self-center">
                      <div>
                        <h6 className="fw-800 font-30">{val?.content?.client_name}</h6>
                        <p className="mb-0 font-inter font-12">{val?.content?.designation}</p>
                        <div className="d-flex">
                          <FaStar className="starcolor" />
                          <FaStar className="starcolor" />
                          <FaStar className="starcolor" />
                          <FaStar className="starcolor" />
                          <FaStar className="starcolor" />
                        </div>
                        <p className="mt-3 font-13">
                        {val?.content?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </Slider>
      </div>
    </>
  );
};

export default Testimoniol;
