import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import notidark from "../assetr/notificationicon.png";
import notilight from "../assetr/notificationimg.png";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { getAllNotifications } from "../utils/ApiCalls";
import { useDispatch, useSelector } from "react-redux";


export const ProfileNotification = () => {
  const [notifaction, setNotification] = useState([]);
  const getProfileNotifications = async () => {
    setNotification(await getAllNotifications());
  }
  useEffect(() => {
    getProfileNotifications();
  }, []);
  const { userDetail, theme } = useSelector((state) => state.user);
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent">Profile</h6> */}
        <div className="row">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">

              <ProfileSidBar isProfile={true}/>
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
              <h6 className="font-inter fw-700 font-20 content"> Notifications </h6>
            </div>
            <div className="row">
              <div className="col-md-3">

              </div>
              <div className="col-12 ">
                <div className="profileinfo p-4 rounded-1 mt-3">
                  {notifaction?.length > 0 ?
                    notifaction.map((val, index) => {
                      return (
                        <div className="notificationcart p-2 ps-2 py-2 rounded-3 mt-1">
                          <div className="text-white d-flex justify-content-between">
                            <div className="mt-2 ps-2">
                              {" "}
                              <img  src={theme === "light" ? notidark : notilight} width={30} alt="" /> <span className="ps-3 republictextwhite">{val?.type}</span>
                            </div>
                          </div>
                          <p className="mt-2 ps-2 ">
                            {val?.text}
                          </p>
                        </div>
                      )
                    }) :
                    <div className="notificationcart p-2 ps-2 py-2 rounded-3">
                      <div className="text-white d-flex justify-content-between">
                      </div>
                      <p className="mt-2 ps-2 republictextwhite">
                        No Notifications Found
                      </p>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};
