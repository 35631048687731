import React, { useEffect, useState } from "react";
import logo from "../assetr/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Leftsidpage from "../component/Leftsidpage";
import { loginUser } from "../utils/ApiCalls";
import { Videos } from "../component/Videos";
import { Alert, Spinner } from 'reactstrap';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useDispatch } from "react-redux";
import axios from "axios";
import { loginWithGoogle } from "../redux/slices/userSlice";
const Login = () => {
  // Get the query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search);
  // Get a specific parameter value
  const from = queryParams.get('from');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const handleLogin = async () => {
    setLoading(true);
    let data = {
      email: email,
      password: password
    }
    await loginUser(data, handleResponse);
  };
  const handleResponse = (data) => {
    setLoading(false);
    if (data?.status) {
      localStorage.setItem('video-paltform-token', data?.access_token)
      if (from) {
        navigate('/' + from)
      } else {
        navigate('/profile')
      }
    } else {
      setError(data?.message);
    }
  }
  const handleAlertDismiss = () => {
    setError(null);
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });
  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            let data = {
              email: res?.data?.email,
              name: res?.data?.name,
              google_image: res?.data?.picture,
              google_id: res?.data?.id
            }
            dispatch(loginWithGoogle(data, handleResponse));
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );
  return (
    <div>
      <div className="container-fluid min-100vh p-0">
        <div className="row min-100vh">
          <div className="col-4 backgroundimges">
            {/* <Videos /> */}
          </div>
          <div className="col-lg-8 col-md-8 col-12 d-flex flex-column justify-content-between p-5 text-center">
            <div className="container-fluid">
              <div className="row justify-content-center me-lg-5 me-md-5 login-right-side">
                <div className="col-lg-8 col-md-8 col-12 ">
                  <img src={logo} className=" mt-2 mb-3" width={150} alt="" />
                  <h3
                    className=" font-inter mt-3 fw-700 font-20 coloryellow"

                  >
                    Welcome to Tate Clips
                  </h3>
                  <form className="row mt-2" style={{ textAlign: 'left' }}>
                    <div className="col-12 mt-4">
                      <label htmlFor="" className="content">
                        Your Address
                      </label>
                      <input
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control input-field p-3"
                        placeholder="Email Address"
                        aria-label="Email Address"
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <label htmlFor="" className="content">
                        Password
                      </label>
                      <input
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control input-field p-3"
                        placeholder="Password"
                        aria-label="Password"
                      />
                      <div className="mt-3">
                        <p className="cursor-pointer content font-inter font-12">
                          {" "}
                          Forgot your password?{" "}
                          <a
                            href=" "
                            className="coloryellow text-decoration-none"
                            onClick={() => navigate("/forgotPassword")}
                          >
                            Recover password
                          </a>{" "}
                        </p>
                      </div>
                      {error &&
                        <Alert style={{ marginTop: 20 }} color="danger" isOpen={!!error} toggle={handleAlertDismiss}>
                          {error}
                        </Alert>

                      }
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn-login px-2 py-2 rounded-3  mt-2"
                          onClick={handleLogin}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Log In"}
                        </button>
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <p className="font-16 poppins   credit-lines d-flex justify-content-center align-items-center mt-3 mb-4">
                        &nbsp;&nbsp;Or&nbsp;&nbsp;
                      </p>
                    </div>
                    <div>
                      <h6 className="content text-center font-inter font-10">
                        sign up with your email
                      </h6>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="signupwithgoogle px-2 py-2 rounded-3 mt-2"
                        onClick={() => login()}
                      >
                        {" "}
                        Sign Up with Google
                      </button>
                    </div>
                    <p className="font-12 font-inter mt-3 content text-center">
                      Don't have an account?{" "}
                      <Link
                        to="/register"
                        className="coloryellow text-decoration-none"
                      >
                        sign up here
                      </Link>{" "}
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
