import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Clipsmodule from './Clipsmodule';

const Videodetailcard = ({longVideoDetail, videodetail, type, videoId }) => {
    const [reportModalShow, setReportModalShow] = useState(false);
    const handleClose = () => setReportModalShow(false);
    const handleShow = () => setReportModalShow(true);
    const convertSecondsToDuration = (secs) => {
        const hours = Math.floor(secs / 3600);
        const minutes = Math.floor((secs % 3600) / 60);
        const remainingSeconds = Math.floor(secs % 60);
        return `${hours} h ${minutes} m ${remainingSeconds} s`;
    };
    return (
        <div className='profileinfo1 p-2 rounded-1 mt-3'>
            <h1 className='content'>{type == 'long' ? "Main" : "Parent"} Video Detail</h1>
            <h4 className='profileinfo p-2 rounded-1 ps-4  mt-3 content'>
                {type == 'long' ? videodetail?.title : videodetail?.title
                }</h4>
            <div className='profileinfo p-2 rounded-1 ps-4  mt-3 content d-flex justify-content-between'>
                <h4>
                    Create your own Clips on this Video
                </h4>
                <button
                    type="button"
                    className="btn-login p-4 px-2 py-2 rounded-3"
                    onClick={handleShow}
                >
                    Create clip
                </button>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-6">
                            <div className="profileinfo p-4 rounded-1 mt-3" style={{ border: '1px solid' }}>
                                <h6 className="font-inter fw-700 font-15 text-center">Total Clips
                                </h6>
                                <h2 className='font-inter fw-700 font-15 text-center'>
                                    {type == 'long' ? longVideoDetail?.clips?.length : longVideoDetail?.clips?.length}
                                </h2>

                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-6">
                            <div className="profileinfo p-4 rounded-1 mt-3" style={{ border: '1px solid' }}>
                                <h6 className="font-inter fw-700 font-15 text-center">Video duration
                                </h6>
                                <h2 className="font-inter fw-700 font-15 text-center">
                                    {convertSecondsToDuration(type == 'long' ? longVideoDetail?.duration : longVideoDetail?.duration)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-6">
                            <div className=" p-3 rounded-1 mt-3">
                                <Link
                                    to={"/video/long/" + videoId}
                                    state={{ data: { video:longVideoDetail } }}
                                >
                                    <button
                                        type="button"
                                        className="btn-login p-4 px-2 py-2 rounded-3  mt-2" >
                                        View All Clips
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <Clipsmodule show={reportModalShow} handleClose={handleClose} videoId={videoId} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Videodetailcard
