import { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import { RiCalendarLine } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import Fotter from "../component/Fotter";
import ProfileSidBar from "../component/ProfileSidBar";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseHistory } from "../redux/slices/userSlice";
const DownloadVideo = () => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const { payment } = useSelector((state) => state.user);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    dispatch(getPurchaseHistory());
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container-fluid profilepage"></div>
      <div className="container">
        {/* <h6 className="profilecontent"> Profile</h6> */}
        <div className="row">
          <div className="col-md-3 mt-3">
            <div className="profileinfo p-2 rounded-3 p-3">

              <ProfileSidBar isSubscription={true} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="profileinfo p-2 rounded-1 ps-4 mt-3">
              <h6 className="font-inter fw-700 font-20"> View Purchase History </h6>
            </div>
            <div className="row">
              {/* <div className="col-3"></div> */}
              <div className="col-12">
                <div className="profileinfo p-4 rounded-1 mt-3">
              <div className="row">
                    <div className="col-md-2 col-lg-4 mt-2 col-6 pe-sm-0 pe-sm-0 pe-2">
                      <div className="custom-input-container">
                        <input
                          type="date"
                          placeholder="mm/dd/yy"
                          className='custom-input3'
                        />
                        {/* <RiCalendarLine className="calendar-icon" /> */}
                      </div>
                    </div>
                    <div className="col-md-2 col-lg-4 mt-2 col-6 pe-sm-0 pe-sm-0 pe-2">
                      <div className="custom-input-container">
                        <input
                          type="date"
                          placeholder="mm/dd/yy"
                          className='custom-input3'
                        />
                        {/* <RiCalendarLine className="calendar-icon" /> */}
                      </div>
                    </div>

                    <div className="col-md-2 mt-2 ms-3 col-12 ">
                      <button type="submit" className="searchicon p-1 rounded-2 me-3 ps-3">
                        <FaSearch />
                      </button>
                    </div>
                  </div    >
                  <div className="row">
                    <div className="col-12 overflowXScroll">
                      <table class="custom-table mt-3">
                        <thead>
                          <tr>
                            <th className="font-inter font-13 fw-700">Date</th>
                            <th className="font-inter font-13 fw-700">Item</th>
                            <th className="font-inter font-13 fw-700">
                              Transaction ID
                            </th>
                            <th className="font-inter font-13 fw-700">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payment?.length > 0 ?
                            payment.map((val, index) => {
                              return (
                                <tr className="tableline">
                                  <td className="font-inter font-12 fw-700">
                                    {
                                      val?.created_at
                                    }
                                  </td>
                                  <td className="font-inter font-12 fw-700">
                                    {val?.plan?.name}
                                  </td>
                                  <td className="font-inter font-12 fw-700">
                                    {val?.trx}
                                  </td>
                                  <td className="font-inter font-12 fw-700">
                                    {
                                      "$" + parseFloat(val?.total)
                                    }
                                  </td>
                                </tr>
                              )
                            })

                            :
                            <tr className="tableline">
                              <td className="font-inter font-12 fw-700" colSpan={4}>
                                <h3>No Purchase History Found</h3>
                              </td>
                            </tr>
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};

export default DownloadVideo;
