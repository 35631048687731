import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaUserCircle, FaSun, FaMoon, FaDiscord, FaHeart } from "react-icons/fa";
import userpannel from "../assetr/userpannel.png";
import profilelogout from "../assetr/profilelogout.png";
import { useDispatch, useSelector } from "react-redux";
import { darkLightModeSwitch, getUser, logout } from "../redux/slices/userSlice";
import { persistStore } from 'redux-persist';
import { store } from "../redux/store/store";
import { googleLogout } from "@react-oauth/google";
import { getThemeData } from "../redux/slices/themeSlice";

const Navbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.user);
  const { theme } = useSelector((state) => state.user);
  const { themeData } = useSelector((state) => state.theme);
  const [profile, setProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    setProfileImage(userDetail?.google_image == null ? userDetail?.image : userDetail?.google_image);
  }, [userDetail]);

  useEffect(() => {
    dispatch(getThemeData());
    dispatch(getUser());
  }, [dispatch]);

  const toggleProfile = () => {
    setProfile(!profile);
  };

  const handleLogout = async () => {
    googleLogout();
    localStorage.removeItem('video-paltform-token');
    localStorage.removeItem('UserData');
    dispatch(logout());
    persistStore(store).purge();
    navigate('/login');
    // window.location.reload();
  };

  const [mode, setMode] = useState(() => localStorage.getItem('theme') || 'dark');

  const toggleTheme = () => {
    setMode(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    dispatch(darkLightModeSwitch(mode));
  }, [mode, dispatch]);

  return (
    <>
      <div className="container-fluid backgruondcolor fixed-top" style={{ transition: "background-color 0.3s ease" }}>
        <header>
          <nav className="navbar navbar-expand-lg poppins">
            <div className="container-fluid mx-lg-4 mx-md-3 mx-xm-3 mx-0">
              <Link className="nav-link" to="/">
                <img src={"https://portal.tateclips.com/asset/frontend/light/images/banner/" + themeData?.banner?.content?.logo} className="logo" alt="w8" />
              </Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse mt-lg-0 mt-3" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-4" style={{ display: props.SecondNav }}>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/">Caption</NavLink>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <NavLink className="nav-link" to="/videos">Title</NavLink>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <NavLink className="nav-link" to="/pricing">Pricing</NavLink>
                  </li>
                  {themeData?.about?.content?.is_about_enable === "1" &&
                    <li className="nav-item ms-lg-3">
                      <NavLink className="nav-link" to="/about">About us</NavLink>
                    </li>
                  }
                  <li className="nav-item ms-lg-3">
                    <NavLink className="nav-link" to="/contact">Contact us</NavLink>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                  </li>
                </ul>
                <ul className="d-flex align-items-center mb-0">
                  <li className="nav-item">
                    <NavLink to={'https://discord.gg/Vc8PfXjw'} className="nav-link me-4">
                      <FaDiscord size={30} color="white" />
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link republictextwhite me-4" onClick={toggleTheme}>
                      {mode === 'light' ? <FaMoon size={30} /> : <FaSun size={30} />}
                    </button>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link republictextwhite me-4" to={userDetail?.id > 0 ? "#" : '/login'} onClick={userDetail?.id > 0 ? toggleProfile : () => navigate('/login')}>
                      {profileImage &&
                        <img
                          src={profileImage}
                          className="text-center"
                          alt="Profile"
                          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                        />
                      }
                      <span className="ms-1 font-lato font-13" style={{ color: "white" }}>
                        {userDetail?.id > 0 ? userDetail?.name : "Sign In"}
                      </span>
                    </Link>
                    {profile && (
                      <ul className="p-3 px-3 mt-2 no-list-style position-absolute poppins">
                        <NavLink to="/profile" className="font-14">
                          <li className="bordertop">
                            <img
                              src={profileImage}
                              className="text-center"
                              alt="Profile"
                              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                            />
                            <h6 className="font-12 font-inter">{userDetail?.name}</h6>
                          </li>
                        </NavLink>
                        <NavLink to="/profile" className="font-14">
                          <li className="bordertop">
                            <img src={userpannel} width={10} alt="" />
                            <span className="font-12 font-inter">User Panel</span>
                          </li>
                        </NavLink>
                        <NavLink to="/myvideos" className="font-14">
                          <li className="bordertop">
                            <FaHeart color="white" />
                            <span className="font-12 font-inter">My Videos</span>
                          </li>
                        </NavLink>
                        <li style={{ cursor: 'pointer' }} onClick={handleLogout} className="bordertop">
                          <img
                            src={profilelogout}
                            width={15}
                            alt=""
                          />
                          <span className="font-12 font-inter">Log Out</span>
                        </li>
                      </ul>
                    )}
                  </li>
                  {!userDetail?.id &&
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/pricing">
                        <button type="button" className="navbtn rounded-5 font-lato">
                          <FaUserCircle /> Sign Up
                        </button>
                      </NavLink>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <div className="navbar-container">
        {/* Your main content goes here */}
      </div>
    </>
  );
};

export default Navbar;
