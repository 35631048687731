import React, { useState } from "react";
import Navbar from "../component/Navbar";
import phone from "../assetr/mobile.png";
import email1 from "../assetr/email.png";
import doteimg from "../assetr/blocks.png";
import Fotter from "../component/Fotter";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../redux/slices/userSlice";
import { Alert, Spinner } from 'reactstrap';
const ContactUs = () => {
  const dispacth = useDispatch();
  const { themeData } =
  useSelector((state) => state.theme);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmitContactus = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      name: name,
      email: email,
      subject: subject,
      message: subject
    }
    dispacth(createContact(data, handleResponse));
  }
  const [response, setResponse] = useState(null);
  const [responseType, setResponseType] = useState('success');
  const handleResponse = (data) => {
    setLoading(false);
    if (data?.status) {
      setResponse("Your request has been submited Successfully!")
      setResponseType("success");
      setName(null);
      setEmail(null);
      setSubject(null);
      setMessage(null);
    }else{
      setResponse(data?.message)
      setResponseType('danger')
    }
  }
  const handleAlertDismiss = () => {
    setResponse(null);
  };

  return (
    <div>
      <Navbar />
      <div className="container py-5 mt-5">
        <h3 className="font-50 fw-500 font-inter content ps-sm-0 ps-2">
          {themeData?.contact?.content?.section_header}
        </h3>
      </div>
      <div className="container-fluid bgimgContactUs mb-5 " >
        <div className="container position-relative">
          <img src={doteimg} className="w-100 position-absolute mt-4" alt="" />
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12 align-self-end">
              <h3 className="font-40 fw-500 font-inter republictextwhite">
              {themeData?.contact?.content?.title}
              </h3>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-md-5 mt-3">
              <div className="row">
                <div className="col-md-5 col-12 mb-md-0 mb-3">
                  <div className="mycardcolor p-3 rounded-3">
                    <img src={phone}  width={40} alt="" />
                    <p className="font-12 mb-2 mt-2 fw-600 font-inter republictextwhite">
                      Phone
                    </p>
                    <h6 className="font-12 fw-700 font-inter republictextwhite">
                    {themeData?.contact?.content?.phone}
                    </h6>
                  </div>
                </div>
                <div className="col-md-7 col-12">
                  <div className="mycardcolor p-3 rounded-3">
                    <img src={email1}  width={40} alt="" />
                    <p className="font-12 mb-2 mt-2 fw-600 font-inter republictextwhite">
                      Email
                    </p>
                    <h6 className="font-12 fw-700 font-inter republictextwhite">
                    {themeData?.contact?.content?.email}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 mt-5">
              <div className="p-4 bg-questions rounded-3">
                <h3>{themeData?.contact?.content?.form_header}</h3>
                <p>
                {themeData?.contact?.content?.form_description}
                </p>
                <form className="row" onSubmit={handleSubmitContactus}>
                  <div className="col-lg-6 col-md-12 mb-3">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      {" "}
                      Name
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      type="text"
                      class="form-control input-fieldblack p-2 rounded-3"
                      placeholder="Enter Your Name ..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 mb-3">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      {" "}
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      type="email"
                      class="form-control input-fieldblack p-2 rounded-3"
                      placeholder="Enter Your Email Address ..."
                      aria-describedby="basic-addon2"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 mb-3">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      name="subject"
                      class="form-control input-fieldblack p-2 rounded-3"
                      placeholder="Enter Your Subject ..."
                      aria-describedby="basic-addon2"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label
                      htmlFor=""
                      className="font-16 font-500 poppins content mb-2"
                    >
                      {" "}
                      Message
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      name="message"
                      type="text"
                      class="form-control input-fieldblack rounded-3"
                      placeholder="Enter Your Message ..."
                      cols="30"
                      rows="4"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label
                        className="form-check-label font-12 poppins content"
                        for="flexCheckDefault"
                      >
                        {themeData?.contact?.content?.form_terms}
                      </label>
                    </div>
                  </div>
                  {response &&
                    <Alert style={{ marginTop: 20 }} color={responseType} isOpen={!!response} toggle={handleAlertDismiss}>
                      {response}
                    </Alert>

                  }
                  <div className="col-lg-12 col-12 mb-3 text-end">
                    <button type='submit' className='btn-login px-4 py-2 rounded-3 mt-3'>
                      {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fotter />
    </div>
  );
};

export default ContactUs;
